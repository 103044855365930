import {Component, Inject, OnInit} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {PaperService} from '../../paper/paper.service';
import {Brush} from '../../global-interfaces/brush';
import {Sujet} from '../../paper/sujet';
import {User} from '../../user/user';

export class DialogData {
  lis: any;
  sta: any;
  case: string;
  brushes: Brush[];
  brush: Brush;
  sujet: Sujet;
  emmmiter: Subject<any>;
  auth: any;
  user: User;

  constructor(){}
}


@Component({
  selector: 'app-start-dialog',
  templateUrl: './start-dialog.component.html',
  styleUrls: ['./start-dialog.component.css']
})
export class StartDialogComponent implements OnInit {

  started: boolean;
  public pos: any;
  private ios: boolean;
  coords: object;
  private locations: Observable<unknown>;
  messaging: boolean;
  private mess: any;
  locked: boolean;

  constructor(private pService: PaperService, @Inject(MAT_DIALOG_DATA) public data: DialogData) {
  }


  requestDeviceOrientationIOS() {
    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
      (DeviceOrientationEvent as any).requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            this.data.sta();
            window.addEventListener('deviceorientation', this.data.lis);
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }
  }


  try_loc() {
    const locationsSubscription = this.locations.subscribe({
      next(position) {
        this.pos = position;
        // @ts-ignore
        this.coords = position.coords;
        console.log('Current Position: ', this.pos.coords);
      },
      error(msg) {
        console.log('Error Getting Location: ', msg);
      }
    });

// Stop listening for location after 10 seconds
    setTimeout(() => {
      locationsSubscription.unsubscribe();
    }, 10000);
  }

  ngOnInit(): void {
    this.mess = '';

    this.started = false;



  }



  // tslint:disable-next-line:typedef

  start() {
  if (this.ios) {
      this.requestDeviceOrientationIOS();

    } else {
      this.started = true;
      this.pService.getF(1);
      this.data.sta();
    }
  }

}
