import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';

import {catchError} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from '../http-error-handler.service';
import {User} from '../user/user';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  auth = false;
  user: User;
  userO: Subject<User>;
  authUrl = 'https://buben-sha.herokuapp.com/oauth/token';
  apiUrl = 'https://buben-sha.herokuapp.com/api';
  private handleError: HandleError;
  public userOb: Observable<any>;

  /**
   * Constructor
   * @param http The http client object
   */
  constructor(
    private http: HttpClient, {createHandleError}: HttpErrorHandler
  ) {
    this.handleError = createHandleError('userService');
this.userO = new Subject<User>();

  }

  /**
   * Get an access token
   * @param e The email address
   * @param p The password string
   */
  login(e: string, p: string): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    };
    return this.http.post(this.authUrl, {
      grant_type: 'password',
      client_id: '2',
      client_secret: 'YfIZVVJH5OMQ1R4XDm8eC7BNG49q3L5lWVVlZjTy',
      username: e,
      password: p,
      scope: ''
    }, options);
  }

  /**
   * local
   * client_id: '5',
   client_secret: 'ECcTPuCFdnjn7RRJddUKF5UI1rXBtDR5krK5kmQS',
   *
   *
   * buben
   * Client ID: 2
   Client secret: YfIZVVJH5OMQ1R4XDm8eC7BNG49q3L5lWVVlZjTy
   * Revoke the authenticated user token
   */

  register(fd: any): Subject<string> {
    const resv = new Subject<string>();
    this.http.post<any>(this.apiUrl + '/register', fd).subscribe(
      response => {

        if (response.message === 'success') {
          localStorage.setItem('user_id', response.user.id);
          localStorage.setItem('user_name', response.user.name);
          localStorage.setItem('user_email', response.user.email);
          this.login(response.user.email, fd.password).subscribe(
            (res: any) => {
              localStorage.setItem('access_token', res.access_token);
            }
          );
          resv.next('success');
        }
      },
      error => {
        console.log(error);

        resv.next('error');
      }
    );
    return resv;

  }

  logout(): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/token/revoke', options);
  }

  set(o: Observable<any>): void {

    this.userOb = o;
  }

  get(): User {
    return this.user;
  }
  find(login): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/records/users?filter=email,eq,' + login, options).pipe(
      catchError(this.handleError<any>('addLocation'))
    );

  }
  check(): Observable<any> {

    const options = {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access_token')
      })
    };
    return this.http.get(this.apiUrl + '/user', options).pipe(
      catchError(this.handleError<any>('addLocation'))
    );

  }
}
