<div (pinch)="a($event)" (swipe)="a($event)" [style.height.px]="hi">
<div class="container" *ngIf="location && userId">
  <div *ngIf="!story">
 <div *ngFor="let story of stories" class="example-card">
   <mat-card>
     <mat-card-header>
       <div mat-card-avatar class="example-header-image"></div>
       <mat-card-title>{{story.name}} ({{story.timeString}})</mat-card-title>
       <mat-card-subtitle *ngIf="story.user">{{story.user.name}} </mat-card-subtitle>
     </mat-card-header>
   <mat-card-content>
       <p *ngIf="story.sujet[0]">
         {{story.sujet[0].start}}
       </p>
     <br/>
     </mat-card-content>
     <mat-card-actions>
    <ion-icon (click) = "play(story)" name="play-circle"></ion-icon>
       <button *ngIf="story.user.name === userId" mat-button>Edit</button>
     </mat-card-actions>
   </mat-card>
   </div>
</div>
  <div *ngIf="story" class="white" [style.height.px]="hi">
    <!--
<div id="controller" (pinch)="rep($event)" [style.height.px]="hi">


</div>
-->
    <mat-grid-list cols="6" *ngIf="sujets.length" rowHeight="40px">
      <ng-container *ngFor="let su of sujets">
        <mat-grid-tile *ngIf="su.objet.length>0" [ngClass]="su === currentSujet  ? 'act' : ''">

          {{su.objet.length}} items<ion-icon (click)="activate(su)" name="play-circle"></ion-icon>

        </mat-grid-tile>

      </ng-container>

    </mat-grid-list>
    <div class="tools">
      <ion-icon (click) = "play()" name="play-circle" [class]="playing ? '' : 'act'"></ion-icon>
      <ion-icon (click) = "pause()" name="pause-circle" [class]="playing ? 'act' : ''"></ion-icon>
      <div style="flex-grow:1" >
  </div>
      <!--      <mat-grid-list cols="6" *ngIf="currentSujet" rowHeight="20px">
        <ng-container *ngFor="let su of currentSujet.objet">
          <mat-grid-tile >

            {{su.strokes.length}} lines

          </mat-grid-tile>

        </ng-container>

      </mat-grid-list>-->

    </div>
  </div>

</div>

</div>
