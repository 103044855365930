import {Component, Input, OnInit} from '@angular/core';
import {Objet} from '../paper/objet';
import {PaperService} from '../paper/paper.service';
import {SvgComponent} from '../svg/svg.component';
import {LineS} from '../paper/lineS';
import {PathS} from '../paper/pathS';
import {Brush} from '../global-interfaces/brush';
import paper from 'paper';
import {getStroke} from 'perfect-freehand';

@Component({
  selector: 'app-svg-part',
  templateUrl: './svg-part.component.html',
  styleUrls: ['./svg-part.component.css']
})
export class SvgPartComponent implements OnInit {
  @Input() objets: Objet[];
  @Input() size: string;
  w: number;
  h: number;
  svg: SvgComponent;

  constructor(private pService: PaperService) {
  }

  ngOnInit(): void {
    const size = this.size.split(',');
    this.w = Number(size[0]);
    this.h = Number(size[1]);

  }

  init($event: SvgComponent): void {
    this.svg = $event;
    const gs = [];
    this.objets.forEach((a,i) => {
      const ob = new LineS(i);
      a.strokes.forEach(u => {

const path = new PathS(u.alpha,u.width);
path.color=u.color;
        const opts = {
          size: u.width,
          start: {
            taper: 1,
            easing: (t) => t,
            cap: false
          },
          end: {
            taper: 0,
            easing: (t) => t,
            cap: true
          }
        };

    path.d = this.pService.getSvgPathFromStroke(getStroke(JSON.parse(u.line), opts));

        ob.paths.push(path);


      });

      gs.push(ob);

    });
this.svg.gs=gs;
  }
}
