import {PathS} from './pathS';
import {Transf} from './transf';


export class LineS {
    data: any;
    id: number;
    dbId?:number;
    alpha: number;
    svg: string;
    paths: PathS[];
    trans: string;
transforms: Transf[];
    type: string;
    start: number;
  start0: number;
    delta: number;
    constructor(id) {

      this.id = id;
      this.alpha = 1;
      this.paths=[];
      this.trans='matrix(1 0 0 1 0 0)';
      this.transforms=[];
    }
}
