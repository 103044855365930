import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../guest/start-dialog/start-dialog.component';
import {RestService} from '../rest/rest.service';
import {User} from '../user/user';
import {AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {MatSelectChange} from '@angular/material/select';

@Component({
  selector: 'app-save',
  templateUrl: './save.component.html',
  styleUrls: ['./save.component.css']
})
export class SaveComponent implements OnInit {
  access = environment.access;
  w: number;
  h: number;
sujet$ = new Subject<number>();
  public user: User;
  selStory: FormControl;
  selAcc: FormControl;
  form: FormGroup;
  formR: FormGroup;
  log: string;
  selectedValue: number;
  sujetId: number;
  size: string;
 loading = false;
 load = 100000;
  forbiddenNameValidator: ValidatorFn;
  errors: any;
  selectedAcc: string;
  lld = 0;

  constructor( private fb: FormBuilder, @Inject(MAT_DIALOG_DATA) public dataD: DialogData, public restService: RestService) {

    this.forbiddenNameValidator = function(a) {
      return (control: AbstractControl): ValidationErrors | null => {
      const ps = this.formR.getRawValue().password_confirmation;
      const psC = this.formR.getRawValue().password_confirmation;
      return ps === psC ? {forbiddenName: {value: control.value}} : null;
    };
  }

  }

  ngOnInit(): void {

    this.w = window.innerWidth;
    this.h = window.innerHeight;
    this.user = this.dataD.user;

    let state = -1;
    if(this.user.story.length === 0) state = 0;
    this.selStory = new FormControl(state);
    this.selAcc = new FormControl('private');
    this.selectedValue = state;
    this.selectedAcc = 'private';
    this.size = this.w.toString().concat(',', this.h.toString());
    if(!this.user){
      this.formR = this.fb.group({
        first_name: ['', Validators.required],

        email: ['', [Validators.required, Validators.email]],
        password: ['', Validators.required, Validators.minLength(6)],
        password_confirmation: ['', Validators.required, Validators.minLength(6)]
      });
      this.form = this.fb.group({
        email: [
          '',
          [Validators.required, Validators.email]
        ],
        password: [
          '',
          Validators.required
        ]
      });


    }
  }


  newS(add: any): void {
    this.selectedValue = add.add;
  }

  newSu($event: any): void {
    // console.log($event);
 this.sujetId = $event.add;

  }
sendSave(){
    this.dataD.emmmiter.subscribe(t=>{
      if(this.load === 100000)this.load = t;
   this.lld = 100 - Math.round(t / this.load) * 100;
if(t === 0)this.loading = true;
    });
  this.sujet$.next(this.sujetId);
}
  regFu() {

    //  this.loading=true;
  }

  loginFu() {
   // this.loading=true;
  }

  upD(ee: MatSelectChange) {
  console.log(ee);

  }
}
