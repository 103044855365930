<script src="https://unpkg.com/@jaames/iro@beta/dist/iro.min.js"></script>
<div class="cp">

  <div id="colp">

  </div>
  <div *ngIf="colorPicker" class="cols">
 <div *ngFor="let a of brushes;let i = index" [class]="ro(i)" [style.width.px]="brushes[i].radius/5 +4" [style.height.px]="brushes[i].radius/5 +4">
    <svg width="40" height="40">
      <pattern id="b7d0w296" width="8" height="8" patternUnits="userSpaceOnUse">
      <rect x="0" y="0" width="8" height="8" fill="#fff"></rect>
      <rect x="0" y="0" width="4" height="4" fill="#ccc"></rect>
      <rect x="4" y="4" width="4" height="4" fill="#ccc"></rect>
    </pattern>
      <rect x="0" y="0" width="40" height="40" fill="url(#b7d0w296)"/>
    </svg>
   <div [style.backgroundColor]="colorPicker.colors[i].rgbaString" (click)="se(i,a)"></div>
 </div>
  <!--  <div [class]="ro(1)" [style.backgroundColor]="colorPicker.colors[1].rgbaString" (click)="se(1)"></div>
    <div [class]="ro(2)" [style.backgroundColor]="colorPicker.colors[2].rgbaString" (click)="se(2)"></div>
    <div [class]="ro(3)" [style.backgroundColor]="colorPicker.colors[3].rgbaString" (click)="se(3)"></div>
    -->

  </div>

  <mat-slider
    (input)="move($event)"

    [value]="we"
    [max]="100"
    [min]="1">

  </mat-slider>

  <ion-icon  class="cl" name="close" [mat-dialog-close]="colorPicker.colors"></ion-icon>

</div>
