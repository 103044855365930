import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LineS} from '../paper/lineS';
import {Subject} from 'rxjs';
import {Brush} from '../global-interfaces/brush';
import paper from 'paper';

@Component({
  selector: 'app-svg',
  templateUrl: './svg.component.svg',
  styleUrls: ['./svg.component.css']
})
export class SvgComponent implements OnInit {
  @Input() width: number;
  @Input() mat: paper.Matrix;
  @Input() height: number;
  @Input() brushes: Brush[];
  @Input() start: number;
  @Input() ww: number;
  @Input() type: string;
  colors: paper.Color[];
   playing = false;
   playingObs = new Subject<boolean>();
  @Output() pos = new EventEmitter<SvgComponent>();
  gs: LineS[];
  b: number;
  gsR: LineS[];
  newL: LineS[];

  constructor() {
  }

  ngOnInit(): void {

    this.playingObs.subscribe(a=>{
    this.playing=a;

    });
    this.b = 1;
    const p = new LineS(0);
    p.paths = [];
    this.gs = [p];
    this.gsR = [];
    this.pos.emit(this);
  }


}
