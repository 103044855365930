
<div class="cvbv" (touchend)="bang($event)" (pan)="onSwipeLeft($event,-1)"

     [style.height.px]="hi" >

  <app-globe *ngIf="user.lat" [bang]="bangO" [mouse]="mouseOb" [user]="user" [myAct]="myAct"></app-globe>

</div>
<div  class="cont" >
    <ion-icon  name="person-circle-outline"></ion-icon>
 <!-- <ion-icon name="body-outline"></ion-icon>
    <ion-icon name="desktop-outline"></ion-icon>-->
    <ion-icon *ngIf="coords === undefined" name="location-outline"></ion-icon>

    <ion-icon name="chatbox-ellipses-outline" (touchend)="message()"></ion-icon>
    <ion-icon name="notifications-circle-outline" (touchstart)="tap($event)"></ion-icon>
 <div (click)="tap1($event)"  (touchend)="tap1($event)">  <ion-icon name="share-social"  ></ion-icon></div>
<ion-icon name="help-circle-outline"  (touchstart)="help($event)"></ion-icon>
    <ion-icon *ngIf="!locked"  (touchstart)="lock(1)"  name="lock-open" class="sm-icon"></ion-icon>
    <ion-icon *ngIf="locked"  (touchstart)="lock(0)"  name="lock-closed" class="sm-icon" ></ion-icon>
</div>


