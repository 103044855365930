import {Component, Inject, OnInit, EventEmitter, Output, Input} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Observable, Subject} from 'rxjs';
import {WebsocketService} from '../../websocket';
import {MAT_FORM_FIELD, MatFormField, MatFormFieldControl} from '@angular/material/form-field';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {MyHammerConfig} from '../../app.component';
import {RestService} from '../../rest/rest.service';
import {User} from '../../user/user';




@Component({
  selector: 'app-guest-menu',
  templateUrl: './guest-menu.component.html',
  styleUrls: ['./guest-menu.component.css'],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }]
})
export class GuestMenuComponent implements OnInit {
@Output() hellp = new EventEmitter<any>();
  bangO: Subject<any>;
@Input() user: User;
  started: boolean;
  public pos: any;
  private ios: boolean;
  coords: object;
  private locations: Observable<unknown>;
  messaging: boolean;
  private mess: any;
  locked: boolean;
  lef: number;
  hi:number;
  mouse: number[];
mouseOb: Subject<number[]>;
  myAct: Subject<any>;
  private audioObj: HTMLAudioElement;
  constructor(private wsService: WebsocketService, private restService: RestService) {
    this.lef=window.innerWidth*0.5-60;
    this.hi=window.innerHeight;
  }





  try_loc() {
    const locationsSubscription = this.locations.subscribe({
      next(position) {
        this.pos = position;
        // @ts-ignore
        this.coords = position.coords;

        this.user.lat=this.coords.latitude;
        this.user.lng=this.coords.longitude;
        console.log(this.user);
      },
      error(msg) {
        console.log('Error Getting Location: ', msg);
      }
    });

// Stop listening for location after 10 seconds
    setTimeout(() => {
      locationsSubscription.unsubscribe();
    }, 10000);
  }

  ngOnInit(): void {
  //
    this.audioObj = new Audio();
    this.audioObj.src = 'assets/lq.mp3';
    this.audioObj.load();
    this.myAct=new Subject<any>();
    this.mouseOb=new Subject<number[]>();
    this.bangO= new Subject<any>();
    this.mess = '';
    this.coords = {t: false};
    this.started = false;
    this.ios = ((/iPad|iPhone|iPod/.test(navigator.userAgent)) && (typeof (DeviceMotionEvent as any).requestPermission === 'function'));


    this.locations = new Observable((observer) => {
      let watchId: number;

      // Simple geolocation API check provides values to publish
      if ('geolocation' in navigator) {
        // @ts-ignore
        watchId = navigator.geolocation.getCurrentPosition((position: any) => {
          observer.next(position);
        }, (error: any) => {
          observer.error(error);
        });
      } else {
        observer.error('Geolocation not available');
      }

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe() {
          navigator.geolocation.clearWatch(watchId);
        }
      };
    });
    this.restService.getMyLoc().subscribe(a=>{
      if(!a.latitude){
        this.try_loc();

      }else{
      this.user.city=a.city;
      this.user.lat=a.latitude;
      this.user.lng=a.longitude;
       // this.try_loc();
      }
    });

  }

  tap(ev) {
    const force = ev.changedTouches[0].force;
console.log(force);
    const AudioContext = window.AudioContext;
    this.audioObj.volume = force/8;
    this.audioObj.play();
   // alert(force);
    this.wsService.send('set-o', '{"mode":"tap","energy":"'.concat(force, '"}'));

  }

  // tslint:disable-next-line:typedef




message() {
    this.messaging = true;
this.lock(1);
  this.hellp.emit('mess');
  }

  check(ev) {
    if (ev.target.value.length > 10) {
      this.mess = ev.target.value;

    }
    this.wsService.send('set-o', '{"mode":"mess","content":'.concat(ev.target.value.length, '}'));
  }

  chmes() {
    if (this.mess === '') {
      return true;
    } else {
      return false;
    }
  }

  sendMes() {
    this.wsService.send('set-o', '{"mode":"mess","content":"'.concat(this.mess, '"}'));
    this.messaging = false;
   // this.lock(0);
  }

  lock(number: number) {
    this.hellp.emit('lock'+number.toString());
    this.wsService.send('set-o', '{"mode":"lock","case":"'.concat(String(number), '"}'));
    this.locked = Boolean(number);

  }

  app(ev: any) {
    console.log(ev);

  }
  messOpen($event: any) {
    this.hellp.emit('mess');
  }
  help($event: any) {
    this.hellp.emit('help');
  }

  onSwipeLeft($event: any, number: number) {

this.mouse=[$event.velocityX,$event.velocityY,$event.center.x,$event.center.y];
this.mouseOb.next(this.mouse);
  }


  tap1($event: any) {
    this.myAct.next($event);

  }

  bang($event: any) {
   this.bangO.next($event);

  }
}
