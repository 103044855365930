<div class="main" [style.height.px]="hi">


  <ion-icon name="person-circle-outline"></ion-icon>
  <app-input [type]="'user'" (ret)="ret($event)"  [size]="26" [id]="user.id" [field]="'nic'" [val]="user.nic" [placeh]="'Anonimous?'"></app-input>
  <div><small>{{user.userId}}</small></div>
  <div *ngIf="!user.story"  class="link">
   <!-- (click)="newStory()" -->a
    Start your story
    <app-input [type]="'story'"  (ret)="ret($event)"  [fields]="{user:user.id,location:locationDB}" [size]="26"  [field]="'name'" [val]="''" [placeh]="'Story name'"></app-input>

  </div>
  <div *ngIf="user.story">Your stories</div>
  <div *ngIf="user.story" class="row ">
    <mat-card class="example-card" *ngFor="let stor of story">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image">
          <ion-icon name="recording-outline"></ion-icon>
        </div>
        <app-input [type]="'story'" [field]="'name'"  [size]="20" [id]="stor.id" [val]="stor.name" [placeh]="'Your story name?'"></app-input>


      </mat-card-header>
     <mat-card-content>
        <p contenteditable="true" (keyup)="cha_d($event,stor.description)" (focus)="cha_d($event,stor.description)"
           [innerHTML]="stor.description ? stor.description : 'Short description of this story'">
      </p>

       <mat-grid-list cols="3" *ngIf="stor.sujet.length" rowHeight="40px">
         <ng-container *ngFor="let su of stor.sujet">
           <mat-grid-tile *ngIf="su.objet.length>0">
             {{su.objet.length}} items<ion-icon (click)="play(su)" name="play-circle"></ion-icon>

           </mat-grid-tile>

         </ng-container>

       </mat-grid-list>

      </mat-card-content>
      <mat-card-actions>
        <button (click)="pla(stor.id)"  mat-button class="btn">PLAY</button>
        <button mat-button (click)="pla(stor.id)" class="btn">EDIT</button>
        <ion-icon name="share-social"></ion-icon>
      </mat-card-actions>
    </mat-card>


  </div>
</div>
