export const WS = {
    ON: {
        MESSAGES: 'messages',
        DRAWINGS: 'draw',
       USERS: 'users',
        USERS_T: 'users_t',
        UPDATE_LINE: 'update-lines'
    },
    SEND: {
        DRAW: 'draw',
        SEND_TEXT: 'set-text',
        REMOVE_TEXT: 'remove-text',
        SEND_ORIENT: 'set-o'
    }
};
