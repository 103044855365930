import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {User} from './user';
import {RestService} from '../rest/rest.service';
import {Story} from '../global-interfaces/story';
import {Sujet} from '../paper/sujet';

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
@Input() user;
  @Input() locationDB;
  @Output() draw = new EventEmitter<any>();
  hi: any;
   story: Story[];
    constructor(private restService: RestService) {
    }

    ngOnInit() {
this.hi = window.innerHeight;
this.story = this.user.story;


    }

  ch($event: Event) {

  }

  cha_d($event: any, val) {
    //  console.log(val);

  }

  cha_n(ev: any, val) {
   if (val === null) {
     ev.target.innerHTML = '               ';
   }
  }

  pla(soryId:number): void {
    this.draw.emit(1);
  }
  newStory() {
    this.draw.emit(1);
  }

  play(su: Sujet) {
    this.draw.emit(su);
  }

  ret(e: any) {
    if(e.add){

      this.story[0].id = e.add;
      this.draw.emit(1);
    }
  }
}
