import {BrowserModule, HammerModule, Meta} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {PaperComponent} from './paper/paper.component';
import {AppComponent} from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {WebsocketModule} from './websocket';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {HostComponent} from './host/host.component';
import {StorageComponent} from './storage/storage.component';
import {UserComponent} from './user/user.component';
import {RestComponent} from './rest/rest.component';
import {HttpErrorHandler} from './http-error-handler.service';
import {MessageService} from './messages';
import {GuestComponent} from './guest/guest.component';
import {ContentControllerComponent} from './content-controller/content-controller.component';
import {DrawerComponent} from './drawer/drawer.component';
import {ColorSelectComponent} from './color-select/color-select.component';
import {_MatMenuDirectivesModule, MatMenuModule} from '@angular/material/menu';
import {MatOptionModule} from '@angular/material/core';
import {ColorComponent} from './color/color.component';
import {GuestMenuComponent} from './guest/guest-menu/guest-menu.component';
import {MatDialogModule} from '@angular/material/dialog';
import {MatSliderModule} from '@angular/material/slider';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {StartDialogComponent} from './guest/start-dialog/start-dialog.component';
import {AudioComponent} from './audio/audio.component';
import {PlayerComponent} from './player/player.component';
import {InputComponent} from './input/input.component';
import {GlobeComponent} from './globe/globe.component';
import {TestComponent} from './test/test.component';
import {MaskScreenComponent} from './mask-screen/mask-screen.component';
import {VisitorComponent} from './visitor/visitor.component';
import {SvgComponent} from './svg/svg.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {PlayerPopupComponent} from './player-popup/player-popup.component';
import {PaintComponent} from './paint/paint.component';
import {HelpComponent} from './help/help.component';
import {RegisterComponent} from './register/register.component';
import {MatIconModule} from '@angular/material/icon';
import {LoginComponent} from './login/login.component';
import { StoryComponent } from './story/story.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { DashBoardComponent } from './dash-board/dash-board.component';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import {MatSelectModule} from '@angular/material/select';
import { SaveComponent } from './save/save.component';
import { SvgPartComponent } from './svg-part/svg-part.component';


@NgModule({
  declarations: [
    AppComponent, PaperComponent, HostComponent, StorageComponent,
    UserComponent, RestComponent, GuestComponent, LoginComponent,
    ContentControllerComponent, DrawerComponent, RegisterComponent,
    ColorSelectComponent, ColorComponent, GuestMenuComponent, StartDialogComponent, AudioComponent, PlayerComponent, InputComponent, GlobeComponent, TestComponent, MaskScreenComponent, VisitorComponent, SvgComponent, PlayerPopupComponent, PaintComponent, HelpComponent, StoryComponent, FileUploadComponent, DashBoardComponent, SaveComponent, SvgPartComponent
  ], schemas: [
    CUSTOM_ELEMENTS_SCHEMA,
  ],
    imports: [
        HttpClientModule,
        BrowserModule.withServerTransition({appId: 'serverApp'}),
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        WebsocketModule.config({
            url: environment.ws
        }), DragDropModule,
        _MatMenuDirectivesModule,
        MatMenuModule,
        MatSliderModule,
        MatOptionModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        HammerModule, MatGridListModule, MatCardModule, MatProgressBarModule, MatIconModule, MatButtonModule, LayoutModule, MatSelectModule
    ],
  providers: [HttpErrorHandler,
    MessageService, Meta
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
