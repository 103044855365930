import {Component, Inject, OnInit} from '@angular/core';
import iro from '@jaames/iro';
import {IroColorPicker} from '@jaames/iro/dist/ColorPicker';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../guest/start-dialog/start-dialog.component';
import {Brush} from '../global-interfaces/brush';



@Component({
  selector: 'app-color',
  templateUrl: './color.component.html',
  styleUrls: ['./color.component.css']
})
export class ColorComponent implements OnInit {
  scolor: string;
  act: number;
  brush: Brush;
  we: number;
  brushes: Brush[];
  public handles: any[];
  public colorPicker: IroColorPicker;

  constructor(@Inject(MAT_DIALOG_DATA) public dataD: DialogData) {
    this.brushes = dataD.brushes;

    this.brush = dataD.brush;

  }

  openLink(): void {

  }

  move(e) {
    this.we = e.value;
    this.brush.radius = this.we/2;
    const rootStyle = document.documentElement.style;

    rootStyle.setProperty('--act-brushR', (this.brush.radius + 4).toString()+'px');
    rootStyle.setProperty('--act-brushRm', (-this.brush.radius/2 - 2).toString()+'px');

  }

  colorChangeCallback(color) {

    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--act-brushC', 'rgba(' + color.rgba.r+
      ',' + color.rgba.g +
      ',' + color.rgba.b +
      ',' + color.rgba.a + ')');

  }


  ngOnInit(): void {
    this.act = 0;


    this.colorPicker = iro.ColorPicker('#colp', {
      // Set the size of the color picker
      layout: [
        {
          component: iro.ui.Wheel,
          options: {}
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'value'
          }
        },
        {
          component: iro.ui.Slider,
          options: {
            sliderType: 'alpha'
          }
        }
      ],
      width: window.innerHeight * 0.4,
      // Set the initial color to red
      colors: [

        {r: this.brushes[0].color.red, g: this.brushes[0].color.green, b: this.brushes[0].color.blue, a: this.brushes[0].color.alpha}, // pure red
        {r: this.brushes[1].color.red, g: this.brushes[1].color.green, b: this.brushes[1].color.blue, a: this.brushes[1].color.alpha}, // pure green
        {r: this.brushes[2].color.red, g: this.brushes[2].color.green, b: this.brushes[2].color.blue, a: this.brushes[2].color.alpha},
        {r: this.brushes[3].color.red, g: this.brushes[3].color.green, b: this.brushes[3].color.blue, a: this.brushes[3].color.alpha}// pure blue
      ]
    });
    this.colorPicker.setActiveColor(this.brush.id);
    this.we = this.brush.radius;

    //  this.brushes=this.data as Brush[];
    this.colorPicker.on('color:change', this.colorChangeCallback);
    this.handles = Array.from(document.getElementsByClassName('IroHandle'));
    this.handles.forEach(a => {
      a.setAttribute('visibility', 'hidden');
    });
    document.getElementsByClassName('IroHandle--isActive')[0].setAttribute('visibility', 'visible');
    document.getElementsByClassName('IroHandle--isActive')[1].setAttribute('visibility', 'visible');
    document.getElementsByClassName('IroHandle--isActive')[2].setAttribute('visibility', 'visible');

  }


  clo() {

  }

  ro(nu: number) {

    let cl = 'ro';
    if (this.brush.id === nu) {
      cl = cl.concat(' active');
    }
    return cl;
  }

  se(n: number, brush: Brush) {
    this.act = n;
    this.brush = brush;
    this.brushes.forEach(a=>{a.active=false;});
    brush.active = true;
    this.colorPicker.setActiveColor(n);
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--act-brushC', 'rgba(' + brush.color.red+
      ',' + brush.color.green +
      ',' + this.brush.color.blue +
      ',' + this.brush.color.alpha + ')');
    rootStyle.setProperty('--act-brushR', (brush.radius + 4).toString()+'px');
    rootStyle.setProperty('--act-brushRm', (-brush.radius/2 - 2).toString()+'px');
    this.we = brush.radius;
    document.getElementsByClassName('IroHandle--isActive')[0].setAttribute('visibility', 'visible');
    document.getElementsByClassName('IroHandle--isActive')[1].setAttribute('visibility', 'visible');

  }

  done() {

  }
}
