import {Component, OnInit} from '@angular/core';
import paper from 'paper';
import {fromEvent, Subscription} from 'rxjs';
import {pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {PaperService} from '../paper/paper.service';

@Component({
  selector: 'app-mask-screen',
  templateUrl: './mask-screen.component.html',
  styleUrls: ['./mask-screen.component.css']
})
export class MaskScreenComponent implements OnInit {
  masking: boolean;
  private exx: paper.Point;
  private po: paper.Point;
  private lastPoint: paper.Point[];
  private canvasEl: HTMLCanvasElement;
  private painting: Subscription;
  private pMasks: paper.Path[];
private  pMask: paper.Path;


  constructor(private paperService: PaperService) {

    this.masking = false;

  }

  ngOnInit(): void {
this.pMasks=[];
    this.canvasEl = paper.view.element;

  }



  private captureEvents_(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
  this.painting=  fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          this.touch(e);
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset

        const prevPos = new paper.Point(res[0].clientX - rect.left, res[0].clientY - rect.top);


        const currentPos = new paper.Point(res[1].clientX - rect.left, res[1].clientY - rect.top);
        this.pMask.addSegments([new paper.Segment(prevPos)]);
        //  console.log(prevPos);
        // this method we'll implement soon to do the actual drawing
      //  this.drawOnCanvas(prevPos, currentPos, 1);
      }, () => {
      }, () => {
        console.log('Observer got a complete notification');
      });
  }

  msk(b: boolean) {

    this.masking = b;
    if (b) {
      this.paperService.maskT.removeChildren();

      this.captureEvents_(this.canvasEl);

      fromEvent(this.canvasEl, 'mouseup').subscribe(ev => {
      //  this.endLine(ev);
      });

 /**/

    }else{

this.painting.unsubscribe();
    }
  }

  touch(event: any) {
    event.preventDefault();

    this.exx = new paper.Point(0, 0);
    this.po = event instanceof MouseEvent ? new paper.Point(event.clientX, event.clientY) : new paper.Point(event.touches[0].clientX,
      event.touches[0].clientY);
    this.pMask=new paper.Path(new paper.Segment(this.po));
  //  this.pMask.fullySelected=true;
    this.pMask.fillColor=new paper.Color(0,0,0);
    this.pMasks.push(this.pMask);
    this.paperService.maskT.addChild(this.pMask);
  //  this.lastPoint = [new paper.Point(this.po.x - rect.left, this.po.y - rect.top)];

  }


}
