<app-user *ngIf="!drawing" [locationDB]="location_db" [user]="user" (draw)="stDraw($event)"></app-user>


<div *ngIf="drawing" class="drawContainer" [style.height.px]="h">
  <div class="lef" [style.height.px]="h-60">
    layers
    <hr>
    active

    <div class="objects act" cdkDropList [cdkDropListData]="done_objets" cdkDropListOrientation="vertical"
         (cdkDropListDropped)="drop($event)">

      <div class="objet" [cdkDragData]="done_objet" cdkDrag *ngFor="let done_objet of done_objets"
           [style.backgroundImage]="'url('+done_objet.image+')'">

      </div>
    </div>
    <hr>
    fading
    <div class="objects wid" cdkDropList [cdkDropListData]="fading_objets" cdkDropListOrientation="horizontal"
         (cdkDropListDropped)="dropF($event)">

      <div class="objet" [cdkDragData]="fd_objet" cdkDrag *ngFor="let fd_objet of fading_objets"
           [style.backgroundImage]="'url('+fd_objet.image+')'">

      </div><!---->
    </div>
    <hr>
    background
    <div class="objects bg">


      <div [class]="'objet '+ bg_objet.active" *ngFor="let bg_objet of bg_objets" (click)="activateBG(bg_objet)"
           [style.backgroundImage]="getBgG(bg_objet)">

      </div>
    </div>
  </div>


  <app-paper-canvas

    (pressup)="pressup($event)"
    class="canvas"
    [type]="'draw'"
    [story]="story"
    [userDb]="user_db"
    [locationDb]="location_db"
    [selG]="selectedG"
    [undo]="undo"
    [newG]="newG"
    [recording]="recordingObs"></app-paper-canvas>

  <div class="brushes">
    <div *ngFor="let brush of brushes; let i=index;" [class]="getAct(brush)">
      <ion-icon name="brush" [class]="'pb t'.concat(i)" [style.color]="getCol(brush)"
                [style.backgroundColor]="getBg(brush)" (click)="select($event,brush)" (touchend)="select($event,brush)">

      </ion-icon>
    </div>

    <button (click)="openColorDialog()" [style.backgroundColor]=" !colorOpened ? '#222':'#999'">
      <ion-icon name="color-palette"></ion-icon>
    </button>


    <ion-icon name="save" (click)="record()" [style.color]="ifRecord(1)"></ion-icon>

    <app-audio [role]="'stream'"></app-audio>
  </div>


  <div class="tto prevent">
    <!--(swipeup)="app(1)" (swipedown)="app(0)"-->

    <div class="flex">

      <div class="menu-item add">
        <button (click)="back(1)">
          <ion-icon name="return-up-back-outline"></ion-icon>
        </button>
      </div>
      <div class="menu-item add">
        <button (tap)="next(1)">
          <ion-icon name="add-circle-outline"></ion-icon>
        </button>
      </div>
      <div class="menu-item">
        <button>
          <ion-icon name="information-circle-outline"></ion-icon>
        </button>
      </div>

    </div>
  </div>

  <div class="dim" *ngIf="colorOpened"></div>
  <!--
  <script src="assets/web-api.js"></script>
  <script>
    var streamer = new WSAudioAPI.Streamer();


  </script>
  -->
</div>
