export class Vector {

    constructor(public x: number, public y: number, public z: number) {
        this.x = x;
        this.y = y;
        this.z = z;

    }
    public length() {

      return  Math.sqrt(this.x * this.x + this.y * this.y + this.z * this.z);

    }
    public normalize(base) {
const len = this.length();
        this.x *= base / len;
        this.y *= base / len;
        this.z *= base / len;
        return this;
    }
    public add(vec: Vector) {
         this.x += vec.x;
        this.y += vec.y;
        this.z += vec.z;

    }
    public divide(a: number) {
        this.x = this.x  / a;
        this.y =  this.y / a;
        this.z = this.z / a;
        return this;
    }
    public multiply(a: number) {
        this.x = this.x  * a;
        this.y =  this.y * a;
        this.z = this.z * a;
        return this;
    }
}
