import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebsocketService } from './websocket.service';
import { config } from './websocket.config';
import { WebSocketConfig } from './websocket.interfaces';
import {BROWSER_STORAGE, BrowserStorageService} from '../storage/storage.service';


@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        WebsocketService,
  BrowserStorageService,
    {provide: BROWSER_STORAGE, useFactory: () => sessionStorage}
]
})
export class WebsocketModule {
    public static config(wsConfig: WebSocketConfig): ModuleWithProviders<any> {
        return {
            ngModule: WebsocketModule,
            providers: [{ provide: config, useValue: wsConfig }]
        };
    }
}
