import {Sujet} from '../paper/sujet';
import {Line} from '../paper/line';
import {Objet} from '../paper/objet';
import {Story} from '../global-interfaces/story';

export class User {
  geometry?: paper.Path;
  userId: string;
  id: number;
  nic: string;
  name: string;
  size: string;
  story: Story[];
  currentsujet?: Sujet;
  sujets?: Sujet[];
  color?: paper.Color;
  po?: paper.Point;
  currentline?: Line;
  currentobjet?: Objet;
  coef?: number;
  tempAr: any[];
  city: any;
  lat: number;
  lng: number;

  constructor(userId, name, size?: string, mw?, mh?) {
    this.userId = userId;
    this.name = name;
    if(size){
    const sizea = size.split(',').map(a => {
      return Number(a);
    });
    if (sizea[0] > sizea[1]) {
      this.coef = mw / Number(sizea[0]);
    } else {
      this.coef = mh / Number(sizea[1]);
    }
  }else{this.coef=1;}
  }
}
