import {Vector} from './vector';

export class Orient {
    alpha: number;
    beta: number;
    gamma: number;
    scale: number;
    x: number;
    y: number;
    mode?: string;
    motion?: Vector;
energy?: number;
time?: number;
case?: string;
content?: any;
    constructor(alpha, beta, gamma, scale, x, y) {

      this.alpha=alpha;
      this.beta=beta;
      this.gamma=gamma;
      this.scale=scale;
      this.x=x;
      this.y=y;

    }

}
