
<ion-icon #closeButton name="close-circle-outline" class="close" mat-dialog-close></ion-icon>

<div class="cont">
  <h3 mat-dialog-title >{{dataD.lis}}</h3>

<ng-container *ngIf="!user && dataD.lis !='about' && dataD.lis !='draw' && dataD.lis !='share'  ">

  <div  >To {{dataD.lis}} your <b>story</b> <br/>
    do
    <span [class]="login ? 'act' : ''" (click)="loginV()">log-in</span>
    or
    <span [class]="reg ? 'act' : ''"  (click)="regV()">register</span>
  </div>
  <div mat-dialog-content [class]="(reg || login) ? 'bl act': 'bl '">
    <ng-container *ngIf="login">

      <form [formGroup]="form" >

        <div class="row" *ngIf="errors">
          <div class="col">
            <div class="alert alert-danger">
              <strong>Oops!</strong> You have entered invalid credentials
            </div>
          </div>
        </div>
        <div class="row">
          <p>
            <mat-form-field appearance="standard">

              <input matInput type="email" id="email" formControlName="email" placeholder="Email">

            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="standard">
             <input matInput type="password" id="password" formControlName="password"  placeholder="Password">

            </mat-form-field>
          </p>
       <!--   <div class="col">
            <div class="form-group">
              <label for="email">Email address</label>
              <input type="email" id="email" formControlName="email" class="form-control" />
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input type="password" id="password" formControlName="password" class="form-control" />
            </div>
          </div>-->
        </div>

      </form>



    </ng-container>
    <ng-container *ngIf="reg">
      <form [formGroup]="formR" >
      <div class="row">
        <p>
          <mat-form-field appearance="standard">

            <input matInput type="text" formControlName="first_name" placeholder="Name">

          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="standard">

            <input matInput type="email" formControlName="email" placeholder="Email">

          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="standard">
            <input matInput type="password"  formControlName="password"  placeholder="Password">

          </mat-form-field>
        </p>
        <p>
          <mat-form-field appearance="standard">
            <input matInput type="password"  formControlName="password_confirmation"  placeholder="Confirm password">

          </mat-form-field>
        </p>
      </div>
      </form>  </ng-container>

  </div>
  <div class="flex-r btns">
    <button *ngIf="reg" (click)="regFu()" class="btn btn-dark col-12" [disabled]="loading">Register</button>
       <button *ngIf="login" (click)="loginFu()" class="btn btn-dark col-12" [disabled]="loading">Login</button>
  </div>



</ng-container>

  <ng-container *ngIf="dataD.lis === 'share'">

    <div mat-dialog-content>
      <ng-container *ngIf="dataD.user">

        Share user

      </ng-container>
      <ng-container *ngIf="!dataD.user">

        Share no

      </ng-container>

    </div>

  </ng-container>



  <ng-container *ngIf="dataD.lis === 'profile'">


    <div mat-dialog-content >
      <app-input *ngIf="user && user.userId" [field]="'name'" [type]="'users'" [id]="user.userId"  [placeh]="'Name'" [size]="16" [val]="user.name"></app-input>

      Stories:
      <div>
        <div *ngIf="stories">
          <div *ngFor="let story of stories ">

            <a (click)="dialogRef.close()"  [routerLink]="'/story/'.concat(story.id.toString())">{{story.name}}</a>
          </div>

        </div>
        <app-input *ngIf="user && user.userId" [field]="'name'" [fields]="{'user':user.userId}" [type]="'story'"   [placeh]="'New story'" [size]="16" ></app-input>



      </div>


      <div class="flex-r btns">
        <button (click)="logout()" mat-mini-fab color="primary" aria-label="Example icon button with a menu icon">
          <mat-icon>logout</mat-icon>log out
        </button>

      </div>
    </div>

  </ng-container>


<ng-container *ngIf="dataD.lis=='draw' || dataD.lis === 'about'">





  <ng-container *ngIf="dataD.lis === 'save'">
    To save the story you got
    <div mat-dialog-content>This dialog showcases the title, close, content and actions elements.</div>

  </ng-container>
  <ng-container *ngIf="dataD.lis === 'stream'">
    To stream the story you got
    <div mat-dialog-content>This dialog showcases the title, close, content and actions elements.</div>

  </ng-container>


  <ng-container *ngIf="dataD.lis === 'draw'">
    <p *ngIf="dataD.case != 'main' && dataD.sujet.access === 'public' ">
    You can  <span  (click)="clo(0)">continue</span> this story
  or you can <span  (click)="clo(1)">start</span> your own.
 </p>
    <p *ngIf="dataD.case === 'main' || (dataD.sujet.access != 'public' && dataD.case != 'main')">
     <span  (click)="clo(1)">Start</span> your own drawing.
 </p>
    <p *ngIf="user && dataD.sujet.owner === user.userId">
      <span  (click)="clo(0)">Edit</span> your drawing ("{{dataD.sujet.name}}").
    </p>

    <p><span (click)="dataD.lis = 'about'">Get</span> more info about this app.</p>
  </ng-container>

  <ng-container *ngIf="dataD.lis === 'about'">

     What you can do here:
    <ul>

      <li>  <span  (click)="dataD.lis = 'draw'">Start</span> drawing by finger or stylus.
      </li>
      <li>Configure your brushes</li>
      <li>Rewind your drawing and see it as animated story</li>
      <li><span (click)="dataD.lis = 'save'">Save</span> and get back to your story later. Or save as movie and publish anywhere.</li>
     <li>   <span (click)="dataD.lis = 'share'">Share</span> your animated story to friends.
        <ng-container *ngIf="!auth">(*will require <span (click)="dataD.lis = 'save'">registration</span>)</ng-container>
      </li>
      <li>
        <span (click)="dataD.lis = 'stream'">Stream</span>
        your drawing in real time, you will able to generate stream address that you will share.
        <ng-container *ngIf="!auth">(*will require <span (click)="dataD.lis = 'stream'">registration</span>)</ng-container>
      </li>

  </ul>






  </ng-container>




</ng-container>

</div>
