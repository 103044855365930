import { Component, OnInit, Self, SkipSelf } from '@angular/core';
import { BROWSER_STORAGE, BrowserStorageService } from './storage.service';

@Component({
    selector: 'app-storage',
    template: ``,
    providers: [
        BrowserStorageService,
        { provide: BROWSER_STORAGE, useFactory: () => sessionStorage }
    ]
})
export class StorageComponent implements OnInit {

    constructor(
        @Self() private sessionStorageService: BrowserStorageService,
        @SkipSelf() private localStorageService: BrowserStorageService,
    ) { }

    ngOnInit() {
      //  console.log(  this.localStorageService.get('hero'));
    }

    setSession() {
        this.sessionStorageService.set('hero', 'Dr Nice - Session');
    }

    setLocal() {
        this.localStorageService.set('hero', 'Dr Nice - Local');
    }
}

