import {Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';

import * as paper from 'paper';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})
export class TestComponent implements OnInit {
  @ViewChild('globeDiv') canvasElement: ElementRef;
  tm = 0;
worker: any;
paperS: Subject<any>;
  constructor() {


  }

  ngOnInit(): void {
    this.paperS= new Subject<any>();

    if (typeof Worker !== 'undefined') {
    // Create a new

    const worker = new Worker('./glob.worker');
    console.log('w');
    worker.onmessage = ({data}) => {

      console.log('f', data);
    }
    this.paperS.subscribe(a=> {
      const rectangle = new paper.Rectangle(new paper.Point(20, 20), new paper.Size(60, 60));
      const path = new paper.Path.Rectangle(rectangle);
      path.fillColor = new paper.Color('black');
      const pap = new paper.Group({
        children: [path],
        // Set the stroke color of all items in the group:
        strokeColor: 'black'
      });
      const imm = pap.rasterize({insert: false});
      paper.project.activeLayer.addChild(pap);
      console.log(imm.toDataURL());

      worker.postMessage({im: imm.toDataURL()});
    });
  }
  }
  ngAfterViewInit() {
    paper.setup(this.canvasElement.nativeElement);
this.paperS.next(1);


  }

}
