import {Quat} from './quat';
import {Matrix3d} from './matrix3d';
import {trigger, transition, animate, style} from '@angular/animations';
import {Vector} from './vector';
// @ts-ignore
import paper from 'paper';
import {PaperService} from '../paper/paper.service';
import {Orient} from './orient';

export class Gost {
  position?: number;
orient: Orient;
  style: string;
  sizes: number[];
  w: number;
  h: number;
  styles: object;
  transf: string;
  mat: Quat;
  matr: Matrix3d;
  posX: number;
  posY: number;
  scale: number;
  energy: number;
  active: boolean;
  lastTime: number;
  timeDelta = 0;
  line: paper.Group;
  dummy: any;
  vector: Vector;
  color: number;
  public locked: number;
  pColor: paper.Color;
  hue: number;

  constructor(public userId: string, private  pService: PaperService) {
    this.locked = 0;
    this.userId = userId;
    this.lastTime = Date.now();
    this.scale = 0.3;
    this.active = true;
    this.energy = 0;
    this.posY=0;
    this.posX=0;

//this.dummy.data.color = new paper.Color({ hue: this.color * 360, saturation: 1, brightness: 1 });

    this.mat = new Quat(0, 0, 0, 0);

//this.transf = 'matrix3d('.concat(new Matrix3d(this.mat.fromEuler(0, 0, 0, 'ZXY').matrix3D()).rotateYY(90).toString(), ')');

  }
public trans(){
  const tem = this.mat.fromEuler(this.orient.alpha, this.orient.beta, this.orient.gamma, 'ZXY');

    this.pService.trans(this, this.orient, tem);

}
  public resize(size) {

    const k = size.split(',');
    this.w = Number(k[0]) / 2;
    this.h = parseFloat(k[1]) / 2;

    this.styles = {'width.px': this.w};
  }

  public awake() {
    console.log('aw');
    this.line.visible = true;
    this.active = true;
    /* */
 // if(this.line.data.otr){ this.line.data.otr.visible = true;
    const tween = this.line.tweenTo({opacity: 1}, 500);
    tween.onUpdate = function(event) {
     // this.object.data.otr.opacity = event.factor;
    };


    tween.then(function() {

    });
}

  public sleep() {
  console.log('sleep');
if(this.line){
    let tween = this.line.tweenTo({opacity: 0}, 500);



    tween.then(function(e) {
      e.visible = false;

    });
  }
  }


  tap(energy: string) {


    const tween = this.dummy.tweenTo({rotation: energy}, 100) as paper.Tween;

    tween.onUpdate = function(event) {

      this.object.data.color = new paper.Color({
        hue: this.object.data.hue * 360,
        saturation: 1 - 0.7 * event.factor,
        brightness: 1 - 0.7 * event.factor
      });
    };
    /* */
  }

  lock() {

    const co = new paper.Color({hue: this.hue * 360, saturation: 0.4, brightness: 0.8});
    this.dummy.data.color = co;

    this.line.children[1].fillColor = co;
    if(this.line.data.otr) {
      const bounds = this.line.data.otr.bounds;
      const bot = this.dummy.bounds.bottom;

      let gradient: paper.Gradient;
      // @ts-ignore
      gradient = new paper.Gradient([[new paper.Color(co.red, co.green, co.blue, 0.6), 0], [new paper.Color(co.red, co.green, co.blue, 0.2), 0.5], [new paper.Color(co.red, co.green, co.blue, 0), 1]]);
      const gradientColor = new paper.Color(gradient, new paper.Point(0, bot), new paper.Point(0, bounds.bottom));
      this.line.data.otr.fillColor = gradientColor;
    }

    this.locked = 1;
  }

  unlock() {
    this.locked = 0;
    const tween = this.dummy.tweenTo({rotation: 0}, 100) as paper.Tween;

    tween.onUpdate = function(event) {
      this.object.data.color = new paper.Color({
        hue: this.object.data.hue * 360,
        saturation: 0.8 - 0.4 * event.factor,
        brightness: 0.8 - 0.4 * event.factor
      });
    };
  }

  prinT(content: any) {
    console.log(content);
  }
}
