
<div class="flex-c bg-w flex-col" [style.width.px]="w" [style.height.px]="h" >
<ng-container *ngIf="user">
  <h3>Save drawing</h3>
  <mat-form-field *ngIf="selectedValue <= 0" appearance="fill">

    <mat-select  [formControl]="selStory" [(ngModel)]="selectedValue" >
      <mat-option *ngIf="user.story.length > 0 " selected [value]="-1" disabled>Select story</mat-option>
      <mat-option *ngFor="let story of user.story " [value]="story.id">{{story.name}}</mat-option>
      <mat-option  [value]="0">New story</mat-option>
    </mat-select>
  </mat-form-field>

 <app-input *ngIf="selectedValue === 0 && !sujetId" (ret)="newS($event)" [type]="'story'" [size]="18" [fields]="{user:user.id}" [field]="'name'"  [placeh]="'New story name'"></app-input>

 <app-input *ngIf="selectedValue > 0 " (ret)="newSu($event)" [type]="'sujet'" [size]="18" [fields]="{user:user.id,story: selectedValue,access:'private','size': size}" [field]="'name'"  [placeh]="'new drawing name'"></app-input>



</ng-container>
  <ng-container *ngIf="!user">
You have to register or logIn to save your drawing
    <ng-container *ngIf="log === 'login'">

      <form [formGroup]="form" >

        <div class="row" *ngIf="errors">
          <div class="col">
            <div class="alert alert-danger">
              <strong>Oops!</strong> You have entered invalid credentials
            </div>
          </div>
        </div>
        <div class="row">
          <p>
            <mat-form-field appearance="standard">

              <input matInput type="email" id="email" formControlName="email" placeholder="Email">

            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="standard">
              <input matInput type="password" id="password" formControlName="password"  placeholder="Password">

            </mat-form-field>
          </p>

        </div>

      </form>

      <div class="flex-r btns">
       <button (click)="loginFu()"  mat-raised-button color="primary"  [disabled]="loading">Login</button>
      </div>

    </ng-container>
    <ng-container *ngIf="log === 'reg'">
      <form [formGroup]="formR" >
        <div class="row">
          <p>
            <mat-form-field appearance="standard">

              <input matInput type="text" formControlName="first_name" placeholder="Name">

            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="standard">

              <input matInput type="email" formControlName="email" placeholder="Email">

            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="standard">
              <input matInput type="password"  formControlName="password"  placeholder="Password">

            </mat-form-field>
          </p>
          <p>
            <mat-form-field appearance="standard">
              <input matInput type="password"  formControlName="password_confirmation"  placeholder="Confirm password">

            </mat-form-field>
          </p>
        </div>
      </form>

      <div class="flex-r btns">
        <button (click)="regFu()"  mat-raised-button   [disabled]="loading">Register</button>
     </div>

    </ng-container>
  </ng-container>

<ng-container *ngIf="!loading && load != 100000">
  Loading...
  <mat-progress-bar *ngIf="!loading && this.sujetId > 0" mode="determinate" [value]="lld"></mat-progress-bar>
  </ng-container>
  <ng-container *ngIf="loading">
 loaded
  <a [routerLink]="'/'.concat(this.sujetId.toString())">Open saved</a>
  </ng-container>

  <mat-dialog-actions align="end">
  <button *ngIf="!user" (click)="log = 'login'" mat-raised-button >Login</button>
  <button *ngIf="!user"  mat-raised-button  (click)="log = 'reg'">Register</button>
  <button *ngIf="!loading" mat-button mat-raised-button mat-dialog-close>Cancel</button>
    <button *ngIf="loading" mat-button mat-raised-button mat-dialog-close>Close</button>
  <button *ngIf="this.sujetId > 0 && !loading" mat-raised-button (click)="sendSave()" cdkFocusInitial>Save</button>
</mat-dialog-actions>
</div>
