import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import * as paper from 'paper';
import {Objet} from './objet';
import {Sujet} from './sujet';
import {fromEvent, interval, Observable, Subject} from 'rxjs';
import {BiWebsocketService, WebsocketService} from '../websocket';
import {WS} from '../websocket.events';
import {pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {IMessage, MyHammerConfig} from '../app.component';
import {User} from '../user/user';
import {Orient} from '../global-interfaces/orient';
import {PaperService} from './paper.service';
import {RestService} from '../rest/rest.service';

import {Brush} from '../global-interfaces/brush';
import {getStroke} from 'perfect-freehand';
import {LineS} from './lineS';
import {SvgComponent} from '../svg/svg.component';
import {PathS} from './pathS';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {Draw} from '../global-interfaces/draw';
import {MatSliderChange} from '@angular/material/slider';
import {Transf} from './transf';


/**/


@Component({
  selector: 'app-paper-canvas',
  templateUrl: './paper.component.html',
  styleUrls: ['./paper.component.css'],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }]
})


export class PaperComponent implements OnInit {
  @ViewChild('pop') canvasElement: ElementRef;


  @Input() type: string;
  @Input() recording: Subject<boolean>;
  @Input() selG: Subject<number>;
  @Input() undo: Subject<number>;
  @Input() newG: Subject<number>;
  @Input() process: string;
  @Input() guests: User[];
  @Input() userDb: number;
  @Input() locationDb: number;
  @Input() story: number;
  @Output() loadUsers = new EventEmitter<any>();
  @Output() pressup = new EventEmitter<any>();
  lines: LineS[];
  public project1: paper.Project;
  // project: Project;
  public tar: any;
  public currentline: any;
  currentobjet: any;
  currentsujet: any;
  po: paper.Point;
  mob: number;
  fadingItems: paper.Item[];
  public currentguest: User;
  public orient_: Orient;
  public w: number;
  public h: number;
  public tempAr: any[];
  tempLine: paper.Path;
  log = '';
  brush: Brush;
  tmLimit: number;
  ifRecord: boolean;
  moving: any;
  trans = false;
  lastPoint: paper.Point[];
  newPoint: any[];
  mat: paper.Matrix;
  svg: SvgComponent;
  drawing: Subject<Draw>;
  gsO = new Subject<LineS[]>();
  gs: LineS;
  marg: number;
  playing = new Subject<number>();
  curTime = 1000;
  per = 100;
  start: number;
  end: number;
  transforms: Transf[];
  pos0 = 0;
  pos1 = 0;
  pl = false;
  curTimeO = new Subject<number>();
  mu: any;
  mur = new Subject<any>();
  lef = 0;
  brushes: Brush[];
  private messages$: Observable<IMessage>;
  private checkEnd: any;
  private scaling: number;
  private tm: number;
  private exx: paper.Point;
  private wid: paper.Point;
  private d3 = 0;

  constructor(private bwsService: BiWebsocketService, private wsService: WebsocketService, public pService: PaperService, private restService: RestService) {
    this.mat = new paper.Matrix();


    this.gsO.subscribe(a => {
      a.forEach(b => {
        if (b.data === 'act') {
          this.gs = b;
        }

      });

    });
  }

  addGs(): LineS {
    const n = new LineS(this.svg.gs.length);
    let st = Date.now();
    if (this.currentline) {
      Math.min(Date.now(), this.currentline.start + this.currentline.delta + 800);
    }
    n.start = st;
    n.data = 'act';   //  console.log( Math.min(Date.now(), this.currentline.start + this.currentline.delta + 800));
    this.svg.gs.forEach(y => {
      if (y.data === 'act') {
        y.data = '';
      }
    });
    // n.paths.push(new PathS(this.brush.color.alpha, this.brush.radius,null));
    this.svg.gs.push(n);

    this.gsO.next(this.svg.gs);
    return n;
  }

  record(): string {
    if (!this.currentsujet.id) {
      const bod = {story: this.story, user: this.userDb};
      this.restService.addItem('sujet', bod).subscribe(loc => {
        this.drawing.next(new Draw(loc));
        this.currentsujet.id = loc;
        return this.recordO(this.currentsujet.id);
      });
    } else {
      return this.recordO(this.currentsujet.id);

    }
  }

  recordO(sid): string {
    this.svg.gs.forEach(y => {
      /*


       */

      if (y.dbId) {
        /*
        object saved

         */
        y.paths.forEach(o => {
          if (o.dbId) {
            /*
                  path saved

                    */
            this.restService.updateItem('strokes', o.dbId, {
              objet: y.dbId,
              delta: o.delta,
              color: o.color,
              width: o.radius,
              alpha: o.alpha,
              start: o.start,
              line: JSON.stringify(o.line)
            });

          } else {


            if (o.line && o.line.length > 1) {
              this.restService.addItem('strokes', {
                objet: y.dbId,
                delta: o.delta,
                color: o.color,
                width: o.radius,
                alpha: o.alpha,
                start: o.start,
                line: JSON.stringify(o.line)
              }).subscribe(res => {
                o.dbId = res;
              });
            }

          }
        });
      } else {
        if (y.paths.length > 0) {
          const trs = [];
          y.transforms.forEach(tr => {
            console.log(tr);
            trs.push([tr.start, tr.delta, tr.matrix.a, tr.matrix.tx, tr.matrix.ty]);
          });
          const last = y.paths[y.paths.length - 1];
          this.restService.addItem('objet', {
            sujet: sid,
            transforms: JSON.stringify(trs),
            user: this.userDb,
            start: y.paths[0].start,
            life: last.start + last.delta - y.paths[0].start
          }).subscribe(obj => {
            if (obj) {
              // this.currentobjet.id = obj;
              y.dbId = obj;


              y.paths.forEach(o => {
                if (o.line && o.line.length > 1) {
                  this.restService.addItem('strokes', {
                    objet: obj,
                    delta: o.delta,
                    color: o.color,
                    width: o.radius,
                    alpha: o.alpha,
                    start: o.start,
                    line: JSON.stringify(o.line)
                  }).subscribe(res => {
                    o.dbId = res;
                  });
                }

              });
            }
          });
        }
      }
    });

    return 'd';
  }

  endLine(ev) {

    this.tmLimit = 0;
    if (this.gs) {


      const endl = ev instanceof MouseEvent ? new paper.Point(ev.clientX, ev.clientY) : new paper.Point(ev.changedTouches[0].clientX,
        ev.changedTouches[0].clientY);
      const di = this.po.subtract(endl).length;
      this.end = Date.now();
      this.curTimeO.next(this.end);
      this.gs.delta = this.end - this.gs.start;
      const delta = this.end - this.start;
      this.gs.paths[this.gs.paths.length - 1].line = this.newPoint;
      this.gs.paths[this.gs.paths.length - 1].delta = delta;

      const brush = this.pService.getBrush();
      if (di > 5) {


      } else {
        if (delta > 300) {
          /*
          новый сюжет



          //  this.gs.paths.pop();
          this.mat = new paper.Matrix();
          this.currentobjet = false;
          this.pressup.emit(this.gs);
          this.gs = this.addGs();
        */
        }

      }
      /*
      if (di>5) {

        let pts = '[';
        this.tempLine.simplify(2);
        for (var segment of this.tempLine.segments) {
          pts = pts.concat(segment.toString(), ',');
        }
        pts = pts.replace(/,$/, ']').replace(/ /g, '').replace(/oint/g, '').replace(/handle/g, '');

        this.tempLine.remove();
        const ob = this.currentobjet.item.bounds;
        const teBounds = 'LINESTRING('.concat(ob.topLeft.x, ' ', ob.topLeft.y, ',', ob.topRight.x, ' ', ob.topRight.y, ',',
          ob.bottomRight.x, ' ', ob.bottomRight.y, ',', ob.bottomLeft.x, ' ', ob.bottomLeft.y, ')');

        if (this.ifRecord) {
          if (this.currentobjet.sujet_id === 1) {
            this.restService.updateItem('objet', this.currentobjet.id, {bounds: teBounds, sujet: this.currentsujet.id});
          }
          this.restService.updateItem('strokes', this.currentobjet.id, {bounds: teBounds, sujet: this.currentsujet.id});
          this.restService.addPath(this.currentobjet.id, brush, pts, delta);
        }
      } else {
        if (delta > 500) {
        //  this.pressup.emit(ev);

        }


    } */
    }
  }

  draw = function(ee) {

    const po = new paper.Point(ee.clientX, ee.clientY);


  };

  stopDraw = function(ee, n: number) {
    console.log(this, n);
    ee.target.removeEventListener('mousemove', this.draw);

  };

  ngOnInit() {
    //
    if (this.type === 'draw') {
      this.recording.subscribe(a => {
        this.record();
      });
      this.marg = 60;
    } else {
      this.marg = 0;
    }
    this.w = window.innerWidth - this.marg * 2;
    this.h = window.innerHeight - this.marg;
    this.mu = {x: this.w - 40, y: 0};
    this.mur.next(this.mu);
    this.curTimeO.subscribe(o => {
      this.curTime = o;
    });


    this.playing.subscribe(
      o => {


        this.svg.gs.forEach(a => {

            if (a.start < o && a.start + a.delta > o && a != this.svg.gsR[0]) {
              this.svg.gsR = [a];

              console.log(a);
            }
          }
        );

        const gsR = this.svg.gsR[0];
        let uu;
        let tra;
        gsR.transforms.forEach((t, i) => {
          if (t.start < o && o < t.start + t.delta) {


            let tra0;

            if (i > 0 && gsR.transforms[i - 1]) {

              tra0 = gsR.transforms[i - 1].matrix;

            } else {
              tra0 = new paper.Matrix();
            }
            tra = t.matrix;

            const co = (o - t.start) / t.delta;
            const tt = tra0.a + (tra.a - tra0.a) * co;

            const nu = new paper.Matrix(tt, 0, 0, tt,
              tra0.tx + (tra.tx - tra0.tx) * co,
              tra0.ty + (tra.ty - tra0.ty) * co);
            gsR.trans = 'matrix('.concat([nu.a, nu.b, nu.c, nu.d, nu.tx, nu.ty].join(' '), ')');


          }
        });
        gsR.paths.forEach(u => {

          if (u.start < o && o < u.start + u.delta) {

            uu = u;
          } else {
            if (o > u.start + u.delta) {
              u.dR = u.d;
            } else {
              u.dR = '';
            }
          }


        });

        if (uu) {
          const sl = uu.line.slice(0, Math.ceil(uu.line.length * (o - uu.start) / uu.delta));
          const opts = {
            size: uu.radius,

          };

          const ar = getStroke(sl, opts);


          uu.dR = this.pService.getSvgPathFromStroke(ar);

        }


      }
    );
    if (this.newG) {
      this.newG.subscribe(y => {
        this.mat = new paper.Matrix();
        this.currentobjet = false;

        this.pressup.emit(this.gs);
        this.gs = this.addGs();


      });
    }
    this.drawing = new Subject<Draw>();
    if (this.undo) {
      this.undo.subscribe(a => {
        if (a === 1) {

          if (this.gs.paths.length > 0) {
            this.gs.paths.pop();
          }

        }
      });
    }
    if (!this.selG) {
      this.selG = new Subject<number>();
    }
    this.selG.subscribe(g => {

      if (this.type === 'draw') {
        this.wsService.send(WS.SEND.DRAW, JSON.stringify({selg: g}));
      }
      if (!this.checkEnd) {
        this.checkEnd = setInterval(() => {
          // this.tmLimit++;
          let li = 0;
          this.svg.gs.forEach(y => {
            if (y.data === 'fading') {
              y.alpha -= 0.01;
              li = Math.max(li, y.alpha);
              if (y.alpha <= 0) {

                y.data = 'faded';
              }
            }


          });
          //  this.svg.gs = this.svg.gs.map(o=>{if(o.data!='faded') return o;});
          if (li <= 0) {
            clearInterval(this.checkEnd);
          }

        }, 100);
      }
      this.svg.gs.sort((a, b) => {
        if (a.id === g) {
          return 1;
        }
        if (b.id === g) {
          return -1;
        }
      }).forEach(b => {
        if (b.id === g) {
          b.data = '';
          b.alpha = 1;
        } else {
          if (b.data != 'faded') {
            b.data = 'fading';
          }

        }

      });
      this.gs = this.addGs();


    });


    this.moving = new paper.Point(0, 0);
    this.scaling = 1;


    if (this.type === 'draw' || this.process === 'canvas') {
      this.fadingItems = [];
      this.currentsujet = false;
      this.currentobjet = false;
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
        this.mob = 1;
      }

      // window['paper'] = paper;

      //
      //  this.canvasElement.ontouchstart

      if (this.process === 'canvas') {
        this.messages$ = this.wsService.on<IMessage>(WS.ON.DRAWINGS);
        this.messages$.subscribe(todos => {


          if (typeof (todos) === 'object') {

            const inp = JSON.parse(String(todos.value));

            if (todos.type === 'draw') {

              const cGuest = this.guests.find(user => user.userId === todos.user);

              if (cGuest) {

                if (inp.stx) {
                  //  const col=JSON.parse(inp.stx[2]);

                  this.brush = new Brush(inp.stx[2][4], new paper.Color(inp.stx[2][0], inp.stx[2][1], inp.stx[2][2], inp.stx[2][3]), 0);
                  this.pService.setBrush(this.brush);
                  //      const pa=new paper.Point((this.po.x - rect.left)/this.mat.a-this.mat.tx/this.mat.a, (this.po.y - rect.top)/this.mat.a-this.mat.ty/this.mat.a);


                  cGuest.po = new paper.Point((Number(inp.stx[0]) * cGuest.coef) / this.mat.a - this.mat.tx / this.mat.a, (Number(inp.stx[1]) * cGuest.coef) / this.mat.a - this.mat.ty / this.mat.a);
                  this.newPoint = [{x: cGuest.po.x, y: cGuest.po.y, pressure: 0}];
                  this.start_p(cGuest);

                } else if (inp.draw) {
                  this.drawOnHost(new paper.Point(Number(inp.draw[0]) * cGuest.coef,
                      Number(inp.draw[1]) * cGuest.coef),
                    cGuest, inp.draw[2], inp.draw[3]);
                } else if (inp.scale) {
                  //  console.log(cGuest.coef);

                  this.gs.trans = 'matrix('.concat([inp.scale[0], 0, 0, inp.scale[0], inp.scale[1] * cGuest.coef, inp.scale[2] * cGuest.coef].join(' '), ')');

                  this.mat = new paper.Matrix(inp.scale[0], 0, 0, inp.scale[0], inp.scale[1] * cGuest.coef, inp.scale[2] * cGuest.coef);

                } else if (inp.end_o) {

                  this.gs = this.addGs();

                } else if (inp.selg) {
                  this.selG.next(inp.selg);
                }
              }

            }
          }

        });
      }
    }
  }

  ngAfterViewInit() {

    paper.setup(this.canvasElement.nativeElement);
// paper.view.autoUpdate = true;

    //  this.bwsService.conne('?t=1');
    this.project1 = paper.project;
    const size = paper.view.size;
    paper.project.activeLayer.data.beta = 12;
    paper.project.activeLayer.addChild(new paper.Group());
    paper.project.activeLayer.addChild(new paper.Group());
    paper.project.activeLayer.addChild(new paper.Group());
    this.pService.initialize();
    this.loadUsers.emit(1);
    this.brush = this.pService.getBrush();


    // const bn = new paper.Path.Circle(new paper.Point(200,300), 40);
    // paper.project.activeLayer.addChild(bn);
    // bn.fillColor = new paper.Color('red');
    /* paper.view.onFrame = function(){
         bn.translate(new paper.Point(2,2));
        // bn.remove();

     }


     this.project1.activeLayer.onMouseDown = ((event) => {

         console.log(event);

     });

 */
    const canvasEl: HTMLCanvasElement = paper.view.element;
    if (this.type === 'guest') {
      this.captureEvents_guest(canvasEl);
    }


    if (this.type === 'draw') {

      fromEvent(canvasEl, 'touchend').subscribe(ev => {
        this.endLine(ev);
      });
      fromEvent(canvasEl, 'mouseup').subscribe(ev => {
        this.endLine(ev);
      });
      // @ts-ignore
      if (this.process === 'canvas' || this.type === 'draw') {
        //  console.log(canvasEl,this.canvasElement.nativeElement);
        this.captureEventsS(this.canvasElement.nativeElement);
        if (this.mob === 1) {

        } else {

          //  this.captureEvents_(canvasEl);
        }
      }
    }
  }


  touch(event: any) {
    event.preventDefault();

    const canvasEl: HTMLCanvasElement = paper.view.element;

    //  canvasEl.removeEventListener('pointermove');
    const rect = canvasEl.getBoundingClientRect();
    this.exx = new paper.Point(0, 0);

    this.po = event instanceof MouseEvent ? new paper.Point(event.clientX, event.clientY) : new paper.Point(event.touches[0].clientX,
      event.touches[0].clientY);
    const pa = new paper.Point((this.po.x - rect.left) / this.mat.a - this.mat.tx / this.mat.a, (this.po.y - rect.top) / this.mat.a - this.mat.ty / this.mat.a);

    this.newPoint = [{x: pa.x, y: pa.y, pressure: 0}];
    this.lastPoint = [new paper.Point(this.po.x - rect.left, this.po.y - rect.top)];
    this.start_p(this);
  }

  start_p(target) {

    this.brush = this.pService.getBrush();
    const brush = this.brush;
    if (target === this) {

      this.wsService.send(WS.SEND.DRAW, JSON.stringify({stx: [target.po.x, target.po.y, [brush.color.red, brush.color.green, brush.color.blue, brush.color.alpha, brush.radius]]}));
    }
    target.tempAr = [];

    // pa.data.strokeColor = new paper.Color(0, 0, 0);
    // target.currentline = {id: 0} as Line;

    if (this.svg.gs.length > 1) {

      //  this.svg.gs[this.svg.gs.length - 2].data = 'fading';
      this.checkEnd = setInterval(() => {
        // this.tmLimit++;
        let li = 0;
        this.svg.gs.forEach(y => {
          if (y.data === 'fading') {
            y.alpha -= 0.01;
            li = Math.max(li, y.alpha);
            if (y.alpha <= 0) {

              // y.data = 'faded';
            }
          }


        });
        //  this.svg.gs = this.svg.gs.map(o=>{if(o.data!='faded') return o;});
        if (li <= 0) {
          clearInterval(this.checkEnd);
        }

      }, 100);
    }
    if (!this.gs) {
      console.log('this.gs');
      this.gs = this.addGs();
      console.log('g', this.gs);
      if (this.currentline) {
        this.gs.start = Math.min(Date.now(), this.currentline.start + this.currentline.delta + 800);
      } else {
        this.gs.start = Date.now();
      }
    }

    const pat = new PathS(this.brush.color.alpha, this.brush.radius, this.currentline);
    pat.alpha = this.brush.color.alpha;
    this.gs.paths.push(pat);
    this.currentline = pat;
    // target.currentline.opacity = this.brush.color.alpha;
    // target.tempAr.push([1, stp, stp, stp]);
    this.start = Date.now();

    if (!target.currentsujet) {
      target.currentsujet = {
        objets: [], start: this.start
      } as Sujet;


      // const gr = new paper.Group();
      // gr.name = 'sujet';
      //   paper.project.activeLayer.addChild(gr);

      // target.currentsujet.item = gr;
    }

    // this.currentline.geometry.addSegments([new paper.Segment(this.po)]);
    if (!target.currentobjet || target.currentobjet.name !== 'last') {
      /***
       * trigger fading of last finished sketch
       *
       *
       */
      if (target.currentobjet && target.currentobjet.name === 'fading_') {
        target.currentobjet.name = 'fading';
        target.currentobjet.start = Date.now();
      }
      // target.currentobjet = {} as Objet;
      //  target.currentobjet.start = new Date().getMilliseconds();
      if (target === this) {
        let tempS = 0;
        if (this.currentsujet.id > 0) {
          tempS = this.currentsujet.id;
        } else {
          tempS = 1;
        }
        if (!this.currentobjet) {
          this.currentobjet = {id: 0} as Objet;
        }

      }

      //  target.currentobjet.lines = [target.currentline];

    } else {
      // target.currentline.id = target.currentobjet.lines.length - 1;
      //  target.currentobjet.lines.push(target.currentline);
      //  target.currentobjet.item.addChild(pa);
    }

    //  console.log(, target.currentobjet.item);
    /*
if(target.currentsujet.lastChild.name === ){
            target.currentsujet.objets.push(target.currentobjet);

target.currentsujet.item.addChild(target.currentobjet.item);
}
*/

  }

  drag(event: any) {
    event.preventDefault();
    console.log(event);
  }

  tch($event: TouchEvent) {
    $event.preventDefault();

  }

  app(ev: any) {
    alert(ev);

  }

  scale(ev: any) {
    this.trans = true;
    // this.tempLine.remove();
    //  this.currentline.geometry.remove();
    const delta = new paper.Point(ev.deltaX, ev.deltaY).subtract(this.moving);
    this.moving = new paper.Point(ev.deltaX, ev.deltaY);
    const cent = ev.center as paper.Point;
    const sc = ev.scale / this.scaling;
    this.scaling = ev.scale;
    this.mat = new paper.Matrix().scale(sc, cent).translate(delta);
    const vals = this.gs.trans.replace('matrix(', '').replace(')', '').split(' ').map(o => parseFloat(o));
    const omat = new paper.Matrix(vals);
    this.mat = this.mat.append(omat);
    this.gs.trans = 'matrix('.concat([this.mat.a, this.mat.b, this.mat.c, this.mat.d, this.mat.tx, this.mat.ty].join(' '), ')');


    this.wsService.send(WS.SEND.DRAW,
      JSON.stringify({'scale': [this.mat.a, this.mat.tx, this.mat.ty]}));

  }

  pinchS($event: any) {
    this.gs.transforms.push(new Transf(Date.now(),true));

  }

  pout($event: any) {
    this.trans = false;
    this.moving = new paper.Point(0, 0);
    this.scaling = 1;
    const tr = this.gs.transforms[this.gs.transforms.length - 1];
    tr.delta = Date.now() - tr.start;
    tr.matrix = this.mat;

    //  this.wsService.send(WS.SEND.DRAW,
    //   JSON.stringify({'scale': [1, 0, 0]}));
  }

  /*
    public testDdr(p1, p0) {
      this.tempLine.addSegments([new paper.Segment(p1)]);
      const segments2 = [p1, p0]; //, prevPos.add(mid1)
      const t2 = new paper.Path(segments2);
      t2.strokeColor = new paper.Color(0, 0, 0);
      this.currentline.geometry.addChild(t2);
    }
  */
  ini(sv: SvgComponent) {
    this.svg = sv;

  }

  play(event: MatSliderChange) {
    this.per = event.source.percent * 100;

    this.playing.next(event.value);


  }

  nn(ev: MatSliderChange) {

    this.per = ev.source.percent * 100;
    this.pl = true;
    const sta = Date.now();
    if (ev.value < this.end) {


      this.svg.playingObs.next(true);

      const num = interval(10);
      const sub = num.subscribe(n => {

        this.curTime = ev.value + Date.now() - sta;
        this.per = (this.curTime - this.currentsujet.start) / (this.end - this.currentsujet.start) * 100;

        this.playing.next(Date.now() - sta + ev.value);
        if (Date.now() - sta + ev.value >= this.end) {
          this.svg.playingObs.next(false);
          this.pl = false;
          sub.unsubscribe();

        }
      });
      /*
      const ii=setInterval(function(){

        this.per+=1;
        console.log(this,this.per);
        this.curTime=this.per*10;
        if(this.per>=100){

          clearInterval(ii);

        }


      },100)
      */

    }
  }


  private captureEvents_guest(canvasEl: HTMLCanvasElement) {

    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'touchstart', {passive: true})
      .pipe(
        switchMap((e) => {
          e.preventDefault();

          // after a mouse down, we'll record all mouse moves

          // this.touch(e);
          // @ts-ignore
          return fromEvent(canvasEl, 'touchmove', {passive: true})
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'touchend')),

              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'touchcancel')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
          const rect = canvasEl.getBoundingClientRect();

          // previous and current position with the offset
          if (res[0].changedTouches.length === 1) {
            const rtn = res[0].changedTouches[0];


            // this method we'll implement soon to do the actual drawing
            // this.drawOnCanvas(prevPos, currentPos, rtn1.force);
          }
        }, () => {
        }, () => {
          console.log('Observer got a complete notification');
        }
        /* */
      );
  }

  private captureEvents_(canvasEl: HTMLCanvasElement) {
    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        switchMap((e) => {
          // after a mouse down, we'll record all mouse moves
          this.touch(e);
          return fromEvent(canvasEl, 'mousemove')
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        this.pService.newDraw(res[0].clientX - rect.left, res[0].clientY - rect.top, 1, this.gs.paths[this.gs.paths.length - 1], this.brush, this.newPoint, this.mat);
        const prevPos = new paper.Point(res[0].clientX - rect.left, res[0].clientY - rect.top);


        const currentPos = new paper.Point(res[1].clientX - rect.left, res[1].clientY - rect.top);


        // this method we'll implement soon to do the actual drawing
        // this.drawOnCanvas(prevPos, currentPos, 1);
      }, () => {
      }, () => {
        console.log('Observer got a complete notification');
      });
  }

  private captureEvents(canvasEl: HTMLCanvasElement) {

    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'touchstart', {passive: true})
      .pipe(
        switchMap((e) => {
          e.preventDefault();

          // after a mouse down, we'll record all mouse moves
          this.touch(e);
          this.wid = new paper.Point(0, 0);
          this.tm = new Date().getTime();
          return fromEvent(canvasEl, 'touchmove', {passive: true})
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'touchend')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'touchcancel')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
          const rect = canvasEl.getBoundingClientRect();

          // previous and current position with the offset
          if (res[0].changedTouches.length === 1) {
            const rtn = res[0].changedTouches[0];

            // this.log = 22 + res[0].changedTouches.item(0).radiusX;
            const rtn1 = res[1].changedTouches[0];
            if (rtn1.clientX) {
              const pa = new paper.Point(rtn1.clientX - rect.left - this.mat.tx, rtn1.clientY - rect.top - this.mat.ty);

              this.pService.newDraw(pa.x, pa.y, rtn1.force, this.gs.paths[this.gs.paths.length - 1], this.brush, this.newPoint, this.mat);
            }
            /*
            const prevPos = new paper.Point(rtn.clientX - rect.left, rtn.clientY - rect.top);


            const currentPos = new paper.Point(rtn1.clientX - rect.left, rtn1.clientY - rect.top);
*/
            //   this.testDdr(prevPos, currentPos);
            //  this method we'll implement soon to do the actual drawing
            // this.drawOnCanvas(prevPos, currentPos, rtn1.force);
          }
        }, () => {
        }, () => {
          console.log('Observer got a complete notification');
        }
        /* */
      );
  }

  private captureEventsS(canvasEl: HTMLCanvasElement) {

    // this will capture all mousedown events from the canvas element
    fromEvent(canvasEl, 'touchstart', {passive: true})
      .pipe(
        switchMap((e) => {
          e.preventDefault();
          if (!this.trans) {
            this.touch(e);
          }


          // after a mouse down, we'll record all mouse moves
          /*
     this.wid=new paper.Point(0,0);
               this.tm = new Date().getTime();

           */
          return fromEvent(canvasEl, 'touchmove', {passive: true})
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'touchend')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'touchcancel')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
          const rect = canvasEl.getBoundingClientRect();

          // previous and current position with the offset
          if (res[0].changedTouches.length === 1 && !this.trans) {
            const rtn = res[0].changedTouches[0];

            // this.log = 22 + res[0].changedTouches.item(0).radiusX;
            const rtn1 = res[1].changedTouches[0];
            if (rtn1.clientX) {


              this.pService.newDraw(rtn1.clientX - rect.left, rtn1.clientY - rect.top, rtn1.force, this.gs.paths[this.gs.paths.length - 1], this.brush, this.newPoint, this.mat);
              this.wsService.send(WS.SEND.DRAW, JSON.stringify({'draw': [rtn1.clientX - rect.left, rtn1.clientY - rect.top, rtn1.force, this.brush.color.toString(), this.brush.radius]}));

            }
            /*
            const prevPos = new paper.Point(rtn.clientX - rect.left, rtn.clientY - rect.top);


            const currentPos = new paper.Point(rtn1.clientX - rect.left, rtn1.clientY - rect.top);
*/
            //   this.testDdr(prevPos, currentPos);
            //  this method we'll implement soon to do the actual drawing
            // this.drawOnCanvas(prevPos, currentPos, rtn1.force);
          }
        }, () => {
        }, () => {
          console.log('Observer got a complete notification');
        }
        /* */
      );
  }

  private drawOnHost(pos: paper.Point, cGuest: User, wi, lcol) {
    this.pService.newDraw(pos.x, pos.y, wi, this.gs.paths[this.gs.paths.length - 1], this.brush, this.newPoint, this.mat);
  }


}
