import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {PaperComponent} from './paper/paper.component';
import {AppComponent} from './app.component';
import {HostComponent} from './host/host.component';
import {GuestComponent} from './guest/guest.component';
import {DrawerComponent} from './drawer/drawer.component';
import {ColorComponent} from './color/color.component';
import {ContentControllerComponent} from './content-controller/content-controller.component';
import {TestComponent} from './test/test.component';
import {VisitorComponent} from './visitor/visitor.component';
import {UserComponent} from './user/user.component';
import {StoryComponent} from './story/story.component';
import {PlayerComponent} from './player/player.component';

const routes: Routes = [

  {
    path: ':location/replay/:user/:story',
    component: ContentControllerComponent,
    children: [

    ]
  },
  {
    path: ':location/visit',
    component: VisitorComponent,
    children: [

    ]
  },
  {
    path: 'story/:story',
    component: HostComponent,
    children: [
      {
        path: '',
        component: StoryComponent
      }
    ]
  },
  {
    path: 'test',
    component: ContentControllerComponent,
    children: [

    ]
  },
  {
    path: ':location/drawer',
    component: DrawerComponent,
    children: [

    ]
  },
  {
    path: 'testa',
    component: TestComponent,
    children: [

    ]
  },
    {
        path: ':location/draw',
        component: HostComponent,
        children: [
            {
                path: '',
                component: DrawerComponent
            }

        ]
    },
  {
    path: '',
    component: HostComponent,
    children: [


    ]
  },
    {
        path: ':location/guest',
        component: HostComponent,
        children: [
            {
                path: '',
                component: PaperComponent
            },
            {
                path: 'color',
                component: ColorComponent
            }

        ]
    },
  {
    path: ':location/canvas',
    component: HostComponent,
    children: [
      {
        path: '',
        component: PaperComponent
      }

    ]
  },
    {
        path: ':location/host',
        component: HostComponent,
        children: [
            {
                path: '',
                component: PaperComponent
            }

        ]
    },
    {
        path: '',
        component: PaperComponent,
        children: []
    }
    ,
    {
        path: ':location',
        component: HostComponent,
        children: [
          { path: ':id',

  children: []
          }
        ]
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabled'
})],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

