import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {fromEvent, Subject, Subscription} from 'rxjs';
import {PathS} from '../paper/pathS';
import {Brush} from '../global-interfaces/brush';
import {PaintService} from './paint.service';
import {RestService} from '../rest/rest.service';
import {HttpClient} from '@angular/common/http';
import {BROWSER_STORAGE, BrowserStorageService} from '../storage/storage.service';
import {pairwise, switchMap, takeUntil} from 'rxjs/operators';
import {MatMenuTrigger} from '@angular/material/menu';
import {ColorComponent} from '../color/color.component';
import paper from 'paper';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {SvgComponent} from '../svg/svg.component';
import {LineS} from '../paper/lineS';
import {getStroke} from 'perfect-freehand';
import {Sujet} from '../paper/sujet';
import {User} from '../user/user';
import {Transf} from '../paper/transf';
import {SaveComponent} from '../save/save.component';

@Component({
  selector: 'app-paint',
  templateUrl: './paint.component.html',
  styleUrls: ['./paint.component.css'],
  providers: [RestService, HttpClient, {provide: Window, useValue: window},
    BrowserStorageService,
    {provide: BROWSER_STORAGE, useFactory: () => sessionStorage}
  ]
})
export class PaintComponent implements OnInit {
  @ViewChild('cont') divC: ElementRef;
  @ViewChild('svgg') svgg: ElementRef;
  @Input() size: any;
  @Input() sujet: Sujet;
  @Input() play: boolean;
  @Output() draw = new EventEmitter<any>();
  @Output() colorCh = new EventEmitter<any>();
  @Input() par: Subject<number>;
  @Input() userO: Subject<User>;
  @Input() userR: User;
  user: User;
  brush: Brush;
  brushes: Brush[];
  currentLine: PathS;
  @ViewChild(MatMenuTrigger) trig: MatMenuTrigger;
  parp = 0;
  line: PathS;
  objet: LineS;
  paint = true;
  mover: paper.Group;
  stPoint: paper.Point;
  progress: Subject<number>;
  private scaling: number;
  private svg: SvgComponent;
  private openDialog: MatDialogRef<ColorComponent, any>;
  private saveDialog: MatDialogRef<SaveComponent, any>;
  private drawing: boolean;
  private colorOpened: boolean;
  private tSta: any;
  private trans: boolean;
  private stateD: Subscription;
  private mat: paper.Matrix;
  private colors: paper.Color[];
  private moving: paper.Point;
  private newTrans: Transf;

  constructor(public restService: RestService, public paintService: PaintService, public menuDialog: MatDialog) {


  }

  ini(sv: SvgComponent): void {
    this.scaling = 1;
    this.svg = sv;
    this.svg.gsR = [];
    this.svg.gs = [];
    if (this.colors) {
      this.svg.colors = this.colors;
    }

  }

  ngOnInit(): void {

    this.userO.subscribe(u => {
      if (u.id) {
        this.restService.loaduser(u.id.toString()).subscribe(a => {
          this.user = a;

        });
      }

    });

    this.colors = [];
    this.drawing = false;

    this.mat = new paper.Matrix();
    this.brushes = this.paintService.getBrushes();

    const brushe = this.brushes.filter(a => {
      this.colors.push(a.color);
      this.colorCh.emit(a.color);
      if (a.active === true) {
        return a;
      }
    });
    this.brush = brushe[0];
    if (this.svg) {
      this.svg.colors = this.colors;
    }
    this.par.subscribe(h => {
      this.parp = h;
      if (h === 1) {

        this.startDrawO();
        //  this.trig.openMenu();
      } else {
        // this.trig.closeMenu();
        this.divC.nativeElement.removeEventListener('touchmove', this.tSta);
      }
    });

  }

  stopDrawO(): void {
    this.stateD.unsubscribe();

  }

  startDrawO(): void {

    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
      //  this.mob = 1;
      this.stateD = this.captureEventsS(this.divC.nativeElement);
    } else {

      this.stateD = this.captureEventsM(this.divC.nativeElement);

    }


    /*
       this.tSta = this.divC.nativeElement.addEventListener('touchstart', event => {
         const rect = this.divC.nativeElement.getBoundingClientRect();
         const po = event instanceof MouseEvent ? new paper.Point(event.clientX, event.clientY) : new paper.Point(event.touches[0].clientX,
           event.touches[0].clientY);
         this.draw.emit(this.draw.emit([po.x - rect.left, po.y - rect.top, 0, this.brush, 0]));
        const tEnd = this.divC.nativeElement.addEventListener('touchend', evente => {


           const pos = evente instanceof MouseEvent ? new paper.Point(evente.clientX, evente.clientY) : new paper.Point(evente.changedTouches[0].clientX,
             evente.changedTouches[0].clientY);
           this.draw.emit([pos.x - rect.left, pos.y - rect.top, evente.changedTouches[0].force, this.brush, 2]);
           this.divC.nativeElement.removeEventListener('touchmove', tMove);
          this.divC.nativeElement.removeEventListener('touchend', tEnd);

         }, {passive: false});
         const tMove = this.divC.nativeElement.addEventListener('touchmove', events => {
           const pod = events instanceof MouseEvent ? new paper.Point(events.clientX, events.clientY) : new paper.Point(events.changedTouches[0].clientX,
             events.changedTouches[0].clientY);
           this.draw.emit([pod.x - rect.left, pod.y - rect.top, events.changedTouches[0].force, this.brush, 1]);

         }, {passive: false});
       }, {passive: false});
     */
  }

  touch(event: any, canvasEl): void {
    if (event instanceof MouseEvent || (event.touches && event.touches.length == 1)) {

      const rect = canvasEl.getBoundingClientRect();
      const po = event instanceof MouseEvent ? new paper.Point(event.clientX, event.clientY) : new paper.Point(event.touches[0].clientX,
        event.touches[0].clientY);
      let pr;


      if (event instanceof MouseEvent) {
        pr = 1;


      } else {

        pr = event.touches[0].force;
      }

      // this.draw.emit([po.x - rect.left, po.y - rect.top, 0, this.brush, 0]);
      if (!this.line) {
        this.line = new PathS(this.brush.color.alpha, this.brush.radius);
      } else {
        const olLine = this.line;
        this.line = new PathS(this.brush.color.alpha, this.brush.radius, olLine);
      }
      if (this.svg.gs.length === 0) {
        this.objet = new LineS(0);
        this.objet.start = Date.now();
        this.svg.gs.push(this.objet);

      } else {


      }


      this.objet.paths.push(this.line);


      if (this.brush.radius < 600 || this.brush.color.alpha > 0.6) {
        this.line.color = 'RGB(' + this.brush.color.red + ',' + this.brush.color.green + ',' + this.brush.color.blue + ')';
        this.line.alpha = this.brush.color.alpha;
      } else {
        this.line.color = 'url(#Gradient' + this.brush.id + ')';
        this.line.alpha = 1;
      }

      this.newDraw(po.x - rect.left, po.y - rect.top, pr);

      //  console.log(this.svg.gs);
    }
  }

  move(pai): void {
    this.paint = pai;
    this.draw.emit(pai);
    if (pai) {
      this.startDrawO();
    }
  }

  endT($event: any) {
    // this.objet.delta=

    this.line.delta = Date.now() - this.line.startR;

    if (this.objet) {
      this.objet.delta = Date.now() - this.line.startR;
      //  this.draw.emit(this.objet);
      //this.line.visible = false;
    }

  }

  openColorDialog(): void {
    if (!this.colorOpened) {
      const dialogRef = this.menuDialog.open(ColorComponent, {
        hasBackdrop: true,
        panelClass: 'color',
        data: {brushes: this.brushes, brush: this.brush}
      });
      this.colorOpened = true;
      dialogRef.afterClosed().subscribe(result => {
        result.forEach((a, i) => {

          this.brushes[i].color = new paper.Color(a.rgba.r, a.rgba.g, a.rgba.b, a.rgba.a);
          this.svg.colors.push(new paper.Color(a.rgba.r, a.rgba.g, a.rgba.b, a.rgba.a));

        });
        this.colorOpened = false;
      });


    } else {
      this.menuDialog.closeAll();

    }

  }


  startDraw(e: Brush) {
    if (!e.active) {
      this.drawing = false;
    } else {
      this.drawing = true;
    }
    this.brushes.forEach(a => {
      if (a === e) {
        a.active = true;
      } else {
        a.active = false;
      }
    });
    this.brush = e;


    // console.log('d');
    //  this.trig.openMenu();
  }

  getSvgPathFromStroke(stroke: any[]) {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }

  public newDraw(x: number, y: number, pressure: number): void {
    if (this.paint) {
      const p1 = new paper.Point(x / this.mat.a - this.mat.tx / this.mat.a, y / this.mat.a - this.mat.ty / this.mat.a);

      this.line.line.push({x: p1.x, y: p1.y, pressure: pressure});

      const opts = {
        size: this.brush.radius,
        start: {
          taper: 0,
          easing: (t) => t,
          cap: false
        },
        end: {
          taper: 0,
          easing: (t) => t,
          cap: false
        }
      };

      const ar = getStroke(this.line.line, opts);

      const p = this.line;
      p.d = this.getSvgPathFromStroke(ar);
    }
  }

  undo(): void {
    this.objet.paths.pop();
    this.line = this.objet.paths[this.objet.paths.length - 1];
  }

  newO() {
    this.mat = new paper.Matrix();
    this.objet = new LineS(this.svg.gs.length);
    this.objet.start = Date.now();
    this.svg.gs.push(this.objet);
  }

  save(): void {
    this.progress = new Subject<number>();
    const dialogRef = this.menuDialog.open(SaveComponent, {
      hasBackdrop: true,
      panelClass: 'color',
      data: {user: this.user, sujet: this.sujet,  emmmiter: this.progress}
    });
    dialogRef.componentInstance.sujet$.subscribe(e=>{
      this.saveR(e);
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {


      }

    });
  }

  public saveR(f): void {
    this.sujet = new Sujet();
    this.sujet.id = f;

    if (this.user.id) {
      const sid = this.sujet.id;
      let strokeNum = 0;
      this.svg.gs.forEach(y => {
        y.paths.forEach(o => {
          strokeNum++;
        });
      });
      this.progress.next(strokeNum);
      this.svg.gs.forEach(y => {
        if (y.dbId) {
          /*
          object saved

           */
          y.paths.forEach(o => {
            if (o.dbId) {
              /*
                    path saved

                      */
              this.restService.updateItem('strokes', o.dbId, {
                objet: y.dbId,
                delta: o.delta,
                color: o.color,
                width: o.radius,
                alpha: o.alpha,
                start: o.start,
                line: JSON.stringify(o.line)
              });

            } else {


              if (o.line && o.line.length > 1) {
                this.restService.addItem('strokes', {
                  objet: y.dbId,
                  delta: o.delta,
                  color: o.color,
                  width: o.radius,
                  alpha: o.alpha,
                  start: o.start,
                  line: JSON.stringify(o.line)
                }).subscribe(res => {
                  o.dbId = res;
                  strokeNum--;
                  this.progress.next(strokeNum);
                });
              }

            }
          });
        } else {
          if (y.paths.length > 0) {
            const trs = [];
            let lastend = 0;
            y.transforms.forEach(tr => {
              if (tr.cas) {
                lastend = tr.start + tr.delta;
              }
              trs.push([tr.start, tr.delta, tr.matrix.a, tr.matrix.tx, tr.matrix.ty]);
            });
            const last = y.paths[y.paths.length - 1];
            lastend = Math.max(last.start + last.delta, lastend);


            this.restService.addItem('objet', {
              sujet: sid,
              transforms: JSON.stringify(trs),

              start: y.paths[0].start,
              delta: lastend - y.paths[0].start,
              deltaR: lastend
            }).subscribe(obj => {
              if (obj) {
                // this.currentobjet.id = obj;
                y.dbId = obj;


                y.paths.forEach(o => {
                  if (o.line && o.line.length > 1) {
                    this.restService.addItem('strokes', {
                      objet: obj,
                      delta: o.delta,
                      color: o.color,
                      width: o.radius,
                      alpha: o.alpha,
                      start: o.start,
                      line: JSON.stringify(o.line)
                    }).subscribe(res => {
                      o.dbId = res;
                      strokeNum--;
                      this.progress.next(strokeNum);
                    });
                  }

                });
              }
            });
          }
        }
      });

    } else {

      alert('need to login or register');
    }


  }

  scale(ev: any) {
    this.trans = true;
    // this.tempLine.remove();
    //  this.currentline.geometry.remove();
    const delta = new paper.Point(ev.deltaX, ev.deltaY).subtract(this.moving);
    this.moving = new paper.Point(ev.deltaX, ev.deltaY);
    const cent = ev.center as paper.Point;
    const sc = ev.scale / this.scaling;
    this.scaling = ev.scale;
    this.mat = new paper.Matrix().scale(sc, cent).translate(delta);
    const vals = this.objet.trans.replace('matrix(', '').replace(')', '').split(' ').map(o => parseFloat(o));
    const omat = new paper.Matrix(vals);
    this.mat = this.mat.append(omat);
    this.objet.trans = 'matrix('.concat([this.mat.a, this.mat.b, this.mat.c, this.mat.d, this.mat.tx, this.mat.ty].join(' '), ')');

    /*
       } this.wsService.send(WS.SEND.DRAW,
          JSON.stringify({'scale': [this.mat.a, this.mat.tx, this.mat.ty]}));
    */
  }

  pinchS($event: any) {
    if (this.objet.paths.length > 0) {
      this.undo();
      const tr = new Transf(this.line.delta + this.line.start, true);
      console.log('pinch', this.line, tr);
      this.objet.transforms.push(tr);
    }
  }

  pout($event: any) {
    this.trans = false;
    this.moving = new paper.Point(0, 0);
    this.scaling = 1;
    const tr = this.objet.transforms[this.objet.transforms.length - 1];
    tr.delta = Date.now() - tr.startR;
    tr.matrix = this.mat;

    //  this.wsService.send(WS.SEND.DRAW,
    //   JSON.stringify({'scale': [1, 0, 0]}));
  }

  sel(ev): LineS {
    const e = ev.changedTouches[0];
    const rect = new paper.Rectangle({
      point: [e.clientX - 15, e.clientY - 15],
      size: [30, 30]
    });
    this.svg.gs.forEach(y => {
      y.paths.forEach(u => {
        u.line.forEach(a => {
          if (rect.contains(new paper.Point(a.x, a.y))) {
            const p = new paper.Group();
            p.importSVG('<path d="'.concat(u.d, '">'));
            paper.project.activeLayer.children[0].removeChildren();
            paper.project.activeLayer.children[0].addChild(p);

            const bo = p.bounds;
            p.removeChildren();
            const pp = new paper.Path.Rectangle(bo);
            pp.fillColor = new paper.Color('rgba(0, 0, 250, 0.05)');
            pp.strokeColor = new paper.Color('rgba(40, 0, 250, 0.3)');
            p.addChild(pp);
            p.applyMatrix = false;
            this.mover = p;
            this.stPoint = new paper.Point(e.clientX, e.clientY);
            this.newTrans = new Transf(Date.now(), false);
            this.newTrans.matrix = new paper.Matrix;
            this.captureMove(this.svgg.nativeElement);
            this.objet = y;
            return y;

          }

        });
      });


    });
    return null;

  }

  usel($event: TouchEvent) {
    paper.project.activeLayer.children[0].removeChildren();

  }

  private captureEventsS(canvasEl: HTMLDivElement): Subscription {

    // this will capture all mousedown events from the canvas element
    return fromEvent(canvasEl, 'touchstart', {passive: false})
      .pipe(
        switchMap((e) => {
          e.preventDefault();

          if (!this.trans) {
          }
          this.touch(e, canvasEl);


          return fromEvent(canvasEl, 'touchmove', {passive: false})
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'touchend')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'touchcancel')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
          res[0].preventDefault();
          res[1].preventDefault();
          const rect = canvasEl.getBoundingClientRect();

          // previous and current position with the offset
          if (res[0].changedTouches.length === 1 && !this.trans) {
            const rtn = res[0].changedTouches[0];

            // this.log = 22 + res[0].changedTouches.item(0).radiusX;
            const rtn1 = res[1].changedTouches[0];
            if (rtn1.clientX) {


              this.newDraw(rtn1.clientX - rect.left, rtn1.clientY - rect.top, rtn1.force);
              //  this.wsService.send(WS.SEND.DRAW, JSON.stringify({'draw': [rtn1.clientX - rect.left, rtn1.clientY - rect.top, rtn1.force, this.brush.color.toString(), this.brush.radius]}));

            }
            /*
            const prevPos = new paper.Point(rtn.clientX - rect.left, rtn.clientY - rect.top);


            const currentPos = new paper.Point(rtn1.clientX - rect.left, rtn1.clientY - rect.top);
*/
            //   this.testDdr(prevPos, currentPos);
            //  this method we'll implement soon to do the actual drawing
            // this.drawOnCanvas(prevPos, currentPos, rtn1.force);
          }
        }, () => {
        }, () => {
          console.log('Observer got a complete notification');
        }
        /* */
      );
  }

  private captureEventsM(canvasEl: HTMLDivElement): Subscription {

    // this will capture all mousedown events from the canvas element
    return fromEvent(canvasEl, 'mousedown', {passive: false})
      .pipe(
        switchMap((e) => {
          e.preventDefault();

          if (!this.trans) {
          }
          this.touch(e, canvasEl);


          return fromEvent(canvasEl, 'mousemove', {passive: false})
            .pipe(
              // we'll stop (and unsubscribe) once the user releases the mouse
              // this will trigger a 'mouseup' event
              takeUntil(fromEvent(canvasEl, 'mouseup')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'mouseleave')),
              // pairwise lets us get the previous value to draw a line from
              // the previous point to the current point
              pairwise()
            );
        })
      )
      .subscribe((res: [MouseEvent, MouseEvent]) => {
          const rect = canvasEl.getBoundingClientRect();

          // previous and current position with the offset
          if (res[0] && !this.trans) {
            // this.log = 22 + res[0].changedTouches.item(0).radiusX;
            const rtn1 = res[1];
            if (rtn1.clientX) {


              this.newDraw(rtn1.clientX - rect.left, rtn1.clientY - rect.top, 1);
              //  this.wsService.send(WS.SEND.DRAW, JSON.stringify({'draw': [rtn1.clientX - rect.left, rtn1.clientY - rect.top, rtn1.force, this.brush.color.toString(), this.brush.radius]}));

            }

          }
        }, () => {
        }, () => {
          console.log('Observer got a complete notification');
        }
        /* */
      );
  }

  private captureMove(canvasEl: HTMLDivElement): Subscription {

    // this will capture all mousedown events from the canvas element
    return fromEvent(canvasEl, 'touchstart', {passive: false})
      .pipe(
        switchMap((e) => {
          e.preventDefault();

          return fromEvent(canvasEl, 'touchmove', {passive: false})
            .pipe(
              takeUntil(fromEvent(canvasEl, 'touchend')),
              // we'll also stop (and unsubscribe) once the mouse leaves the canvas (mouseleave event)
              takeUntil(fromEvent(canvasEl, 'touchcancel')),

              pairwise()
            );
        })
      )
      .subscribe((res: [TouchEvent, TouchEvent]) => {
          res[0].preventDefault();
          res[1].preventDefault();

          if (res[0].changedTouches.length === 1) {
            const rtn = res[0].changedTouches[0];
            const rtn1 = res[1].changedTouches[0];
            this.newTrans.matrix.ty = rtn1.clientY - this.stPoint.y;
            this.newTrans.matrix.tx = rtn1.clientX - this.stPoint.x;
            this.mover.matrix = this.newTrans.matrix;
            this.objet.trans = 'matrix('.concat([this.newTrans.matrix.a,
              this.newTrans.matrix.b,
              this.newTrans.matrix.c,
              this.newTrans.matrix.d,
              this.newTrans.matrix.tx,
              this.newTrans.matrix.ty].join(' '), ')');


          }
        }, () => {
        }, () => {
          console.log('Observer got a complete notification');
        }
        /* */
      );
  }

  del() {
    console.log(this.sujet);
  }
}
