import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../guest/start-dialog/start-dialog.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {User} from '../user/user';
import {RestService} from '../rest/rest.service';
import {Story} from '../global-interfaces/story';


@Component({
  selector: 'app-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.css']
})
export class HelpComponent implements OnInit {
  form: FormGroup;
  loading: boolean;
  user: User;
  errors: boolean;
  private login: boolean;
  private reg: boolean;
  auth: boolean;
  formR: FormGroup;
stories: Story[];

  constructor(
    public dialogRef: MatDialogRef<HelpComponent>,
    private authService: AuthService,
              private fb: FormBuilder,private restService: RestService,
              @Inject(MAT_DIALOG_DATA) public dataD: DialogData) {
console.log(this.dataD);


    if(this.dataD.user && this.dataD.user.id){
      this.auth = true;
  this.user=new User(this.dataD.user.id, this.dataD.user.name);
this.restService.loadItem('story','filter=user,eq,'.concat(this.dataD.user.id.toString())).subscribe(y=>{
console.log(y);
 this.stories=y.map(a=>{
  let s =new Story(this.dataD.user.id);
  s.name = a.name;
  s.id=a.id;
   return s;
 });


});
    }


  }

  ngOnInit(): void {
   // this.user = this.dataD.auth;
  }

  loginV() {
    this.login = true;
    this.reg = false;
    this.form = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email]
      ],
      password: [
        '',
        Validators.required
      ]
    });
  }
swi(t): void{

    this.dataD.lis=t;
}
  regV() {
    this.reg = true;
    this.login = false;

    this.formR = this.fb.group({
      first_name: ['', Validators.required],

      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      password_confirmation: ['', Validators.required]
    });

  }

  loginFu(): void {

    this.loading = true;
    this.errors = false;
    this.authService.login(this.controls.email.value, this.controls.password.value)
      .subscribe((res: any) => {
        // Store the access token in the localstorage
        localStorage.setItem('access_token', res.access_token);
      //  localStorage.setItem('user_name', res.access_token);
        this.loading = false;
     this.auth=true;
      }, (err: any) => {
        // This error can be internal or invalid credentials
        // You need to customize this based on the error.status code
        this.loading = false;
        this.errors = true;
      });


  }
  get controls() {
    return this.form.controls;
  }

  regFu() {
    const formData = this.formR.getRawValue();
  this.authService.register(formData).subscribe(

  a=>{

   this.auth = true;

  }
);
  }

  clo(nu: number): void {
  this.dialogRef.close({nu:nu});
  }

  logout() {
    this.authService.logout().subscribe(
      u=>{
if(u==='DONE'){

  localStorage.removeItem('auth_token');
  this.dialogRef.close({nu:0});
}


      }

    );
  }

  addStory() {
    console.log(this.user)
  }
}
