import {Injectable} from '@angular/core';
import * as paper from 'paper';
import {LineS} from '../paper/lineS';
import {HttpClient, HttpResponse} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class SharpService {

  constructor(private http: HttpClient,) {


  }

  df(im) {
    const pap = new paper.Group();
    const rectangle = new paper.Rectangle(new paper.Point(20, 20), new paper.Size(60, 60));
    const path = new paper.Path.Rectangle(rectangle);
    path.strokeColor = new paper.Color('black');
    pap.addChild(path);
//  const imm= path.rasterize(72,false);

  }


  publish(svg: LineS[], it: paper.Item, name: string, id: number, x, y) {
    let st;
    const ar = [];
    let scale = 1;
    let cy = 0;
    if (y > x && y > 512) {
      scale = 512 / y;
      cy = (512 - x * scale) / 2;

    }

    it.addChild(new paper.Path.Rectangle({
      point: [0, 0],
      size: [512, 512],
      fillColor: 'white'
    }));
    /**/

    svg.forEach(y => {
      y.paths.forEach((s, i) => {
        if (i === 0) {
          st = s.start;
        }
        console.log(s);
        if (s.d) {
let g = '<g transform="scale(' + scale.toString() + ') translate(' + cy.toString() + ' 0)">' +
  '<path opacity="' + s.alpha + '" fill="' + s.color + '" d="' + s.d + '"/></g>';
console.log(g);
  it.importSVG(g,);
          /* gr.shadowColor =  new paper.Color(255, 255, 255);

             // Set the shadow blur radius to 12:
             gr.shadowBlur = 12;
             // Offset the shadow by { x: 5, y: 5 }
             gr.shadowOffset= new paper.Point(0, 0);
           */

          if (s.start > st + 10) {
            st = s.start;
            ar.push(it.rasterize({resolution: 72, insert: false}).toDataURL());

          }

        }
      });


    });
    const filename = 'MyStory_' + id + '.webp';

    this.http.post('https://walls.memigration.info/webP',
      {ims: ar, x: 512, y: 512, name: name, id: id},
      {
        observe: 'response',
        responseType: 'blob'
      }).subscribe(
      (response: HttpResponse<Blob>) => {


        let binaryData = [];
        binaryData.push(response.body);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: 'image/webp'}));
        if (filename) {
          downloadLink.setAttribute('download', filename);
          document.body.appendChild(downloadLink);
          downloadLink.click();
        }

      }
    );


  }
}
