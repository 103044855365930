import {Injectable} from '@angular/core';
import * as paper from 'paper';
import {Vector} from '../global-interfaces/vector';
import {Gost} from '../global-interfaces/gost';
import {ActivatedRoute} from '@angular/router';
import {Orient} from '../global-interfaces/orient';
import {Brush} from '../global-interfaces/brush';
import {Quat} from '../global-interfaces/quat';
import {PathS} from './pathS';
import {getStroke} from 'perfect-freehand';

// import {PaperComponent} from './paper.component';


@Injectable({
  providedIn: 'root'
})
export class PaperService {
  public brush: Brush;
  fi: string;
  public beta: number;
  public hei: number;
  public recording: boolean;
  public maskT: paper.Item;
  public tm: number;
  public brushes: Brush[];
  private guests: paper.Group;
  private oldp: paper.Point;
  private tete: any;
  private zero: paper.Point;
  private botC: paper.Point;
  private real: paper.Item;
  private otr: paper.Item;
  private worker: any;

  constructor(private route: ActivatedRoute) {
    this.fi = 'filter';
    this.zero = new paper.Point(0, 0);
    this.beta = 30;
    this.hei = 10;
    /*  if(route.snapshot.url[1].path === 'guest') {

      }else{this.fi = '';}

     */


    if (typeof Worker !== 'undefined') {
      // Create a new
      /*
            this.worker = new Worker('./paper.worker', {type: 'module'});

            this.worker.onmessage = ({data}) => {
              // console.log({data});
            };
      */
    }


  }/**/
  initialize(): void {
    this.real = paper.project.activeLayer.children[1];
    this.otr = paper.project.activeLayer.children[0];
    this.maskT = paper.project.activeLayer.children[2];

  }

  public newDraw(x: number, y: number, pressure: number, p: PathS, brush: Brush, points: any, mat: paper.Matrix): void {

    const size = brush.radius;
    // const p1 = ppo.transform(new paper.Matrix(1/this.mat.a,0,0,1/this.mat.d,-this.mat.tx,-this.mat.ty));
    const p1 = new paper.Point(x / mat.a - mat.tx / mat.a, y / mat.a - mat.ty / mat.a);


    //  brush.color.red, brush.color.green, brush.color.blue  , brush.color.alpha, brush.radius
    points.push({x: p1.x, y: p1.y, pressure: pressure});
    const opts = {
      size: size,
      start: {
        taper: 1,
        easing: (t) => t,
        cap: false
      },
      end: {
        taper: 0,
        easing: (t) => t,
        cap: true
      }
    };

    const ar = getStroke(points, opts);
    /*


    //  thinning: 0.5,
      streamline: 0.5,
     // smoothing: 0.5,
      easing: (t) => t,
      start: {
        taper: 0,
        easing: (t) => t,
        cap: true
      },
      end: {
        taper: 100,
        easing: (t) => t,
        cap: true
      }




    const p = this.gs.paths[this.gs.paths.length - 1];   */
    //  p.alpha = brush.color.alpha;
   // p.color = 'RGB(' + brush.color.red + ',' + brush.color.green + ',' + brush.color.blue + ')';
    p.d = this.getSvgPathFromStroke(ar);


  }

  public getSvgPathFromStroke(stroke: any[]): string {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }

  public setRecording(a): void {
    this.recording = a;
  }

  // tslint:disable-next-line:typedef
  public hslToRGB(h, s, l) {

    let r;
    let g;
    let b;
    if (s === 0) {
      r = g = b = l; // achromatic
    } else {
      const hue2rgb = (p, q, t) => {
        if (t < 0) {
          t += 1;
        }
        if (t > 1) {
          t -= 1;
        }
        if (t < 1 / 6) {
          return p + (q - p) * 6 * t;
        }
        if (t < 1 / 2) {
          return q;
        }
        if (t < 2 / 3) {
          return p + (q - p) * (2 / 3 - t) * 6;
        }
        return p;
      };
      const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
      const p = 2 * l - q;
      r = hue2rgb(p, q, h + 1 / 3);
      g = hue2rgb(p, q, h);
      b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = x => {
      const hex = Math.round(x * 255).toString(16);
      return hex.length === 1 ? '0' + hex : hex;
    };
    const toHe = x => {
      return String(Math.round(x * 255));

    };

    return new paper.Color(r, g, b);
  }

  setBrush(brush: Brush) {
    this.brush = brush;

  }

  // tslint:disable-next-line:typedef
  addPath(sz, user, gost) {
    this.botC = paper.project.view.bounds.bottomCenter.subtract(new paper.Point(0, 12));
    const szz = sz.split(',');
    const w = Number(szz[0]);
    const h = Number(szz[1]);
    const ttg = new paper.Group();
    this.real.addChild(ttg);


    ttg.data = user;

    if (user.color > 0) {

      ttg.data.color = this.hslToRGB(user.color, 0.1, 0.3);
    } else {
      ttg.data.color = new paper.Color(23, 155, 166, 1);
    }
    //
    const tt = new paper.Path([new paper.Point(0, 0), new paper.Point(0, 1)]);
    // const ttrR = new paper.Path.Rectangle(new paper.Rectangle(-w / 8, -h / 8, w / 8, h / 8));
    const ttr = new paper.Path();

    // ttrR.translate();

    ttr.applyMatrix = false;
    ttg.applyMatrix = false;
    // ttrR.applyMatrix = false;
    // ttrR.strokeColor = new paper.Color('white');
    ttg.addChild(tt);
    ttg.addChild(ttr);
    ttg.addChild(new paper.Path());
    // ttg.addChild(ttrR);
    /*  const otrp = new paper.Path();
      ttg.data.otr = otrp;
      this.otr.addChild(otrp);
      */

    gost.dummy = new paper.Path();
    ttg.addChild(gost.dummy);
    gost.dummy.visible = false;
    gost.dummy.data.hue = gost.hue;
    gost.dummy.data.color = gost.pColor;

    return ttg;
  }

  // tslint:disable-next-line:typedef
  removePath(index: number) {


  }

  public ddr(popo: paper.Point, frc0, lcol, tete, caser) {
    if (tete.length > 0) {
      const ppathD = new paper.Path();
      if (caser === 'stop') {

        const oldpr = tete[tete.length - 1][1];
        const lenn = popo.subtract(oldpr);
        const oldir = tete[tete.length - 2][3];
        const lel: paper.Point = lenn.normalize(1).rotate(90, this.zero);
        // document.getElementById("tex").innerHTML=lel.dot(oldir);
        const len = lenn.length;
        const frc = tete[tete.length - 2][0];

        const sold = oldpr.add(lel.multiply(frc * 0.5));
        // var ddr=popo.subtract(oldp).normalize(frc/2).rotate(90);

        ppathD.segments = [oldpr.add(lel.multiply(frc * 0.5)), new paper.Segment(oldpr.subtract(lel.multiply(frc * 0.5)), null, tete[tete.length - 2][3].multiply(len * 0.5)), popo, new paper.Segment(sold, tete[tete.length - 2][3].multiply(len * 0.5))],
          ppathD.fillColor = lcol;

      } else {

        const oldp = tete[tete.length - 1][2];
        const dir = popo.subtract(oldp).normalize(1);

        /*
                var ppath1 = new paper.Path({
        segments: [oldp,popo],
        //  fillColor:new paper.Color(lcol),
        strokeWidth:t.force*6, strokeCap: 'round',
    strokeColor:'blue',
        // Select the path, so we can see its segment points:

    });
    */

        const curpa = tete[tete.length - 1];
        const len = curpa[1].subtract(curpa[2]).length;
        const middir = curpa[3].add(dir).multiply(-len / 3);
        //  var middir = dir.multiply(-len / 2);
        // curp.insert(0,curpa[1].add(middir));


        // segments: [curpa[1],curpa[2].subtract(middir)],
        ppathD.segments = [new paper.Segment(curpa[2], null, middir), curpa[1]];
        // fillColor:new paper.Color(lcol),
        if (this.brush.radius < 10) {
          ppathD.strokeWidth = curpa[0] * this.brush.radius;
        } else {
          ppathD.strokeWidth = this.brush.radius;
        }
        ppathD.strokeCap = 'round';
        ppathD.strokeColor = new paper.Color(this.brush.color.red / 256, this.brush.color.green / 256,
          this.brush.color.blue / 256);

        // ppathD.strokeColor='#dff000';
//console.log( ppathD.strokeColor);
        // Select the path, so we can see its segment points: fullyselected: true


        //  if(tete.length % 2 ===1)   ppathD.strokeColor= new paper.Color('#eee'); else ppathD.strokeColor=new paper.Color('#ff0000');
        /*var ppathD = new paper.Path({
        segments: [curpa[2].add(3),curpa[1].add(3)],
    //  segments: [new paper.Segment(curpa[2],middir),curpa[1]],
    //  fillColor:new paper.Color(lcol),
    strokeWidth:1, strokeCap: 'round',

    // Select the path, so we can see its segment points:

});
*/

        // var frc = t.force * 6;


        // curp=ppath1;
        // dir=popo.subtract(oldp).normalize(1);
        // oldp = popo;
        tete.push([frc0, oldp, popo, dir]);
      }
      return ppathD;
    } else {

    }
  }

  /**/

  public seg(po: paper.Point, po1: paper.Point, po2: paper.Point): paper.Segment {
    const center = paper.project.view.center;
    if (po1 instanceof paper.Point) {
      return new paper.Segment(po.add(center), po1.subtract(po).multiply(-0.6), po1.subtract(po).multiply(0.6));
    } else if (po2 instanceof paper.Point) {
      return new paper.Segment(po2.add(center), po2.subtract(po).multiply(-0.6), null);
    } else {
      return new paper.Segment(po.add(center));
    }
  }

  public mk_rnd(sgs: paper.Segment[], radius: number): any[] {
    const segs = [];
    for (const i in sgs) {
      segs.push(sgs[i]);
    }
    return segs;
  }

  public pixelate(paths: PathS[]) {
    const temp = new paper.Group();
    //  temp.visible=false;
    paths.forEach(a => {

      const svg = '<path fill="' + a.color + '" d="' + a.d + '"/>';

      const ree = temp.importSVG(svg);
      temp.addChild(ree);
    });
    // this.real.addChild(temp);

    // temp.position.x+=30;
    const mr = temp.rasterize({resolution: 25, insert: false});
    temp.remove();
    return mr;


  }

  public trans(guest: Gost, eu: any, tem: Quat) {
    const vector = tem.vector;
    const vectorX = tem.rotateX(0, 0, Math.PI * 0.5);
    const vectorZ = tem.rotateY(0, 0, Math.PI * 0.5);
    // line.position = new paper.Point(w / 2, h / 2);
    const line = guest.line;

    const bbot = paper.project.view.bounds.height * 0.75;


    if (line && eu.motion && guest.locked === 0) {

      // this.worker.postMessage({ln:line.exportJSON(),sk:eu,gost:'t'});
      const nacl = new paper.Point(vector.z, vector.x).angle;

      const ta = new paper.Point(vector.z, vector.y).normalize(1);
      const dummy = line.children[0] as paper.Path;
      dummy.visible = false;
      const otr = line.data.otr as paper.Path;
      const phon = line.children[1] as paper.Path;
      const ln = 3000 * eu.scale;

      const pos = this.project(new Vector(guest.posX + eu.x + eu.motion.x * 0 - vector.x * ln, guest.posY + eu.y + eu.motion.y * 0 + vector.z * ln, 0),
        new Vector(12, 1, 0), 700);

      const eux = pos.x;
      // console.log(eu.motion);
      const euy = pos.y;
      const scale = eu.scale * pos.z;


// console.log(bot,paper.project.view.bounds.height);
//

      const cos = Math.cos((paper.project.activeLayer.data.beta - this.hei) / 180 * Math.PI);
      const h = guest.h / 2 * scale * cos;
      const w = guest.w / 2 * scale;
      const w1 = (guest.w / 2 - guest.w / 12) * scale;
      const h1 = (guest.h / 2 - guest.w / 12) * scale * cos;

      dummy.segments = this.mk_rnd([
        this.seg(new paper.Point(eux + vectorZ.x * w1 - vectorX.x * h, euy - vectorZ.z * w1 + vectorX.z * h),
          new paper.Point(eux + vectorZ.x * w - vectorX.x * h, euy - vectorZ.z * w + vectorX.z * h), null),


        this.seg(new paper.Point(eux + vectorZ.x * w - vectorX.x * h, euy - vectorZ.z * w + vectorX.z * h),
          null,
          new paper.Point(eux + vectorZ.x * w - vectorX.x * h1, euy - vectorZ.z * w + vectorX.z * h1)),


        // this.seg(eux + vectorZ.x * w - vectorX.x * h1, euy - vectorZ.z * w  + vectorX.z * h1, vector.y * ln, null, null),
        this.seg(new paper.Point(eux + vectorZ.x * w + vectorX.x * h1, euy - vectorZ.z * w - vectorX.z * h1),
          new paper.Point(eux + vectorZ.x * w + vectorX.x * h, euy - vectorZ.z * w - vectorX.z * h), null),
        /* */
        this.seg(new paper.Point(eux + vectorZ.x * w + vectorX.x * h, euy - vectorZ.z * w - vectorX.z * h)
          , null, new paper.Point(eux + vectorZ.x * w1 + vectorX.x * h, euy - vectorZ.z * w1 - vectorX.z * h)),


        this.seg(new paper.Point(eux - vectorZ.x * w1 + vectorX.x * h, euy + vectorZ.z * w1 - vectorX.z * h),
          new paper.Point(eux - vectorZ.x * w + vectorX.x * h, euy + vectorZ.z * w - vectorX.z * h), null),

        this.seg(new paper.Point(eux - vectorZ.x * w + vectorX.x * h, euy + vectorZ.z * w - vectorX.z * h), null,
          new paper.Point(eux - vectorZ.x * w + vectorX.x * h1, euy + vectorZ.z * w - vectorX.z * h1)),

        this.seg(new paper.Point(eux - vectorZ.x * w - vectorX.x * h1, euy + vectorZ.z * w + vectorX.z * h1),
          new paper.Point(eux - vectorZ.x * w - vectorX.x * h, euy + vectorZ.z * w + vectorX.z * h), null),

        this.seg(new paper.Point(eux - vectorZ.x * w - vectorX.x * h, euy + vectorZ.z * w + vectorX.z * h),
          null, new paper.Point(eux - vectorZ.x * w1 - vectorX.x * h, euy + vectorZ.z * w1 + vectorX.z * h)),

        ///////
        //
        // this.seg( new paper.Point(eux - vectorZ.x * w1 - vectorX.x * h, euy + vectorZ.z * w1  + vectorX.z * h), null, null),
        // this.seg(eux + vectorZ.x * w - vectorX.x * h, euy - vectorZ.z * w  + vectorX.z * h, vector.y * ln, null, null),
        //  this.seg(eux - vectorZ.x * w - vectorX.x * h, euy + vectorZ.z * w  + vectorX.z * h , vector.y * ln, null, null),
        //  this.seg(eux + vectorX.x * h - vectorZ.x * w, euy + vectorZ.z * w  - vectorX.z * h, vector.y * ln, null, null),
        //  this.seg(eux + vectorX.x * h + vectorZ.x * w, euy - vectorZ.z * w  - vectorX.z * h , vector.y * ln, null, null),
        this.seg(new paper.Point(eux + vectorZ.x * w1 - vectorX.x * h, euy - vectorZ.z * w1 + vectorX.z * h), null, null)


      ], 12);

      const th = 15;
      const bot = dummy.bounds.bottom;


      /***
       * reflection
       *
       *
       *
       *
       */
      if (bot < bbot) {
        const delty = bbot - euy - paper.project.view.bounds.height / 2 + dummy.bounds.height / 2;

        phon.segments = dummy.segments;
        const co = guest.dummy.data.color;

        phon.fillColor = co;
        if (otr) {

          otr.segments = dummy.segments;
          otr.scale(1, -1);
          otr.translate(new paper.Point(0, delty));
          const bounds = otr.bounds;
          //  const gradient = new paper.Gradient([[new paper.Color(co.red, co.green, co.blue, 1), 0], [new paper.Color(31 / 256, 31 / 256, 32 / 256, 1), 1]]);

          //  const gradientColor = new paper.Color(gradient, new paper.Point(0, bot), new paper.Point(0, bounds.bottom));
          //  otr.fillColor = gradientColor;

        }


        // @ts-ignore
        //  const gradient = new paper.Gradient([[new paper.Color(co.red, co.green, co.blue, 0.6), 0], [new paper.Color(co.red, co.green, co.blue, 0.2), 0.5], [new paper.Color(co.red, co.green, co.blue, 0), 1]]);


// Set the fill color of the path to the gradient color:
      }
      /*      const len = dummy.length;
          const tan = new paper.Point(vectorX.x, vectorX.z).normalize(1);
          const tan1 = new paper.Point(vectorZ.x, vectorZ.z).normalize(1);

          const cen = paper.project.view.center;
    // guest.line.children[3].matrix = (new paper.Matrix(1,-tan1.y,tan.x,-ta.y,x,cen.y));
          /*   const ar =[];
             var ca=0;
             for(let i = 0 ; i<line0.curves.length; i++){
                 var cu=line0.curves[i];
                 var ar = cu.getTimesWithTangent(tan);
              if(ar.length === 1)  {
                  var cucu = cu.splitAtTime(ar[0]);
                 if(cucu) {
                     cucu.translate(ta);
                    // cucu.insert(0,new paper.Segment)
                     //  cucu.segment1.point = cucu.segment1.point.add(ta);
                     //  cucu.segment2.point = cucu.segment2.point.add(ta);
                     ca = 1;
               }
             }
             }
             /*
             for(let i =0 ; i<900; i++){
                 var offset = i / 900 * len;
     var point = line0.getPointAt(offset);
     var tangent = line0.getTangentAt(offset).dot(tan);
     ar.push([tangent, offset]);

             }


     ar.sort((a, b) => a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0);

             const point = line0.getPointAt( ar[0][1]);
             const point1 = line0.getPointAt( ar[ar.length-1][1]);

    line0.fullySelected= true;
    s
      */
      // line.shadowColor= new paper.Color('#1f1f21');// line.data.color;
      //  line.shadowBlur=12;
      // line.opacity = 0.6;
      //  line.children[2].position = line.children[2].position.add(ta.multiply(th));
      // line.children[1].segments = [new paper.Segment(point1.add(ta.multiply(th))), new paper.Segment(point1),new paper.Segment(point), new paper.Segment(point.add(ta.multiply(th)))];


      // line.children[1].position = paper.project.view.center;
      const an = 0;
      //   line.children[1].matrix = new paper.Matrix(Math.cos(an) * Math.cos(Math.asin(vector.x)), Math.sin(an) * vector.y, -Math.sin(an), Math.cos(an) * Math.cos(Math.asin(vector.z)), eux - vector.x * 200 + w / 2, euy + h / 2 + vector.z * 200);

      // paper.view.update();
// line.remove();
      //
      const movx = eu.motion.x * 300;
// console.log(this.botC);
      //  line.children[3].segments = [new paper.Segment(this.botC), new paper.Segment(this.botC.add(new paper.Point(movx, 0)))];
      // line.children[3].strokeWidth = 6;
      //  line.children[3].strokeColor = new paper.Color('red');
      // line.removeSegments();
      // console.log(line.segments[0]);
      //
      // line.addSegments([new paper.Segment(new paper.Point(eux, euy)), new paper.Segment(new paper.Point(, )]);
      //
    }
  }

  getF(a): string {
    if (a === 1) {
      this.fi = 'done';

    }
    return this.fi;

  }

  updateAngle(o: Orient) {
    this.beta = o.beta;


  }

  getBrush(): Brush {
    return this.brush;
  }

  private project(v: Vector, view: Vector, l: number): Vector {
    const pp = new paper.Point(v.y, v.z).rotate(paper.project.activeLayer.data.beta - this.hei, this.zero.add(new paper.Point(0, 0)));

    const dis = pp.length + l;
    const x = v.x * l / dis;
    const y = pp.y * l / dis;


    return new Vector(x, y, l / dis);
  }
}
