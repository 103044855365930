import {Component, Input, OnInit, Self, SkipSelf} from '@angular/core';
import {User} from '../user/user';
import {ActivatedRoute, Router} from '@angular/router';
import {WebsocketService} from '../websocket';
import {BROWSER_STORAGE, BrowserStorageService} from '../storage/storage.service';
import {RestService} from '../rest/rest.service';

import {PaperService} from '../paper/paper.service';
import {HttpClient} from '@angular/common/http';
import * as paper from 'paper';
import {WS} from '../websocket.events';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

import {StartDialogComponent} from '../guest/start-dialog/start-dialog.component';
import {ColorComponent} from '../color/color.component';
import {Brush} from '../global-interfaces/brush';
import {Subject} from 'rxjs';
import {PathS} from '../paper/pathS';
import {LineS} from '../paper/lineS';
import {PlayerComponent} from '../player/player.component';

class MenuG {
  id: number;
  image?: string;
  svg?: string;
  type: string;
  active: string;

  constructor(id, type, image, svg) {
    this.id = id;
    this.image = image;
    this.svg= svg;
    this.type= type;
  }
}

/*
}
*/

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.css'],
  providers: [RestService, HttpClient, {provide: Window, useValue: window},
    BrowserStorageService,
    {provide: BROWSER_STORAGE, useFactory: () => sessionStorage}
  ]
})

export class DrawerComponent implements OnInit {
  @Input() user: User;
  @Input() user_db: number;
  @Input() location_db: number;
  @Input() story: number;
  private openDialog: MatDialogRef<ColorComponent, any>;
  fading_items: paper.Group[];
  done_objets: MenuG[];
  public colorOpened: Boolean;
  colors: paper.Color[];
  brushes: Brush[];
  activeBrush: Brush;
  public recording: boolean;
  recordingObs: Subject<boolean>;
  selectedG: Subject<number>;
  newG: Subject<number>;
  undo: Subject<number>;
  drawing: boolean;
  curTime:number;
test:number;
  bg_objets: MenuG[];
  fading_objets: MenuG[];
  h: number;


  constructor(private router: Router, private route: ActivatedRoute, private wsService: WebsocketService,
              @Self() private sessionStorageService: BrowserStorageService,
              @SkipSelf() private localStorageService: BrowserStorageService,
              private restService: RestService, private paperService: PaperService, public menuDialog: MatDialog) {
this.recordingObs = new Subject<boolean>();
this.selectedG = new Subject<number>();
    this.undo= new Subject<number>();
    this.newG= new Subject<number>();
this.drawing=false;
this.bg_objets=[new MenuG(0,'bg','','')];
this.fading_objets=[];
  }

  openColorDialog() {
    if (!this.colorOpened) {
      const dialogRef = this.menuDialog.open(ColorComponent, {data: {brushes: this.brushes, brush: this.activeBrush}});
      this.colorOpened = true;
      dialogRef.afterClosed().subscribe(result => {
        result.forEach((a, i) => {

          this.brushes[i].color = new paper.Color(a.rgba.r, a.rgba.g, a.rgba.b, a.rgba.a);


        });
        this.colorOpened = false;
      });
    } else {
      this.menuDialog.closeAll();

    }

  }

  ngOnInit() {
    this.h = window.innerHeight;
    this.test=0;
    this.curTime=1000;
    this.recording = false;
    console.log(this.user);
    this.user.id=this.user_db;
this.recordingObs.next(this.recording);
    this.colors = [new paper.Color(77, 19, 80, 0.1),
      new paper.Color(204, 19, 80, 0.1),
      new paper.Color(102, 116, 95, 0.1),
      new paper.Color(26, 9, 0, 0.9)
    ];
    this.brushes = [new Brush(
      20, this.colors[0],0),
      new Brush(20, this.colors[1], 1),
      new Brush(20, this.colors[2], 2),
      new Brush(2, this.colors[3], 3)
    ];
    this.brushes[3].active = true;
    this.activeBrush = this.brushes[3];
    this.paperService.setBrush(this.activeBrush);

    this.colorOpened = false;
    this.fading_items = [];
    this.paperService.getF(1);
    if (this.story === undefined) {
   //   this.restService.addItem('story', {user: this.user_db, location: this.location_db}).subscribe(loc => {

    //    this.story = loc;
    //  });
    } else {
      //  console.log('ddd',this.story);
    }
    this.done_objets = [];

  }


  ngAfterViewInit() {
    console.log('s');
    // const streamer = new WSAudioAPI.Player();
  }


  pressup(dav: LineS) {
    if(dav instanceof LineS){
      const da = this.paperService.pixelate(dav.paths);

      this.test++;
    this.done_objets.push(new MenuG(dav.id,'front', da.toDataURL(), dav.paths));
    this.wsService.send(WS.SEND.DRAW, JSON.stringify({end_o: 1}));
    }

  }

  app(n: number) {
  }



  getBg(brush: Brush) {
    const col = brush.color.lightness / brush.color.alpha;
    if (col > 50) {
      return 'rgba('.concat(String(brush.color.red), ',', String(brush.color.green), ',', String(brush.color.blue), ',', String(brush.color.alpha), ')');
    } else {
      return 'none';
    }
  }

  getCol(brush: Brush) {
    const col = brush.color.lightness / brush.color.alpha;

    if (col <= 50) {
      return 'rgba('.concat(String(brush.color.red), ',', String(brush.color.green), ',', String(brush.color.blue), ',', String(brush.color.alpha), ')');
    } else {
      return '#444444';
    }
  }

  select(ev, brush: Brush) {

    this.activeBrush.active = false;
    this.activeBrush = brush;
    this.activeBrush.active = true;
    this.paperService.setBrush(brush);
  }

  getAct(brush: Brush) {
    if (brush.active) {
      return 'act';
    } else {
      return '';
    }


  }

  ifRecord(c) {
    if (c === 0) {

      if (this.recording) {
        return 'radio-button-on';
      } else {
        return 'stop-circle';
      }
    } else {
      if (this.recording) {
        return 'red';
      } else {
        return '#333';
      }
    }
  }

  record() {
    if (this.recording) {
      this.recording = false;
    } else {
      this.recording = true;
    }
    this.recordingObs.next(this.recording);
  }

  stDraw($event: any) {
  if($event==1) {this.drawing=true;}else{

    const dialogRef = this.menuDialog.open(PlayerComponent, {backdropClass:'frf',data: {sujet: $event}});

    dialogRef.afterClosed().subscribe(result => {
      result.forEach((a, i) => {



      });

    });


  }
  }

  sel(id: MenuG) {
    console.log(id);

  }

  drop(event: CdkDragDrop<MenuG[]>) {

    if (event.previousContainer === event.container) {

     // this.selectedG.next(event.item.data.id);
      if( event.currentIndex === event.previousContainer.data.length-1){
        transferArrayItem(
          event.container.data,
          this.fading_objets,
          event.previousIndex,
          0,
        );

      }else{

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
      }
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }




  }

  back(number: number) {
    this.undo.next(number);
  }

  getBgG(bgb: MenuG) {
    if(bgb.image!=''){

      return 'url('+bgb.image+')';
    }

  }

  activateBG(a:MenuG) {
    if(a.active=='act'){a.active='';}else{

      a.active='act';
    }

  }

  next(number: number) {
    this.newG.next(1);
  }

  dropF(event: CdkDragDrop<MenuG[], any>) {
    if( event.currentIndex === 0){
      transferArrayItem(
        event.container.data,
        this.done_objets,
        event.previousIndex,
        0,
      );

    }else{

      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    }

  }


}
