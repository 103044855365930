
<div class="grid-container">
  <h1 class="mat-h1">Story</h1>




<ng-container *ngIf="story">


  <mat-grid-list cols="2" rows="2" rowHeight="40vh">
    <mat-grid-tile [colspan]="2" [rowspan]="2">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            <app-input [id]="story.id" [field]="'name'"  [type]="'story'"  [val]="story.name" [placeh]="'Story name'" [size]="36" ></app-input>


          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">

          <div>
            <form [formGroup]="form" (submit)="onSubmit()">
              <mat-form-field appearance="fill">
                <mat-placeholder>Description</mat-placeholder>
                <textarea placeholder="Description" matInput formControlName="description" [value]="story.description">{{story.description}}</textarea>
              </mat-form-field>
              <br>
              <mat-divider></mat-divider>
              <mat-form-field appearance="fill">
                <mat-label>Access</mat-label>
                <mat-select formControlName="access">

                  <mat-option  *ngFor="let a of access" [value]="a" [selected]="(a === story.access || (a=='private' && !story.access)) ? 'selected' : ''">{{a}}
                  </mat-option>


                </mat-select>
              </mat-form-field>
              <br>


              <br>
              <button [disabled]="!form.valid" mat-raised-button>Save</button>
            </form>

          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
    <mat-grid-tile *ngFor="let su of story.sujet; let i = index;" [colspan]="cards | async" [rowspan]="2">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            <app-input  [field]="'name'" [id]="su.id" [fields]="{'user':user.id, 'size':'no','story':story.id,'image':su.image}" [type]="'sujet'"  [val]="su.name" [placeh]="'name'" [size]="18" ></app-input>


            <button mat-icon-button class="more-button" [matMenuTriggerFor]="menu" aria-label="Toggle menu">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu" xPosition="before">
              <button *ngIf="su.objet.length>0" (click)="router.navigateByUrl('/'.concat(su.id.toString()))" mat-menu-item>Replay</button>
              <button mat-menu-item *ngIf="user.id === su.user" (click)="deleteSu(su)">Remove</button>

            </mat-menu>
          </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">

          <div>
            <app-svg-part *ngIf="su.objet.length>0" [objets]="su.objet" [size]="su.size"  ></app-svg-part>
            <img *ngIf="su.image" [src]="su.image">

          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>

    <mat-grid-tile [colspan]="cards | async" [rowspan]="2">
      <mat-card class="dashboard-card">
        <mat-card-header>
          <mat-card-title>
            <h3>New image</h3>
   </mat-card-title>
        </mat-card-header>
        <mat-card-content class="dashboard-card-content">

          <div>  <app-file-upload *ngIf="user" (save)="saved($event)" [userId]="user.id"></app-file-upload>
          </div>
        </mat-card-content>
      </mat-card>
    </mat-grid-tile>
  </mat-grid-list>




</ng-container>
</div>
