import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {DialogData} from '../guest/start-dialog/start-dialog.component';

@Component({
  selector: 'app-player-popup',
  templateUrl: './player-popup.component.html',
  styleUrls: ['./player-popup.component.css']
})
export class PlayerPopupComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public dataDd: DialogData) { }

  ngOnInit(): void {
  }

}
