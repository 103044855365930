import {Component, OnInit, SkipSelf} from '@angular/core';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {MyHammerConfig} from '../app.component';
import {RestService} from '../rest/rest.service';
import {ActivatedRoute} from '@angular/router';
import {Story} from '../global-interfaces/story';
import {BrowserStorageService} from '../storage/storage.service';
import {Sujet} from '../paper/sujet';
import {Objet} from '../paper/objet';
import paper from 'paper';
import {PaperService} from '../paper/paper.service';
import {ObjetItem} from '../global-interfaces/objetItem';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {StartDialogComponent} from '../guest/start-dialog/start-dialog.component';
import {Subject} from 'rxjs';
import {PlayerPopupComponent} from '../player-popup/player-popup.component';


/*
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
*/

///import * as Speech from '@google-cloud/speech';


@Component({
  selector: 'app-content-controller',
  templateUrl: './content-controller.component.html',
  styleUrls: ['./content-controller.component.css'],
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }]
})
export class ContentControllerComponent implements OnInit {
  hi: any;
  story: Story;
  location: Location;
  public userId: string;
  public stories: Story[];
  playing: boolean;
  currentSujet: Sujet;
  currentObjet: Objet;
  currentObjetNum: number;
  currentSujetNum: number;
  sujets: Sujet[];
  objets: Objet[];
  obItems: ObjetItem[];
  begin: number;
  end: number;
  stTime: number;
  trval: any;
  curTime=0;
  private storyStart: number;
  private storyEnd: number;
  private allTime: number;
  private firstTime: number;
  private cont: Subject<any>;
  public user: any;


  constructor(public menuDialog: MatDialog, private paperService: PaperService, @SkipSelf() private localStorageService: BrowserStorageService, private restService: RestService, private route: ActivatedRoute) {
    this.hi = window.innerHeight;
  }

  ngOnInit() {

    this.cont=new Subject<number>();
    this.cont.subscribe(u=>{


    });
    this.obItems=[];
    this.paperService.getF(1);
    this.currentObjetNum = 0;
    this.firstTime = 0;
    this.currentSujetNum = 0;
    this.stories = [];
    this.objets = [];
    this.playing = false;

    this.restService.loadlocation(this.route.snapshot.params.location).subscribe(ab => {
      this.location = ab as Location;

      ab.story.forEach(a => {

        if (this.route.snapshot.params.story && this.route.snapshot.params.story === a.id) {
          this.story = a;
this.user=a.user;

          this.sujets = a.sujet.map(af => {

            if (af.objet.length > 0) {

              af.objet.forEach(b => {
                this.objets.push(b);

              });
              /*  this.restService.delItem(af.id,'sujet'); this.restService.delItem(b.id, 'objet');
              */
return af;
            } else {
              this.restService.delItem(af.id, 'sujet');
            }
          });



          this.currentObjet = this.sujets[0].objet[0];

         // this.storyStart = Date.parse(this.currentObjet.strokes[0].start);
         // this.storyEnd = Date.parse(this.sujets[this.sujets.length - 1].objet.slice(-1)[0].start);
         // this.allTime = this.storyEnd - this.storyStart;
        }
        if (a.sujet.length > 0 ) {

          /*
          const beg = Date.parse(a.sujet[0].objet[0].start);
        */
          let del = 0;
          a.sujet.forEach(d => {
            if (d.objet.length > 0) {
              d.objet.forEach(y=>{
                if(y.strokes.length==0){
                  this.restService.delItem(y.id,'objet');


                }


              });
              //  del = Date.parse(d.objet[d.objet.length - 1].start) ;
            }else{
              this.restService.delItem(d.id,'sujet');
            }

          });

          //  a.time = new Date(Date.parse(a.sujet[a.sujet.length-1].objet.pop().start) -  Date.parse(a.sujet[0].objet[0].start));


        } else {
          //  a.sujet.forEach(b=>{this.restService.delItem(b.id,'sujet');});

this.restService.delItem(a.id,'story');
        }

      });


    });
    this.userId = this.localStorageService.get('user');
    /*  const speech = require('@google-cloud/speech');
      const client = new Speech.SpeechClient();
  console.log(client);

    */
  }

  a(ev: any) {
    alert(ev);

  }



  filter(story: Story[]) {
    return story.filter(a => {

      if (a.sujet.length > 0 && a.sujet[0].objet.length > 0) {
        const beg = Date.parse(a.sujet[0].objet[0].start);
        let del = 0;
        a.sujet.forEach(d => {
          if (d.objet.length > 0) {
            del = Date.parse(d.objet.pop().start) - beg;
          }

        });

        //  a.time = new Date(Date.parse(a.sujet[a.sujet.length-1].objet.pop().start) -  Date.parse(a.sujet[0].objet[0].start));
        a.timeString = new Date(del).toDateString().split(' ')[1];
        return a;
      }

    });

  }

  activate(sujet: Sujet) {

    this.currentSujet = sujet;

  }
  getC(){

    return this.curTime;
  }
  read(y){
    console.log(this.curTime);
    this.curTime=y;

  }
  play() {
    if(!this.playing){
      this.playing=true;
    const sujet= this.currentSujet;
    const dialogRef = this.menuDialog.open(PlayerPopupComponent, {panelClass:'frf',data: {lis: this.user,sujet: sujet}});

    dialogRef.afterClosed().subscribe(result => {
      this.playing=false;

    });
  }
  }


  start() {


  }

  pause() {
    this.playing = false;
  }

  move(e) {

    this.firstTime = e.value - this.storyStart;
   // console.log(this.firstTime);
    if (this.playing) {


    } else {
      this.objets.forEach(a => {
        if (Date.parse(a.start) - e.value < 0) {
        const te=this.obItems.filter(u => {
         if(u.id === a.id) return u;
        });

          if (te.length>0) {
            te.forEach(f => f.reDraw(e.value));
          } else {
            let curObjetItem = new ObjetItem(a, e.value);
            this.obItems.push(curObjetItem);
          }
          // curObjetItem.id=


        }

      });

    }
  }

  rep($event: any) {
    console.log($event);
  }
}
