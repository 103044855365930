import {Injectable, OnDestroy, Inject, Self, SkipSelf} from '@angular/core';
import {Observable, SubscriptionLike, Subject, Observer, interval} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {WebSocketSubject, WebSocketSubjectConfig} from 'rxjs/webSocket';

import {share, distinctUntilChanged, takeWhile} from 'rxjs/operators';
import {IWebsocketService, IWsMessage, BWsMessage, WebSocketConfig, BWebsocketService} from './websocket.interfaces';
import {config} from './websocket.config';
import {ActivatedRoute, Router} from '@angular/router';
import {BROWSER_STORAGE, BrowserStorageService} from '../storage/storage.service';


@Injectable({
    providedIn: 'root'
})
export class WebsocketService implements IWebsocketService, OnDestroy {

    private config: WebSocketSubjectConfig<IWsMessage<any>>;

    private websocketSub: SubscriptionLike;
    private statusSub: SubscriptionLike;

    private reconnection$: Observable<number>;
    public websocket$: WebSocketSubject<IWsMessage<any>>;
  public bwebsocket$: WebSocketSubject<BWsMessage<any>>;
    private connection$: Observer<boolean>;
    private wsMessages$: Subject<IWsMessage<any>>;
    private wsBinary$: Subject<BWsMessage<any>>;

    private reconnectInterval: number;
    private reconnectAttempts: number;
    public isConnected: boolean;


    public status: Observable<boolean>;
    private user: string;
    private host: string;


    constructor(@Inject(config) private wsConfig: WebSocketConfig, private router: Router, private route: ActivatedRoute) {

        /*  this.user = this.localStorageService.get('user');
          console.log(this.user);
          this.host = this.route.snapshot.paramMap.get('host');
  */
        this.wsMessages$ = new Subject<IWsMessage<any>>();
      this.wsBinary$ = new Subject<BWsMessage<any>>();
        this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
        this.reconnectAttempts = wsConfig.reconnectAttempts || 10; // number of connection attempts

        this.config = {
            url: wsConfig.url,
            closeObserver: {
                next: (event: CloseEvent) => {
                    this.websocket$ = null;
                    this.connection$.next(false);
                }
            },
            openObserver: {
                next: (event: Event) => {
                   console.log('WebSocket connected!');
                    this.connection$.next(true);
                }
            }
        };

        // connection status
        this.status = new Observable<boolean>((observer) => {
            this.connection$ = observer;
        }).pipe(share(), distinctUntilChanged());

        // run reconnect if not connection
        this.statusSub = this.status
            .subscribe((isConnected) => {
                this.isConnected = isConnected;

                if (!this.reconnection$ && typeof (isConnected) === 'boolean' && !isConnected) {
                    this.reconnect();
                }
            });

        this.websocketSub = this.wsMessages$.subscribe(
            null, (error: ErrorEvent) => console.error('WebSocket error!', error)
        );


    }

    public conne(h: string) {
        this.config.url = this.config.url.concat(h);

        this.connect();
    }

    ngOnDestroy() {
        this.websocketSub.unsubscribe();
        this.statusSub.unsubscribe();
    }


    /*
    * connect to WebSocked
    * */
    private connect(): void {
        this.websocket$ = new WebSocketSubject(this.config);
        this.websocket$.subscribe(
            (message) => this.wsMessages$.next(message),
            (error: Event) => {

                if (!this.websocket$) {
 // run reconnect if errors
                    this.reconnect();
                }
            });

       ///////////
      this.bwebsocket$ = new WebSocketSubject(this.config);
      this.bwebsocket$.subscribe(
        (message) => this.wsBinary$.next(message),
        (error: Event) => {

          if (!this.bwebsocket$) {
            // run reconnect if errors
            this.reconnect();
          }
        });


    }


    /*
    * reconnect if not connecting or errors
    * */
    private reconnect(): void {
        /*  */
        this.reconnection$ = interval(this.reconnectInterval)
            .pipe(takeWhile((v, index) => index < this.reconnectAttempts && !this.websocket$));

        this.reconnection$.subscribe(
            () => this.connect(),
            null,
            () => {
                // Subject complete if reconnect attemts ending
                this.reconnection$ = null;

                if (!this.websocket$) {
                    this.wsMessages$.complete();
                    this.connection$.complete();
                }
            });


    }


    /*
    * on message event
    * */
    public on<T>(event: string): Observable<T> {
        // console.log('d',event);
        if (event) {

            return this.wsMessages$.pipe(
                filter((message: IWsMessage<T>) => message.event === event),
                map((message: IWsMessage<T>) => message.data)
            );
        }
    }


    /*
    * on message to server
    * */
    public send(event: string, data: any = {}): void {
// c
        if (event && this.isConnected) {
            this.websocket$.next(<any>JSON.stringify({event, data}));
           //  console.log(JSON.stringify({event, data}));
        } else {
            console.error('Send error!');
        }
    }


  public bsend(event: any): void {
 if (event && this.isConnected) {
      this.bwebsocket$.next(<any>event);
      //  console.log(JSON.stringify({event, data}));
    } else {
      console.error('Send error!');
    }
  }

}



@Injectable({
  providedIn: 'root'
})
export class BiWebsocketService implements BWebsocketService, OnDestroy {

  private config: WebSocketSubjectConfig<IWsMessage<any>>;

  private websocketSub: SubscriptionLike;
  private statusSub: SubscriptionLike;

  private reconnection$: Observable<number>;
  public websocket$: WebSocketSubject<IWsMessage<any>>;
  public bwebsocket$: WebSocketSubject<BWsMessage<any>>;
  private connection$: Observer<boolean>;
  private wsMessages$: Subject<IWsMessage<any>>;
  private wsBinary$: Subject<BWsMessage<any>>;

  private reconnectInterval: number;
  private reconnectAttempts: number;
  public isConnected: boolean;


  public status: Observable<boolean>;
  private user: string;
  private host: string;


  constructor(@Inject(config) private wsConfig: WebSocketConfig, private router: Router, private route: ActivatedRoute) {

    /*  this.user = this.localStorageService.get('user');
      console.log(this.user);
      this.host = this.route.snapshot.paramMap.get('host');
*/
    this.wsMessages$ = new Subject<IWsMessage<any>>();
    this.wsBinary$ = new Subject<BWsMessage<any>>();
    this.reconnectInterval = wsConfig.reconnectInterval || 5000; // pause between connections
    this.reconnectAttempts = wsConfig.reconnectAttempts || 10; // number of connection attempts

    this.config = {
      url: 'wss://facereplays23.herokuapp.com',
      binaryType: 'blob',
      closeObserver: {
        next: (event: CloseEvent) => {
          this.websocket$ = null;
          this.connection$.next(false);
        }
      },
      openObserver: {
        next: (event: Event) => {
          console.log('WebSocket connected!');
          this.connection$.next(true);
        }
      }
    };

    // connection status
    this.status = new Observable<boolean>((observer) => {
      this.connection$ = observer;
    }).pipe(share(), distinctUntilChanged());

    // run reconnect if not connection
    this.statusSub = this.status
      .subscribe((isConnected) => {
        this.isConnected = isConnected;

        if (!this.reconnection$ && typeof (isConnected) === 'boolean' && !isConnected) {
          this.reconnect();
        }
      });

    this.websocketSub = this.wsMessages$.subscribe(
      null, (error: ErrorEvent) => console.error('WebSocket error!', error)
    );


  }

  public conne(h: string) {
    this.config.url = this.config.url.concat(h);

    this.connect();
  }

  ngOnDestroy() {
    this.websocketSub.unsubscribe();
    this.statusSub.unsubscribe();
  }


  /*
  * connect to WebSocked
  * */
  private connect(): void {
    this.websocket$ = new WebSocketSubject(this.config);
    this.websocket$.subscribe(
      (message) => this.wsMessages$.next(message),
      (error: Event) => {

        if (!this.websocket$) {
          // run reconnect if errors
          this.reconnect();
        }
      });

    ///////////
    this.bwebsocket$ = new WebSocketSubject(this.config);
    this.bwebsocket$.subscribe(
      (message) => this.wsBinary$.next(message),
      (error: Event) => {

        if (!this.bwebsocket$) {
          // run reconnect if errors
          this.reconnect();
        }
      });


  }


  /*
  * reconnect if not connecting or errors
  * */
  private reconnect(): void {
    /*  */
    this.reconnection$ = interval(this.reconnectInterval)
      .pipe(takeWhile((v, index) => index < this.reconnectAttempts && !this.websocket$));

    this.reconnection$.subscribe(
      () => this.connect(),
      null,
      () => {
        // Subject complete if reconnect attemts ending
        this.reconnection$ = null;

        if (!this.websocket$) {
          this.wsMessages$.complete();
          this.connection$.complete();
        }
      });


  }


  /*
  * on message event
  * */
  public on<T>(event: string): Observable<T> {
    // console.log('d',event);
    if (event) {

      return this.wsMessages$.pipe(
        filter((message: IWsMessage<T>) => message.event === event),
        map((message: IWsMessage<T>) => message.data)
      );
    }
  }


  /*
  * on message to server
  * */
  public send(event: string, data: any = {}): void {
// c
    if (event && this.isConnected) {
      this.websocket$.next(<any>JSON.stringify({event, data}));
      //  console.log(JSON.stringify({event, data}));
    } else {
      console.error('Send error!');
    }
  }


  public bsend(event: any): void {
    if (event ) {
      console.log(event);
      this.bwebsocket$.next(<any>event);

    } else {
      console.error('Send error!');
    }
  }

}
