import {Component, OnInit, Self, SkipSelf} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

import {WebsocketService} from '../websocket/websocket.service';
import {BROWSER_STORAGE, BrowserStorageService} from '../storage/storage.service';
import {RestService} from '../rest/rest.service';
import {HttpClient} from '@angular/common/http';
import {User} from '../user/user';
import {IMessage} from '../app.component';
import {WS} from '../websocket.events';
import {Observable, Subject} from 'rxjs';
import {Gost} from '../global-interfaces/gost';
import {animate, style, transition, trigger} from '@angular/animations';
import {PaperService} from '../paper/paper.service';
import {Story} from '../global-interfaces/story';
import {environment} from '../../environments/environment';
import {Orient} from '../global-interfaces/orient';
// @ts-ignore
import paper from 'paper';
import {Vector} from '../global-interfaces/vector';

import {DialogData} from '../guest/start-dialog/start-dialog.component';
import {AuthService} from '../services/auth.service';
import {Meta} from '@angular/platform-browser';
import {SharpService} from '../services/sharp.service';
import {PlayerComponent} from '../player/player.component';


@Component({
  animations: [
    trigger('myInsertRemoveTrigger', [
      transition(':enter', [
        style({opacity: 0}),
        animate('300ms', style({opacity: 1})),
      ]),
      transition(':leave', [
        animate('500ms', style({opacity: 0}))
      ])
    ]),
  ],
  selector: 'app-host',
  templateUrl: './host.component.html',
  styleUrls: ['./host.component.css'],
  providers: [RestService, HttpClient, {provide: Window, useValue: window},
    BrowserStorageService,
    {provide: BROWSER_STORAGE, useFactory: () => sessionStorage}
  ]
})
export class HostComponent implements OnInit {
  public hostId: string;
  public user: User;
  public type: string;
  myExtObject: any;
  guests: Gost[];
  users: User[];
  guest: Gost;
  hi: number;
  wi: number;
  process: string;
  log: string;
  guestUrl: string;
  host_db: number;
  user_db: number;
  auth = false;
  public story: Story;
  stories: Story[];
  user_ex: boolean;
  public dta: DialogData;
  private userId: string;
  private messages$: Observable<IMessage>;
  private moves$: Observable<IMessage>;
  private color: string;
  private odB: Subject<DialogData>;
  private parSujet: string;
  mClosedC = false;
  logg: boolean;
  drawing: boolean;
  played: number;
  command: Subject<string>;
  player: PlayerComponent;
  private parStory: string;


  constructor(private sharpService: SharpService, private metaService: Meta, private authService: AuthService, private router: Router, public route: ActivatedRoute, public wsService: WebsocketService,
              @Self() private sessionStorageService: BrowserStorageService,
              @SkipSelf() private localStorageService: BrowserStorageService, private restService: RestService, private paperService: PaperService) {

    const userR = this.authService.check();
    this.dta = new DialogData();
    this.authService.set(userR);

    userR.subscribe(
      (o: any) => {

this.dta.user = o;
        if (o.id) {

          this.auth = true;
          localStorage.setItem('user_id', o.id);
          localStorage.setItem('user_name', o.name);

          this.user_db = o.id;
          this.authService.userO.next(new User(o.id, o.name));
          this.restService.loaduser(o.id).subscribe(u=>{

            this.user=u;
          });

        } else {
          this.auth = false;
        }


      }
    );

  }

  loadUsers(a): void {

    if (this.type === 'host' || this.type === 'guest') {

      this.restService.loadusers('').subscribe(loc => {
        /*  this.restService.getLocations(1).subscribe(
            af => {
              loc.forEach((a, i, ar) => {
                console.log('update `user` set `drum` ='+af[i].id+'  where id='+a.id+';');

  //  console.log(a,af[i]);
              });
            });
  */
        loc.forEach((a, i, ar) => {
          const si = a.size.split(',');
          if (this.type === 'host') {
            if (Number(si[0]) < Number(si[1])) {
              const new1 = new Gost(String(a.name), this.paperService);
              new1.resize(String(a.size));
              new1.pColor = new paper.Color({hue: Number(a.color) * 360, saturation: 0.1, brightness: 0.2});
              new1.color = Number(a.color);
              new1.hue = Number(a.color);
              new1.posX = this.wi - Math.random() * this.wi * 2;
              new1.posY = i * 12 - 800;
              new1.line = this.paperService.addPath(String(a.size), a, new1);
              this.guests.push(new1);

              const eu = new Orient(10 - Math.random() * 20, 90, 20 - Math.random() * 40, 0.3, 0, 0);
              eu.motion = new Vector(0, 0, 0);
              new1.orient = eu;
              const tem = new1.mat.fromEuler(eu.alpha, eu.beta, eu.gamma, 'ZXY');


              if (eu.scale) {
                new1.scale = eu.scale;
              }
              if (eu.x) {
                new1.posX = eu.x;
              }
              if (eu.y) {
                new1.posY = eu.y;
              }

              this.paperService.trans(new1, eu, tem);

            }

            /**/
            if (this.type === 'host' && i === ar.length - 1) {
              this.paperService.getF(1);
            }
            /*
            if (this.type === 'guest' && i === ar.length - 1) {

              paper.project.activeLayer.data.guests = this.guests;
              paper.project.activeLayer.onFrame = function() {
                this.data.guests.forEach(a => {
                  a.trans();

                });
              };
            }*/
          } else {

            console.log(a);
          }

        });

      });


    }

  }

  ngOnInit() {

this.command = new Subject<string>();
    const para = this.route.snapshot.paramMap.get('location');

    if (para && para.match(/([\d]+)/)) {
      this.parSujet = para;
    }else if(para && para.match(/([\w]+)/) && this.route.children.length==1){
      this.route.children[0].params.subscribe(i=>{
        this.parSujet = i.id;

      });
      this.process = 'story';
      this.parStory = para;

    } else {
      this.hostId = para;
      this.parSujet = '';
    }

    this.odB = new Subject<DialogData>();
    if (this.route.snapshot.url.length <= 1 || this.parSujet != '') {
      if (this.parSujet === '') {
        this.parSujet = '36';
        this.process = 'main';
      } else {
       if(!this.process) this.process = 'sujet';

      }
      this.type = 'draw';

      const bo = {size: '360,530'};

      if (this.process === 'main') {
        this.restService.loadItem('sujet', 'filter=id,eq,' + this.parSujet + '&join=objet').subscribe(loc => {

console.log(loc);

          this.dta.case = 'main';
          this.dta.lis = loc[0];
          this.dta.sujet = loc[0];
          if (!loc[0].size) {
       this.dta.lis.size = '360,530';
          }

          this.metaService.addTag({name: 'description', content: this.dta.sujet.name});
          this.odB.next(this.dta);
        });


      } else if (this.process === 'story') {
        this.restService.loadItem('story', 'filter=url,eq,' + this.parStory + '&join=sujet,objet').subscribe(loc => {

const sujet=loc[0].sujet.filter(u=> u.id === Number(this.parSujet) ?  u : null)[0];
/*      */

          sujet.objet = loc[0].template.objet.map(o => {o.type = 'temp'; return o; }).concat(sujet.objet.sort((a, b) => (a.start < b.start ? -1 : 1)));
          this.dta.case = 'story';
          this.dta.lis = loc[0].template;
          this.dta.sujet = sujet;
          if ( loc[0].template.size) {
            this.dta.lis.size = '360,530';
          }

          this.odB.next(this.dta);


        });


      } else {
        this.restService.loadItem('sujet', 'filter=id,eq,' + this.parSujet + '&join=objet').subscribe(loc => {
  this.dta.lis = loc[0];
          this.dta.sujet = loc[0];

          this.odB.next(this.dta);
        });
      }
    } else {


      this.type = this.route.snapshot.url[1].path;


      this.user_ex = false;
      this.wi = window.innerWidth;
      this.hi = window.innerHeight;

      this.guests = [];
      this.users = [];
      //  this.userId = this.localStorageService.get('user');
      this.userId = localStorage.getItem('user_id');

      this.hostId = this.route.snapshot.paramMap.get('location');


      /*
        s         if (typeof Worker !== 'undefined') {
        // Create a new

        const worker = new Worker('../test/glob.worker', {type: 'module'});
        console.log(`page got message: `);
        worker.onmessage = ({data}) => {
          console.log(`page got message: ${data}`);
        };


        const rectangle = new paper.Rectangle(new paper.Point(20, 20), new paper.Size(60, 60));
        const path = new paper.Path.Rectangle(rectangle);
        path.fillColor = new paper.Color('black');
        const pap = new paper.Group({
          children: [path],
          // Set the stroke color of all items in the group:
          strokeColor: 'black'
        });
        const imm = pap.rasterize({insert: false});
        paper.project.activeLayer.addChild(pap);
        console.log(imm.toDataURL());

        worker.postMessage({im: imm.toDataURL()});



      } else {

        // Web workers are not supported in this environment.
    //  You should add a fallback so that your program still executes correctly.
      }
   /*     */
      if (this.hostId) {
        this.restService.loadlocation(this.hostId).subscribe(loc => {
          if (loc != undefined) {
            this.host_db = loc.id;
            //    console.log(loc);
            this.stories = loc.story;
            this.restService.setLocation(loc.id);
          } else {
            this.restService.addLocation(this.hostId).subscribe(loc => {
              this.host_db = loc;
              this.restService.setLocation(loc);
            });

          }
        });
      } else {
        console.log('no');

      }

      this.guestUrl = 'https://'.concat(environment.server, '/', this.hostId, '/guest');

      if (this.userId) {

      } else {
        this.userId = 'user_'.concat(String(Date.now()));
        this.localStorageService.set('user', this.userId);

      }


      this.wsService.conne('?t=1&user='.concat(this.userId, '&host=', this.hostId,
        '&type=', this.type, '&size=', String(window.innerWidth), ',', String(window.innerHeight)));

      this.restService.loaduser(this.userId).subscribe(loc => {
        if (loc) {

          this.user_db = Number(loc.id);
          this.restService.setUser(loc);
          this.localStorageService.set('userDb', String(loc.id));
          //  this.color = loc[0].color;

          if (loc.size === null) {
            this.restService.updateUser(loc.id, {size: ''.concat(String(window.innerWidth), ',', String(window.innerHeight))});
          }
          this.restService.setUser(loc);
          if (this.type === 'canvas') {
            this.paperService.getF(1);
          }
          this.story = new Story(loc.id);

          if (loc.story && loc.story.length > 0) {

            this.story.story_db = loc.story[0].id;
          }
          this.user = new User(this.userId, loc.name, loc.size, this.wi, this.hi);
          this.user.nic = loc.nic;
          if (loc.story.length > 0) {
            this.user.story = loc.story;
          }
          this.user_ex = true;

        } else {

          const ccol = Math.random();
          this.restService.addUser(this.userId, ''.concat(String(window.innerWidth), ',', String(window.innerHeight)), ccol).subscribe(loc => {
            this.user_db = loc;

            this.restService.setUser(loc);
            this.story = new Story(loc);
            if (this.type === 'guest' || this.type === 'draw') {
              this.user = new User(this.userId, 'guest', ''.concat(String(window.innerWidth), ',', String(window.innerHeight)), this.wi, this.hi);
              this.user_ex = true;
            }

          });

        }
        if (this.type === 'host' || this.type === 'draw' || this.type === 'guest' || this.type === 'canvas') {
          this.log = this.type;
          this.messages$ = this.wsService.on<IMessage>(WS.ON.USERS);
          this.messages$.subscribe(todos => {
            if (todos.user !== this.user.userId) {

              if (typeof (todos) === 'object') {
//
                const inp = JSON.parse(String(todos.value));

                if ((todos.type === 'guest' || todos.type === 'draw') && inp.str === 'welcome') {

                  if (this.guests.length > 0) {
                    this.guest = this.guests.find(user => String(user.userId) === String(todos.user));
                    if (this.guest) {
                      this.guest.awake();
                    }

                  }
                  if (!this.guest) {
                    if (todos.type === 'guest') {

                      const new1 = new Gost(String(todos.user), this.paperService);
                      console.log(todos.user);
                      this.guests.push(new1);
                      this.restService.loaduser(todos.user).subscribe(user => {
                        console.log(user);
                        const new11 = this.guests.find(userf => String(userf.userId) === user[0].name);
                        new11.resize(user[0].size);
                        new11.pColor = new paper.Color({hue: Number(user[0].color) * 360, saturation: 0.1, brightness: 0.4});
                        new11.color = Number(user[0].color);
                        new11.hue = Number(user[0].color);
                        if (paper.project) {
                          new11.line = this.paperService.addPath(user[0].size, user[0], new11);
                        }
                      });
                    }
                    if (todos.type === 'draw') {
                      this.users.push(new User(todos.user, todos.name, String(todos.size), this.wi, this.hi));
                    }

                  } else {


                  }

                }
                if (todos.type === 'guest' && inp.str === 'gone') {

                  this.guest = this.guests.find(user => String(user.userId) === String(todos.user));
                  this.guest.active = false;
                }
              }
            }
          });
          this.moves$ = this.wsService.on<IMessage>(WS.ON.USERS_T);
          this.moves$.subscribe(todos => {

            if (typeof (todos) === 'object') {

              const eu: Orient = JSON.parse(todos.value);

              const guest = this.guests.find(user => String(user.userId) === String(todos.user));

              if (guest !== undefined) {
                if (guest.line !== null) {

                  if (eu.mode) {

                    if (eu.mode === 'awake') {
                      guest.active = true;
                      guest.awake();
                    } else if (eu.mode === 'lock') {
                      //  console.log(eu.case);
                      if (eu.case === '1') {
                        guest.lock();
                      }
                      if (eu.case === '0') {
                        guest.unlock();
                      }
                    } else if (eu.mode === 'tap') {
                      console.log(guest);
                      // guest.tap(String(eu.energy));
                    } else if (eu.mode === 'sleep') {
                      guest.sleep();
                      guest.active = false;
                      //  guest.line.visible = false;

                    } else if (eu.mode === 'mess') {
                      guest.prinT(eu.content);
                      //  guest.line.visible = false;

                    }
                  } else {
                    if (!guest.active) {
                      guest.active = true;
                      guest.awake();

                    }
                    if (eu.scale) {
                      guest.scale = eu.scale;
                    }
                    if (eu.x) {
                      guest.posX = eu.x;
                    }
                    if (eu.y) {
                      guest.posY = eu.y;
                    }

                    const tem = guest.mat.fromEuler(eu.alpha, eu.beta, eu.gamma, 'ZXY');


                    this.paperService.trans(guest, eu, tem);


                    const delta = 100;
                  }
                  /* guest.transf = 'matrix3d('
                       .concat(new Matrix3d(tem
                           .matrix3D()).rotateX(90, guest.scale, eu.x, eu.y, tem.vector, delta).toString(), ')');

              */
                }
              } else {
                const new1 = new Gost(String(todos.user), this.paperService);

                this.guests.push(new1);
                this.restService.loaduser(todos.user).subscribe(user => {
                  const new11 = this.guests.find(userf => String(userf.userId) === user[0].name);
                  new11.resize(user[0].size);
                  new11.pColor = new paper.Color({hue: Number(user[0].color) * 360, saturation: 0.3, brightness: 0.3});
                  new11.color = Number(user[0].color);
                  new11.hue = Number(user[0].color);
                  if (paper.project) {
                    new11.line = this.paperService.addPath(user[0].size, user[0], new11);
                  }
                });
                /*   */

              }

            }

          });
        }


      });


    }

  }

  getOp(guest: Gost) {
    return 1 - guest.scale * 0.7 * guest.h / this.hi;

  }

  mop() {
    if(!this.logg){
      if (this.mClosedC) {
        this.mClosedC = false;
      } else {
        this.mClosedC = true;
      }
    }
  }

  login() {
    this.logg = true;

  }

  logged($event: any) {
    this.logg = false;
  }

  help(profile: string) {
    this.command.next(profile);

  }

  startDraw() {

  }

  saveWEBP() {
this.player.saveWEBP();
  }

  playyed(): void {
    this.played = 1;
  }

  initPlayer($event: any):void {
    this.player = $event;
  }
}
