<div *ngIf="role=='player'" class="player">
  <ion-icon *ngIf="!getPlaying()" (click)="play(true)"  name="volume-mute-outline"></ion-icon>

  <ion-icon *ngIf="getPlaying()" (click)="play(false)" name="volume-high-outline"></ion-icon>
</div>
<div *ngIf="role === 'stream'">

  <ion-icon *ngIf="!getStreaming()" (click)="stream(true)"  name="mic-off-circle-outline"></ion-icon>
  <ion-icon *ngIf="getStreaming()" (click)="stream(false)"  name="mic-circle-outline"></ion-icon>

</div>
