import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import paper from 'paper';
import {Subject} from 'rxjs';
import {RestService} from '../rest/rest.service';
import {User} from '../user/user';
import {WebsocketService} from '../websocket';


@Component({
  selector: 'app-globe',
  templateUrl: './globe.component.html',
  styleUrls: ['./globe.component.css']
})
export class GlobeComponent implements OnInit {
  @ViewChild('globeDiv') canvasElement: ElementRef;
  @Input() mouse: Subject<number[]>;
  @Input() bang: Subject<any>;
  drummersO: Subject<any[]>;
  @Input() myAct: Subject<any>;
  @Input() user: User;
  w: number;
  h: number;
  bg: any;
  scene: any;
  drummers: any[];

  private globe: paper.Group;
  private globeD: paper.Item;
  private delx0: number;
  private dely0: number;
  private poz: paper.Point;
globeBG:paper.Path;
  private buben: paper.Group;
  private audioObj: HTMLAudioElement;
  private mob: number;

  constructor(private restService: RestService, private wsService: WebsocketService) {

  }

  ngOnInit(): void {

    this.audioObj = new Audio();
    this.audioObj.src = 'assets/lq.mp3';
    this.audioObj.load();
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      this.mob = 1;

    }

   this.bang.subscribe(d=>{


const ta = d.changedTouches[0]  as Touch;

console.log(ta.radiusX,ta.force);
const po = new paper.Point(ta.clientX,ta.clientY);
if(this.buben.hitTest(po)){
 let force = ta.force;

if(force==0) force=0.01;
if(this.mob==1){force*=100;}
  const AudioContext = window.AudioContext;
  this.audioObj.volume = Math.min(1,force/10);
  this.audioObj.play();
  // alert(force);
  this.wsService.send('set-o', '{"mode":"tap","energy":"'.concat(force.toString(), '"}'));

}


   });


    this.drummersO= new Subject<any[]>();
    this.myAct.subscribe(u=>{
const tar=this.globeD.children['me'];
const data=tar.data;
let ci;
if(this.globe.children['me']){
ci=this.globe.children['me'];

    } else{
   ci=new paper.Group([new paper.Path.Circle({ center: new paper.Point(0,0),
    radius: 6,strokeColor:'red',strokeWidth:3})]);
  ci.applyMatrix=false;
  ci.set({name:'me'});
  this.globe.addChild(ci);

}

 //    data.co[0],data.co[1]
     var r=new paper.Point(data.co[0] - this.poz.x,data.co[1] - this.poz.y);
 	r=r.normalize();
 	const cir=ci.children[0];
cir.opacity=0.2;
      cir.applyMatrix=false;
	ci.matrix=new paper.Matrix(r.x,r.y,-r.y,r.x,data.co[0],data.co[1]);
	cir.matrix=new paper.Matrix(data.an,0,0,1,0,0);

     const tw = cir.tween({
        opacity:1,'matrix.a':data.an*5,'matrix.d':5

      }, {
        easing: 'easeOutQuint',
        duration: 100
      });
      /*tw.onUpdate = function(event) {
   };


       */
      tw.then(function(event) {
       const va = cir.tween({
          opacity:0,'matrix.a':data.an,'matrix.d':1}, {
          easing: 'easeOutQuint',
          duration: 300
        });
        va.then(function(event) {
     // this.object.parent.remove();
        });

      });







    /*   */




    })
    this.delx0 = 0;
    this.dely0 = 0;
    this.w = window.innerWidth;
    this.h = window.innerHeight;
this.poz=new paper.Point(this.w*0.5,this.h*0.3);
    this.delx0 = -this.user.lng / 180 * Math.PI - Math.PI / 2;
    this.dely0 = -this.user.lat / 180 * Math.PI+0.3;
    this.restService.getLocations(1).subscribe(
      af => {
        this.drummers = af;

      this.drummers.push({ind:'me',lat:this.user.lat,lng:this.user.lng});
        this.drummersO.next( this.drummers);

        this.mouse.subscribe(a => {

          const ppo=new paper.Point(a[2],a[3]);

          if (a[1] && this.globeBG.hitTest(ppo)) {

          }
        });
      }
    );

    /*
    delx=-30.36395/180*Math.PI-Math.PI/2;
dely=-39.93672/180*Math.PI;
     */

  }

  bong(target, strength, emo) {

    //  this.globeD.children.

  }

  ngAfterViewInit() {
    paper.setup(this.canvasElement.nativeElement);
// paper.view.autoUpdate = true;
    //  this.bwsService.conne('?t=1');

    const size = paper.view.size;

    paper.project.activeLayer.data.beta = 12;
    paper.project.activeLayer.addChild(new paper.Group());
    paper.project.activeLayer.addChild(new paper.Group());

    var ra = this.h * 0.26;
    var poz = new paper.Point(this.w * 0.5, ra * 1.3);
    var car = 1600;
    var tt = Math.sqrt((car) * (car) - ra * ra);

   this.globeBG = new paper.Path.Circle({radius: ra*(car/(car + ra )), center: new paper.Point(this.w/2, this.h*0.3), applyMatrix: false});
   // path2.matrix = new paper.Matrix(ra * car / (tt * 100), 0, 0, ra * car / (tt * 100), poz.x, poz.y);

    const gra1 = new paper.Gradient();
    gra1.stops = [new paper.GradientStop(new paper.Color('#73b779'), 0.05),
      new paper.GradientStop(new paper.Color('#3a4167'), 0.6),
      new paper.GradientStop(new paper.Color('#312a20'), 1)];

    gra1.radial = true;

    this.globeBG.fillColor = new paper.Color({
      gradient: gra1,
      origin: this.globeBG.bounds.topLeft,
      destination: this.globeBG.bounds.rightCenter
    });

    const gra = new paper.Gradient();
    gra.radial = true;
    gra.stops = [new paper.GradientStop(new paper.Color('#73b779'), 0.05),
      new paper.GradientStop(new paper.Color('#3a4167'), 0.6),
      new paper.GradientStop(new paper.Color('#000000'), 1)];
    this.bg = paper.project.activeLayer.children[0];
    this.globe =new paper.Group([ new paper.CompoundPath({
      children: [], fillColor: {
        gradient: gra,
        origin: new paper.Point(0, 0),
        destination: new paper.Point(this.w * 0.7, this.w * 0.72)
      }
    })]);
    this.globeD = new paper.Group;
    this.bg.addChild(this.globeBG);
    this.bg.addChild(this.globe);
    this.bg.addChild(this.globeD);
    this.scene = paper.project.activeLayer.children[1];
    this.buben=new paper.Group([
      new paper.Path.Circle({
        radius: this.w*0.48,
        fillColor: new paper.Color(0.8,0.8,0.8),
        center: new paper.Point(this.w/2, this.h*0.6),
        applyMatrix: false
      })]);
    this.scene.addChild(this.buben);


  }

}
