import paper from 'paper';

export class Transf {
  start: number;
  startR: number;
  matrix: paper.Matrix;
  delta: number;
  fade: boolean;
  cas: boolean;
  constructor(tm, cas) {
    this.cas = cas;
    this.startR = Date.now();
    this.start = tm;
  }
}
