import {Component, HostListener, Input, KeyValueDiffer, KeyValueDiffers, OnInit} from '@angular/core';
import {User} from '../user/user';
import {Orient} from '../global-interfaces/orient';
import {WebsocketService} from '../websocket';
import {HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';
import {MyHammerConfig} from '../app.component';
import {Vector} from '../global-interfaces/vector';
import paper from 'paper';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {PaperService} from '../paper/paper.service';
import {StartDialogComponent} from './start-dialog/start-dialog.component';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-guest',
  styleUrls: ['./guest.component.css'],
  templateUrl: './guest.component.html',
  providers: [{
    provide: HAMMER_GESTURE_CONFIG,
    useClass: MyHammerConfig
  }]
})

export class GuestComponent implements OnInit {
  @Input() group: string;
  @Input() user: User;
  arrayColors: any[];
  arrayColors1: any[];
  selectedColor: number;
  actColor: any;

  userId: string;
  wi: number;
  hi: number;
  wii: number;
  hii: number;
  color: paper.Color;
  scale: number;
  scaleC: number;
  connected: boolean;
  ios = false;
  overlayClicked = false;
  orient = false;
  x = 0;
  y = 0;
  x2: number;
  y2: number;
  check = 0;
  motion = new Vector(0, 0, 0);
  speed = new Vector(0, 0, 0);
  position = new Vector(0, 0, 0);
  tic = 0;
  delta = 0;
  menuV = false;
  enabled = false;
  motions = [];
  energy;
  locked: boolean;
// requesting device orientation permission
  bwi: number;
  private orient1: Orient;
  private orientDiffer: KeyValueDiffer<string, any>;
  private wakeLock1: any;
  private locations: Observable<unknown>;
  private openDialog: MatDialogRef<StartDialogComponent, any>;
  private coords: any;
  private helpDialog: MatDialogRef<StartDialogComponent, any>;

  constructor(public menuDialog: MatDialog, public helpeDialog: MatDialog,
              private wsService: WebsocketService,
              private differs: KeyValueDiffers,
              private paperService: PaperService) {
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: any): void {
    if (this.wsService.isConnected) {
      this.wsService.send('set-o', '{"mode":"awake"}');

    }
  }

  @HostListener('window:blur', ['$event'])
  onBlur(event: any): void {
    if (this.wsService.isConnected) {
      this.wsService.send('set-o', '{"mode":"sleep"}');

    }
  }

  public sleep(): void {


  }

  public awake(): void {


  }

  public helpp(v): void {
    if(v.indexOf('lock')){

      this.locked=true;
    }else{
    let cs = 0;
    if (this.helpDialog) {
      cs = this.helpDialog.getState();
    }


    if (!this.helpDialog || cs === 2) {
      this.helpDialog = this.menuDialog.open(StartDialogComponent, {
        data: {
          case: v,

          sta: this.sta
        }
      });
    }}
  }

  public sta(): void {
    if (this.ios) {
      this.requestPermissionsIOS();
    } else {
      this.paperService.getF(1);
      this.menuV = true;
    }

  }

  public sel(event): void {

    const l = event.target.parentElement.getElementsByClassName('toolbar__center');
    const count = l.length;
    for (let i = 0; i < count; i++) {
      l[i].className = 'toolbar__center';
    }
    event.target.className = 'toolbar__center active';
  }

  public sca(event, a): void {
    if (a === 0) {
      this.scaleC = this.scale * event.scale;
    }
    if (a === 1) {
      this.scale = event.scale * this.scaleC;
    }

  }

  public accelerate(a, t): void {
    /*
            let newZ = Object.assign({}, this.motions[0]);
            const aa = a as Vector;
            newZ.acceleration = aa.length > .2 ? aa : new Vector(0, 0, 0); // noise filter
            newZ.time = t;
            newZ = this.eulerStep(this.motions[0], newZ);

            newZ.velocity = newZ.velocity.multiply(.9); // friction
            newZ.velocity = newZ.velocity.length < .02 ? new Vector(0, 0, 0) : newZ.velocity; // noise filter
            newZ.position = newZ.position.multiply(.999); // tend back to zero
            if (newZ.velocity) {
            }
            this.motions.unshift(newZ);

     */
  }

  ngOnInit(): void {
    this.ios = ((/iPad|iPhone|iPod/.test(navigator.userAgent)) && (typeof (DeviceMotionEvent as any).requestPermission === 'function'));

    this.coords = {t: false};
    this.locations = new Observable((observer) => {
      let watchId: number;

      // Simple geolocation API check provides values to publish
      if ('geolocation' in navigator) {
        // @ts-ignore
        watchId = navigator.geolocation.getCurrentPosition((position: any) => {
          observer.next(position);
        }, (error: any) => {
          observer.error(error);
        });
      } else {
        observer.error('Geolocation not available');
      }

      // When the consumer unsubscribes, clean up data ready for next subscription.
      return {
        unsubscribe(): void {
          navigator.geolocation.clearWatch(watchId);
        }
      };
    });


    this.openDialog = this.menuDialog.open(StartDialogComponent, {
      data: {
        case: false,

        sta: this.sta
      }
    });
    this.openDialog.beforeClosed().subscribe(a => {
      this.sta();
    });

    this.locked = false;
    this.motions.push({velocity: new Vector(0, 0, 0), position: new Vector(0, 0, 0)});
    // const colorPicker = new iro.ColorPicker(window.document.getElementById('colp'));
    // @ts-ignore
    if (navigator.wakeLock) {
      // @ts-ignore
      navigator.wakeLock
        .request('screen')
        .then((wakeLock) => {
          this.wakeLock1 = wakeLock;
          this.enabled = true;
          // alert("Wake Lock active.");
          this.wakeLock1.addEventListener('release', () => {
            // ToDo: Potentially emit an event for the page to observe since
            // Wake Lock releases happen when page visibility changes.
            // (https://web.dev/wakelock/#wake-lock-lifecycle)
            console.log('Wake Lock released.');
          });
        })
        .catch((err) => {
          this.enabled = false;
          console.error(`${err.name}, ${err.message}`);
          throw err;
        });

    }

    this.color = this.paperService.hslToRGB(this.user.color, 0.2, 0.5);
    this.selectedColor = 0;
    this.arrayColors = ['rgba(100,200,100,1)'];
    this.actColor = this.arrayColors[0];
    this.arrayColors1 = ['rgba(100,200,100,1)', 'rgba(100,100,200,1)'];
    this.wii = window.innerWidth;
    this.hii = window.innerHeight;
    // Check if is IOS 13 when page loads.
    this.ios = this.orient = ((/iPad|iPhone|iPod/.test(navigator.userAgent)) && (typeof (DeviceMotionEvent as any).requestPermission === 'function'));
    this.tic = Date.now();


    this.wi = window.screen.width;
    this.scaleC = this.scale = 1;
    this.hi = window.screen.height;
    this.orient1 = new Orient(2, 3, 4, 1, 0, 0);
    this.orientDiffer = this.differs.find(this.orient1).create();

    this.connected = this.wsService.isConnected;


    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) && !this.ios) {
      window.addEventListener('devicemotion', ev => {
        //   this.orient1 = {gamma: 0, alpha: ev.alpha, beta: 90, scale: this.scale, x: this.x, y: this.y};

        /*
                if (this.check < 5) {
                  this.check++;
                  this.motion.add(ev.acceleration as Vector);

                } else {
                  this.motion.add(ev.acceleration as Vector);
                  this.delta = Date.now() - this.tic;
                  this.tic = Date.now();
                  this.check = 0;
                  const sp = this.motion.divide(6);
                  //  this.accelerate(sp, ev.timeStamp);

                }
        */
      }, true);
      window.addEventListener('deviceorientation', ev => {
        //   this.orient1 = {gamma: 0, alpha: ev.alpha, beta: 90, scale: this.scale, x: this.x, y: this.y};
        this.orient1 = {
          gamma: ev.gamma,
          alpha: ev.alpha,
          beta: ev.beta,
          scale: this.scale * 0.4,
          x: this.x,
          y: this.y,
          motion: this.position,
          energy: this.energy
        };
      }, true);
      /*   this.ngZone.runOutsideAngular(() => {

          //



         });
*/

    }

  }

  requestPermissionsIOS(): void  {
    //  this.requestDeviceMotionIOS();
    this.requestDeviceOrientationIOS();
    this.overlayClicked = true;
  }

// requesting device motion permission
  requestDeviceMotionIOS(): void  {
    if (typeof (DeviceMotionEvent as any).requestPermission === 'function') {
      (DeviceMotionEvent as any).requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            window.addEventListener('devicemotion', ev => {
              this.orient1.motion = ev.acceleration as Vector;


            });
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }
  }

  requestDeviceOrientationIOS(): void  {
    if (typeof (DeviceOrientationEvent as any).requestPermission === 'function') {
      (DeviceOrientationEvent as any).requestPermission()
        .then(permissionState => {
          if (permissionState === 'granted') {
            this.orient = false;
            window.addEventListener('deviceorientation', ev => {
              this.orient1 = {
                gamma: ev.gamma,
                alpha: ev.alpha,
                beta: ev.beta,
                scale: this.scale * 0.4,
                x: this.x,
                y: this.y, motion: this.position
              };
              this.paperService.getF(1);
              this.menuV = true;

            });
          }
        })
        .catch(console.error);
    } else {
      // handle regular non iOS 13+ devices
    }
  }

  ngDoCheck(): void {
    const changes = this.orientDiffer.diff(this.orient1);

    if (changes && this.wsService.isConnected && !this.locked) {
      if (paper.project && paper.project.activeLayer.data) {
        paper.project.activeLayer.data.beta = this.orient1.beta;
        paper.project.activeLayer.data.gamma = this.orient1.gamma;
      }
      this.orient1.time = new Date().getTime();
    this.wsService.send('set-o', JSON.stringify(this.orient1));
    }

  }

  mo(event: any, a: number): void {
    // console.log(event);
    if (a === 1) {
      this.x = this.x2 - event.deltaX;
      this.y = this.y2 - event.deltaY;
    } else {
      this.x2 = this.x;
      this.y2 = this.y;

    }
    console.log(this.x);

  }

  cool($event: any): void {


  }

  ch_c($event: any): void {
    this.actColor = $event;

  }

  getBackgroundColor(a): any {
    if (1 === 1) {
      return this.actColor;
    }
  }

  tap(ev: any): void {
    const force = ev.force;
    this.wsService.send('set-o', '{"mode":"tap","energy":"'.concat(force, '"}'));


  }

  lock(c: boolean): void {
    this.locked = c;
  }

  hide(): void {

  }

  private eulerStep(state0, state1): any {

    const interval = (state1.time - state0.time) / 1000; // convert ms to s
    if (interval) {
      state1.position = state0.position.add(state0.velocity.multiply(interval));
      state1.velocity = state0.velocity.add(state0.acceleration.multiply(interval));
    }
    return Object.assign({}, state1);

  }


}
