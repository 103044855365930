import paper from 'paper';

export class Brush {
  radius: number;
  color: paper.Color;
active?: boolean;
id: number;
  constructor(radius, color, id) {
    this.radius = radius;
    this.color = color;
    this.id = id;
  }
}
