import {AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {RestService} from '../rest/rest.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.css']
})
export class InputComponent implements AfterViewInit {
  @Input() val: string;
  @Input() placeh: any;
  @Input() id: number;
  @Input() size: number;
  @Input() type: string;
  @Input() field: string;
  @Input() fields: object;
  @Output() ret = new EventEmitter<any>();
edit: boolean;
  @ViewChild('dt') someInput: ElementRef;
  private hi: number;
 ready: boolean;
  saving: boolean;


  constructor(private restService: RestService) { this.edit=false;this.saving=false;
  this.ready=false; }


  ngAfterViewInit() {

this.hi=(this.someInput.nativeElement.getElementsByTagName('input')[0].offsetHeight);
//console.log(this.someInput.nativeElement.getElementsByTagName('input')[0].offsetHeight);
  }

  ch(ev: Event) {
    this.val=this.someInput.nativeElement.getElementsByTagName('input')[0].value;
    if(this.val.length >3){this.ready=true;}
//this.val=ev.va
  }
getHi(){
return this.hi;
}
  foc(b: boolean) {
  if(!this.ready || b)  this.edit=b;
  }

  save() {
    this.saving=true;
    if(this.fields && !this.id){

      this.fields[this.field]=this.val;

      this.restService.addItem(this.type,this.fields).subscribe(a=>{
       this.ret.emit({add:a});

      });
    }
    else{
    let vo={};
    vo[this.field]=this.val;

this.restService.updateItem(this.type,this.id,vo).subscribe(a=>{
  this.ret.emit({update:a});

});
    }
  }
}
