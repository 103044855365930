<div mat-dialog-content>
<button mat-button mat-dialog-close *ngIf="!data.case">
    <ion-icon  class="lg" name="play-circle-outline"></ion-icon>
  </button>


  <div *ngIf="data.case === 'help'" class="mdl-dialog">


    sdfsdfsd

    <button mat-button  mat-dialog-close class="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ">

    </button>
  </div>

  <div *ngIf="data.case === 'mess'" class="mdl-dialog">


    <form action="#">
      <div class="mdl-textfield mdl-js-textfield">
        <input class="mdl-textfield__input" type="text" id="sample1">
        <label class="mdl-textfield__label" for="sample1">Text...</label>
      </div>
    </form>
    <button mat-button  mat-dialog-close class="mdl-button mdl-js-button mdl-button--fab mdl-button--mini-fab ">

    </button>
  </div>
</div>
