// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  ws: 'wss://newttry.herokuapp.com/:3000',
  bws: 'wss://facereplays23.herokuapp.com/:3000',
  server: '192.168.0.114:3100',
  apiUrl: 'https://buben-sha.herokuapp.com/api/records/',
  apiUrlS: 'https://buben-sha.herokuapp.com/api/',
  apiUrlOld: 'https://walls.memigration.info/api/records/',
  userPath: 'users/',
  usersPath: 'user',
  locationPath: 'locations',

  Client1: 1,
  secret1: '85RHbu8FZ0FcGaAdh55Han9d7TKdUeVbuFPIfcTU',
  Client: 2,
  secret: 'G2lwvCrND15LR4r0UemSt9jKAep0aA65aHjjkuaa',

  test: {
    bounds: null, story: 677, user: 264, start: null, parent: null, name: null, likes: null, dlikes: null, objet: [{
       sujet: 2, start: 1644937173191, bounds: null, life: 35798, transforms: [], strokes: [{

        color: 'RGB(204,19,80)',
        width: 20,
        start: 1644937206157,
        line: [{x: 722, y: 126, pressure: 0}, {x: 722, y: 126, pressure: 0.3137255012989044}, {
          x: 722,
          y: 126,
          pressure: 0.34117648005485535
        }, {x: 721.2000122070312, y: 123.20000457763672, pressure: 0.3607843220233917}, {
          x: 716,
          y: 120.80000305175781,
          pressure: 0.37254902720451355
        }, {x: 709.6000366210938, y: 118, pressure: 0.3764706254005432}, {
          x: 703.6000366210938,
          y: 119.20000457763672,
          pressure: 0.3764706254005432
        }, {x: 700.7999877929688, y: 121.20000457763672, pressure: 0.3764706254005432}, {
          x: 698,
          y: 125.20000457763672,
          pressure: 0.3803921937942505
        }, {x: 694.7999877929688, y: 130, pressure: 0.3803921937942505}, {
          x: 690.7999877929688,
          y: 137.1999969482422,
          pressure: 0.3803921937942505
        }, {x: 686.7999877929688, y: 144.8000030517578, pressure: 0.3803921937942505}, {
          x: 682.4000244140625,
          y: 154,
          pressure: 0.3803921937942505
        }, {x: 679.2000122070312, y: 162.40000915527344, pressure: 0.3803921937942505}, {
          x: 676.7999877929688,
          y: 170.40000915527344,
          pressure: 0.38823533058166504
        }, {x: 674.7999877929688, y: 178, pressure: 0.40000003576278687}, {
          x: 674,
          y: 185.1999969482422,
          pressure: 0.4078431725502014
        }, {x: 673.6000366210938, y: 192, pressure: 0.41960787773132324}, {
          x: 674.4000244140625,
          y: 198.40000915527344,
          pressure: 0.43137258291244507
        }, {x: 676, y: 204.8000030517578, pressure: 0.4431372880935669}, {
          x: 679.2000122070312,
          y: 210.40000915527344,
          pressure: 0.45098042488098145
        }, {x: 683.6000366210938, y: 216, pressure: 0.45098042488098145}, {
          x: 689.6000366210938,
          y: 220.8000030517578,
          pressure: 0.45098042488098145
        }, {x: 696.4000244140625, y: 224.40000915527344, pressure: 0.45098042488098145}, {
          x: 704.7999877929688,
          y: 227.60000610351562,
          pressure: 0.45098042488098145
        }, {x: 714.4000244140625, y: 229.1999969482422, pressure: 0.45098042488098145}, {
          x: 724.4000244140625,
          y: 229.60000610351562,
          pressure: 0.45098042488098145
        }, {x: 735.6000366210938, y: 228.8000030517578, pressure: 0.45098042488098145}, {
          x: 746.4000244140625,
          y: 225.60000610351562,
          pressure: 0.45098042488098145
        }, {x: 757.6000366210938, y: 220, pressure: 0.45098042488098145}, {
          x: 768,
          y: 212.8000030517578,
          pressure: 0.45098042488098145
        }, {x: 777.6000366210938, y: 204.8000030517578, pressure: 0.45098042488098145}, {
          x: 785.2000122070312,
          y: 196,
          pressure: 0.45098042488098145
        }, {x: 791.2000122070312, y: 187.1999969482422, pressure: 0.45098042488098145}, {
          x: 794.4000244140625,
          y: 178,
          pressure: 0.45098042488098145
        }, {x: 796, y: 169.60000610351562, pressure: 0.45098042488098145}, {
          x: 795.6000366210938,
          y: 162.8000030517578,
          pressure: 0.45098042488098145
        }, {x: 794, y: 156.40000915527344, pressure: 0.45098042488098145}, {
          x: 790.4000244140625,
          y: 151.1999969482422,
          pressure: 0.45098042488098145
        }, {x: 785.6000366210938, y: 146, pressure: 0.45098042488098145}, {
          x: 779.6000366210938,
          y: 142,
          pressure: 0.45098042488098145
        }, {x: 773.2000122070312, y: 138.8000030517578, pressure: 0.45098042488098145}, {
          x: 766,
          y: 136.8000030517578,
          pressure: 0.45098042488098145
        }, {x: 758, y: 135.60000610351562, pressure: 0.45098042488098145}, {
          x: 749.6000366210938,
          y: 134.8000030517578,
          pressure: 0.45098042488098145
        }, {x: 741.2000122070312, y: 134.40000915527344, pressure: 0.45098042488098145}, {
          x: 732.7999877929688,
          y: 135.1999969482422,
          pressure: 0.45098042488098145
        }, {x: 724, y: 137.1999969482422, pressure: 0.45098042488098145}, {
          x: 715.2000122070312,
          y: 140.40000915527344,
          pressure: 0.45098042488098145
        }, {x: 706.7999877929688, y: 144.8000030517578, pressure: 0.45098042488098145}, {
          x: 699.2000122070312,
          y: 150.8000030517578,
          pressure: 0.45098042488098145
        }, {x: 692.4000244140625, y: 156.8000030517578, pressure: 0.45098042488098145}, {
          x: 686.4000244140625,
          y: 164,
          pressure: 0.45098042488098145
        }, {x: 681.6000366210938, y: 171.60000610351562, pressure: 0.45098042488098145}, {
          x: 677.6000366210938,
          y: 179.1999969482422,
          pressure: 0.45098042488098145
        }, {x: 674.7999877929688, y: 187.1999969482422, pressure: 0.45098042488098145}, {
          x: 674,
          y: 194.40000915527344,
          pressure: 0.45098042488098145
        }, {x: 674, y: 200.8000030517578, pressure: 0.45098042488098145}, {
          x: 675.6000366210938,
          y: 206.40000915527344,
          pressure: 0.45098042488098145
        }, {x: 679.2000122070312, y: 211.60000610351562, pressure: 0.45098042488098145}, {
          x: 685.2000122070312,
          y: 215.1999969482422,
          pressure: 0.45098042488098145
        }, {x: 693.6000366210938, y: 218, pressure: 0.45098042488098145}, {
          x: 705.2000122070312,
          y: 219.1999969482422,
          pressure: 0.45098042488098145
        }, {x: 720.4000244140625, y: 218, pressure: 0.45098042488098145}, {x: 738.4000244140625, y: 214, pressure: 0.3490196168422699}],
        objet: 647,
        delta: 540,
        alpha: 0.10000000000000001
      }, {
         color: 'RGB(26,9,0)', width: 2, start: 1644937173191, line:
          [{x: 272.8000183105469, y: 322.8000183105469, pressure: 0}, {x: 255.20001220703125, y: 282, pressure: 0.3764706254005432}, {
            x: 258,
            y: 268,
            pressure: 0.3803921937942505
          }, {x: 263.6000061035156, y: 252, pressure: 0.3803921937942505}, {
            x: 272,
            y: 233.1999969482422,
            pressure: 0.3803921937942505
          }, {x: 282.8000183105469, y: 213.60000610351562, pressure: 0.3803921937942505}, {
            x: 295.6000061035156,
            y: 192,
            pressure: 0.3803921937942505
          }, {x: 310.8000183105469, y: 170.40000915527344, pressure: 0.3803921937942505}, {
            x: 328,
            y: 147.1999969482422,
            pressure: 0.3803921937942505
          }, {x: 346.8000183105469, y: 125.5999984741211, pressure: 0.3803921937942505}, {
            x: 365.20001220703125,
            y: 104.80000305175781,
            pressure: 0.3803921937942505
          }, {x: 383.6000061035156, y: 86.80000305175781, pressure: 0.3803921937942505}, {
            x: 401.6000061035156,
            y: 71.20000457763672,
            pressure: 0.3803921937942505
          }, {x: 418, y: 59.20000076293945, pressure: 0.3803921937942505}, {
            x: 433.20001220703125,
            y: 51.20000076293945,
            pressure: 0.3803921937942505
          }, {x: 446.8000183105469, y: 46, pressure: 0.3803921937942505}, {
            x: 458.4000244140625,
            y: 43.60000228881836,
            pressure: 0.3803921937942505
          }, {x: 468.4000244140625, y: 43.20000076293945, pressure: 0.3803921937942505}, {
            x: 476.4000244140625,
            y: 45.60000228881836,
            pressure: 0.3803921937942505
          }, {x: 482.79998779296875, y: 50.79999923706055, pressure: 0.3803921937942505}, {
            x: 486.4000244140625,
            y: 59.20000076293945,
            pressure: 0.38431376218795776
          }, {x: 488, y: 71.20000457763672, pressure: 0.3960784673690796}, {
            x: 486.79998779296875,
            y: 86,
            pressure: 0.4078431725502014
          }, {x: 482.4000244140625, y: 103.5999984741211, pressure: 0.41568630933761597}, {
            x: 476,
            y: 122.80000305175781,
            pressure: 0.41568630933761597
          }, {x: 468, y: 143.1999969482422, pressure: 0.41568630933761597}, {
            x: 459.60003662109375,
            y: 163.1999969482422,
            pressure: 0.41568630933761597
          }, {x: 450.8000183105469, y: 183.60000610351562, pressure: 0.41568630933761597}, {
            x: 441.6000061035156,
            y: 202.8000030517578,
            pressure: 0.41960787773132324
          }, {x: 433.20001220703125, y: 221.1999969482422, pressure: 0.41960787773132324}, {
            x: 425.6000061035156,
            y: 238.40000915527344,
            pressure: 0.41960787773132324
          }, {x: 419.6000061035156, y: 253.60000610351562, pressure: 0.41960787773132324}, {
            x: 415.20001220703125,
            y: 267.20001220703125,
            pressure: 0.41960787773132324
          }, {x: 412.3999938964844, y: 278.8000183105469, pressure: 0.41960787773132324}, {
            x: 411.20001220703125,
            y: 288,
            pressure: 0.41960787773132324
          }, {x: 411.6000061035156, y: 295.6000061035156, pressure: 0.41960787773132324}, {
            x: 413.6000061035156,
            y: 300.8000183105469,
            pressure: 0.41960787773132324
          }, {x: 417.6000061035156, y: 303.6000061035156, pressure: 0.41960787773132324}, {
            x: 424,
            y: 304.3999938964844,
            pressure: 0.41960787773132324
          }, {x: 434, y: 301.6000061035156, pressure: 0.41960787773132324}, {
            x: 447.6000061035156,
            y: 293.20001220703125,
            pressure: 0.41960787773132324
          }, {x: 464.4000244140625, y: 278.8000183105469, pressure: 0.41960787773132324}, {
            x: 483.60003662109375,
            y: 260.3999938964844,
            pressure: 0.41960787773132324
          }, {x: 503.60003662109375, y: 240, pressure: 0.41960787773132324}, {
            x: 523.2000122070312,
            y: 219.60000610351562,
            pressure: 0.41960787773132324
          }, {x: 541.6000366210938, y: 201.1999969482422, pressure: 0.41960787773132324}, {
            x: 558.7999877929688,
            y: 185.1999969482422,
            pressure: 0.41960787773132324
          }, {x: 574, y: 171.60000610351562, pressure: 0.41960787773132324}, {
            x: 587.2000122070312,
            y: 161.1999969482422,
            pressure: 0.41960787773132324
          }, {x: 598, y: 154, pressure: 0.41960787773132324}, {
            x: 606.7999877929688,
            y: 150.40000915527344,
            pressure: 0.41960787773132324
          }, {x: 613.2000122070312, y: 148.8000030517578, pressure: 0.41960787773132324}, {
            x: 618,
            y: 150.40000915527344,
            pressure: 0.41960787773132324
          }, {x: 621.2000122070312, y: 154.8000030517578, pressure: 0.41960787773132324}, {
            x: 622.7999877929688,
            y: 162.40000915527344,
            pressure: 0.41960787773132324
          }, {x: 621.6000366210938, y: 2.60000610351562, pressure: 0.41960787773132324}, {
            x: 617.6000366210938,
            y: 187.60000610351562,
            pressure: 0.4235294461250305
          }, {x: 610, y: 204.40000915527344, pressure: 0.4274510145187378}, {
            x: 600,
            y: 222.8000030517578,
            pressure: 0.4274510145187378
          }, {x: 589.6000366210938, y: 242.40000915527344, pressure: 0.4274510145187378}, {
            x: 578.7999877929688,
            y: 260.8000183105469,
            pressure: 0.4274510145187378
          }, {x: 569.6000366210938, y: 278.8000183105469, pressure: 0.4274510145187378}, {
            x: 561.6000366210938,
            y: 294,
            pressure: 0.4274510145187378
          }, {x: 555.6000366210938, y: 307.20001220703125, pressure: 0.4274510145187378}, {
            x: 552,
            y: 317.6000061035156,
            pressure: 0.4274510145187378
          }, {x: 550, y: 324.3999938964844, pressure: 0.4274510145187378}, {x: 550, y: 328.3999938964844, pressure: 0.4274510145187378}, {
            x: 552,
            y: 330,
            pressure: 0.4274510145187378
          }, {x: 556.4000244140625, y: 328.8000183105469, pressure: 0.4274510145187378}, {
            x: 565.2000122070312,
            y: 323.20001220703125,
            pressure: 0.4274510145187378
          }, {x: 580.7999877929688, y: 311.6000061035156, pressure: 0.4274510145187378}, {
            x: 602.4000244140625,
            y: 294.8000183105469,
            pressure: 0.4274510145187378
          }, {x: 628.4000244140625, y: 275.6000061035156, pressure: 0.4274510145187378}, {
            x: 655.6000366210938,
            y: 256.3999938964844,
            pressure: 0.3529411852359772
          }, {x: 683.6000366210938, y: 239.1999969482422, pressure: 0.24705883860588074}], objet:
          647, delta:
          633, alpha:
          0.90000000000000002
      },
        {

          color: 'RGB(26,9,0)',
          width: 2,
          start: 1644937183933,
          line: [{x: 598.7999877929688, y: 87.20000457763672, pressure: 0}, {
            x: 598.7999877929688,
            y: 87.20000457763672,
            pressure: 0.34117648005485535
          }, {x: 598, y: 84.80000305175781, pressure: 0.364705890417099}, {
            x: 592.4000244140625,
            y: 82.80000305175781,
            pressure: 0.3803921937942505
          }, {x: 585.6000366210938, y: 81.20000457763672, pressure: 0.38823533058166504}, {
            x: 578.4000244140625,
            y: 83.5999984741211,
            pressure: 0.40000003576278687
          }, {x: 573.2000122070312, y: 88, pressure: 0.4078431725502014}, {
            x: 566.7999877929688,
            y: 95.20000457763672,
            pressure: 0.41960787773132324
          }, {x: 559.2000122070312, y: 104.4000015258789, pressure: 0.41960787773132324}, {
            x: 550.7999877929688,
            y: 115.5999984741211,
            pressure: 0.41960787773132324
          }, {x: 541.6000366210938, y: 127.5999984741211, pressure: 0.41960787773132324}, {
            x: 532.7999877929688,
            y: 140.8000030517578,
            pressure: 0.41960787773132324
          }, {x: 524, y: 154.40000915527344, pressure: 0.41960787773132324}, {
            x: 515.6000366210938,
            y: 168,
            pressure: 0.41960787773132324
          }, {x: 508.4000244140625, y: 181.1999969482422, pressure: 0.41960787773132324}, {
            x: 502.4000244140625,
            y: 193.1999969482422,
            pressure: 0.4235294461250305
          }, {x: 498, y: 203.60000610351562, pressure: 0.43529415130615234}, {
            x: 495.60003662109375,
            y: 212.40000915527344,
            pressure: 0.4431372880935669
          }, {x: 494.79998779296875, y: 219.60000610351562, pressure: 0.44705885648727417}, {
            x: 495.60003662109375,
            y: 225.60000610351562,
            pressure: 0.44705885648727417
          }, {x: 497.60003662109375, y: 230.40000915527344, pressure: 0.44705885648727417}, {
            x: 502,
            y: 234.40000915527344,
            pressure: 0.44705885648727417
          }, {x: 508.4000244140625, y: 236.8000030517578, pressure: 0.44705885648727417}, {
            x: 516.7999877929688,
            y: 237.60000610351562,
            pressure: 0.44705885648727417
          }, {x: 528, y: 236, pressure: 0.44705885648727417}, {
            x: 542,
            y: 231.1999969482422,
            pressure: 0.44705885648727417
          }, {x: 558.7999877929688, y: 222.8000030517578, pressure: 0.44705885648727417}, {
            x: 578.4000244140625,
            y: 210.8000030517578,
            pressure: 0.44705885648727417
          }, {x: 600, y: 196, pressure: 0.43137258291244507}, {
            x: 622.7999877929688,
            y: 179.60000610351562,
            pressure: 0.4235294461250305
          }, {x: 645.2000122070312, y: 163.60000610351562, pressure: 0.4235294461250305}, {
            x: 664.4000244140625,
            y: 150.40000915527344,
            pressure: 0.4235294461250305
          }, {x: 678.7999877929688, y: 140, pressure: 0.4235294461250305}, {
            x: 688,
            y: 132.40000915527344,
            pressure: 0.4235294461250305
          }, {x: 694.4000244140625, y: 126.80000305175781, pressure: 0.4235294461250305}, {
            x: 698.4000244140625,
            y: 123.5999984741211,
            pressure: 0.4274510145187378
          }, {x: 700.7999877929688, y: 122, pressure: 0.4274510145187378}, {
            x: 701.6000366210938,
            y: 121.5999984741211,
            pressure: 0.43137258291244507
          }, {x: 702.4000244140625, y: 122.4000015258789, pressure: 0.4392157196998596}, {
            x: 702.4000244140625,
            y: 124.4000015258789,
            pressure: 0.44705885648727417
          }, {x: 701.2000122070312, y: 128.8000030517578, pressure: 0.44705885648727417}, {
            x: 698,
            y: 134.8000030517578,
            pressure: 0.44705885648727417
          }, {x: 691.2000122070312, y: 144.8000030517578, pressure: 0.44705885648727417}, {
            x: 682,
            y: 156.40000915527344,
            pressure: 0.44705885648727417
          }, {x: 671.2000122070312, y: 169.60000610351562, pressure: 0.44705885648727417}, {
            x: 661.2000122070312,
            y: 181.60000610351562,
            pressure: 0.44705885648727417
          }, {x: 652.4000244140625, y: 192.40000915527344, pressure: 0.44705885648727417}, {
            x: 644.7999877929688,
            y: 202.40000915527344,
            pressure: 0.44705885648727417
          }, {x: 638.4000244140625, y: 211.1999969482422, pressure: 0.4549019932746887}, {
            x: 632.7999877929688,
            y: 218.8000030517578,
            pressure: 0.458823561668396
          }, {x: 628.7999877929688, y: 225.60000610351562, pressure: 0.458823561668396}, {
            x: 625.6000366210938,
            y: 231.60000610351562,
            pressure: 0.458823561668396
          }, {x: 624, y: 236.8000030517578, pressure: 0.458823561668396}, {
            x: 623.6000366210938,
            y: 240.8000030517578,
            pressure: 0.458823561668396
          }, {x: 624, y: 243.60000610351562, pressure: 0.458823561668396}, {
            x: 625.6000366210938,
            y: 244.8000030517578,
            pressure: 0.458823561668396
          }, {x: 629.2000122070312, y: 245.1999969482422, pressure: 0.458823561668396}, {
            x: 636,
            y: 244,
            pressure: 0.458823561668396
          }, {x: 646.7999877929688, y: 239.60000610351562, pressure: 0.458823561668396}, {
            x: 661.6000366210938,
            y: 231.60000610351562,
            pressure: 0.458823561668396
          }, {x: 678.7999877929688, y: 221.1999969482422, pressure: 0.458823561668396}, {
            x: 696.4000244140625,
            y: 210.8000030517578,
            pressure: 0.458823561668396
          }, {x: 714.4000244140625, y: 200.40000915527344, pressure: 0.458823561668396}, {
            x: 730.7999877929688,
            y: 190.8000030517578,
            pressure: 0.458823561668396
          }, {x: 744.4000244140625, y: 183.1999969482422, pressure: 0.458823561668396}, {
            x: 753.6000366210938,
            y: 178.40000915527344,
            pressure: 0.458823561668396
          }, {x: 759.2000122070312, y: 176.40000915527344, pressure: 0.458823561668396}, {
            x: 761.6000366210938,
            y: 176.40000915527344,
            pressure: 0.458823561668396
          }, {x: 763.2000122070312, y: 177.60000610351562, pressure: 0.458823561668396}, {
            x: 764,
            y: 180.40000915527344,
            pressure: 0.458823561668396
          }, {x: 763.6000366210938, y: 185.1999969482422, pressure: 0.458823561668396}, {
            x: 760.7999877929688,
            y: 192.40000915527344,
            pressure: 0.458823561668396
          }, {x: 754.4000244140625, y: 202.40000915527344, pressure: 0.458823561668396}, {
            x: 745.2000122070312,
            y: 214.8000030517578,
            pressure: 0.458823561668396
          }, {x: 734.4000244140625, y: 228, pressure: 0.458823561668396}, {
            x: 724,
            y: 241.1999969482422,
            pressure: 0.458823561668396
          }, {x: 714.7999877929688, y: 253.60000610351562, pressure: 0.458823561668396}, {
            x: 707.6000366210938,
            y: 264.8000183105469,
            pressure: 0.458823561668396
          }, {x: 703.2000122070312, y: 274, pressure: 0.458823561668396}, {
            x: 700.4000244140625,
            y: 280.8000183105469,
            pressure: 0.458823561668396
          }, {x: 700, y: 285.20001220703125, pressure: 0.458823561668396}, {
            x: 700.4000244140625,
            y: 288.3999938964844,
            pressure: 0.458823561668396
          }, {x: 702, y: 290.3999938964844, pressure: 0.458823561668396}, {
            x: 706,
            y: 291.20001220703125,
            pressure: 0.458823561668396
          }, {x: 712.4000244140625, y: 290.8000183105469, pressure: 0.458823561668396}, {
            x: 723.6000366210938,
            y: 287.6000061035156,
            pressure: 0.458823561668396
          }, {x: 737.2000122070312, y: 282, pressure: 0.458823561668396}, {
            x: 752.4000244140625,
            y: 274.8000183105469,
            pressure: 0.458823561668396
          }, {x: 766.4000244140625, y: 268.8000183105469, pressure: 0.458823561668396}, {
            x: 778,
            y: 264.8000183105469,
            pressure: 0.458823561668396
          }, {x: 786.7999877929688, y: 262.3999938964844, pressure: 0.458823561668396}, {
            x: 792.4000244140625,
            y: 262,
            pressure: 0.458823561668396
          }, {x: 795.6000366210938, y: 263.20001220703125, pressure: 0.458823561668396}, {
            x: 796.7999877929688,
            y: 265.6000061035156,
            pressure: 0.458823561668396
          }, {x: 797.6000366210938, y: 269.6000061035156, pressure: 0.458823561668396}, {
            x: 797.2000122070312,
            y: 274.8000183105469,
            pressure: 0.458823561668396
          }, {x: 796, y: 281.20001220703125, pressure: 0.458823561668396}, {
            x: 794,
            y: 288.3999938964844,
            pressure: 0.458823561668396
          }, {x: 792, y: 295.6000061035156, pressure: 0.458823561668396}, {
            x: 790.7999877929688,
            y: 302,
            pressure: 0.458823561668396
          }, {x: 790.4000244140625, y: 308.3999938964844, pressure: 0.458823561668396}, {
            x: 790.7999877929688,
            y: 313.6000061035156,
            pressure: 0.4549019932746887
          }, {x: 792.4000244140625, y: 318.3999938964844, pressure: 0.40000003576278687}, {
            x: 796.4000244140625,
            y: 321.6000061035156,
            pressure: 0.2823529541492462
          }],
          objet: 647,
          delta: 866,
          alpha: 0.90000000000000002
        }, {
           color: ' RGB(77, 19, 80)',
          width: 20, start: 1644937208132, line: [{x: 587.6000366210938, y: 123.20000457763672, pressure: 0}, {
            x: 587.6000366210938,
            y: 123.20000457763672,
            pressure: 0.3176470696926117
          }, {x: 587.2000122070312, y: 121.5999984741211, pressure: 0.3490196168422699}, {
            x: 582,
            y: 120.4000015258789,
            pressure: 0.3686274588108063
          }, {x: 574.7999877929688, y: 119.20000457763672, pressure: 0.38431376218795776}, {
            x: 566.7999877929688,
            y: 120.80000305175781,
            pressure: 0.3921568989753723
          }, {x: 562, y: 123.5999984741211, pressure: 0.40392160415649414}, {
            x: 557.2000122070312,
            y: 128,
            pressure: 0.40392160415649414
          }, {x: 551.6000366210938, y: 134, pressure: 0.40392160415649414}, {
            x: 546,
            y: 140.8000030517578,
            pressure: 0.40392160415649414
          }, {x: 540.4000244140625, y: 148.8000030517578, pressure: 0.40392160415649414}, {
            x: 534.4000244140625,
            y: 157.60000610351562,
            pressure: 0.4078431725502014
          }, {x: 528.7999877929688, y: 166.8000030517578, pressure: 0.4117647409439087}, {
            x: 524,
            y: 176.40000915527344,
            pressure: 0.4274510145187378
          }, {x: 519.6000366210938, y: 186, pressure: 0.43529415130615234}, {
            x: 516,
            y: 195.60000610351562,
            pressure: 0.45098042488098145
          }, {x: 513.6000366210938, y: 204.8000030517578, pressure: 0.458823561668396}, {
            x: 512,
            y: 214,
            pressure: 0.4705882668495178
          }, {x: 511.60003662109375, y: 222.8000030517578, pressure: 0.4745098352432251}, {
            x: 511.60003662109375,
            y: 231.60000610351562,
            pressure: 0.4745098352432251
          }, {x: 512, y: 239.60000610351562, pressure: 0.4745098352432251}, {
            x: 514,
            y: 247.1999969482422,
            pressure: 0.4745098352432251
          }, {x: 516.7999877929688, y: 254.40000915527344, pressure: 0.4745098352432251}, {
            x: 520.7999877929688,
            y: 260.3999938964844,
            pressure: 0.4745098352432251
          }, {x: 525.2000122070312, y: 265.6000061035156, pressure: 0.4745098352432251}, {
            x: 530.7999877929688,
            y: 269.20001220703125,
            pressure: 0.4745098352432251
          }, {x: 537.2000122070312, y: 271.20001220703125, pressure: 0.4745098352432251}, {
            x: 544.7999877929688,
            y: 271.6000061035156,
            pressure: 0.4745098352432251
          }, {x: 553.2000122070312, y: 270, pressure: 0.4745098352432251}, {x: 562, y: 266, pressure: 0.4745098352432251}, {
            x: 571.2000122070312,
            y: 259.20001220703125,
            pressure: 0.4745098352432251
          }, {x: 580.4000244140625, y: 250.8000030517578, pressure: 0.4745098352432251}, {
            x: 588.7999877929688,
            y: 241.1999969482422,
            pressure: 0.4745098352432251
          }, {x: 595.6000366210938, y: 231.1999969482422, pressure: 0.4745098352432251}, {
            x: 600.7999877929688,
            y: 222,
            pressure: 0.4745098352432251
          }, {x: 604.4000244140625, y: 214, pressure: 0.4745098352432251}, {
            x: 606.7999877929688,
            y: 206.8000030517578,
            pressure: 0.4745098352432251
          }, {x: 608.4000244140625, y: 201.1999969482422, pressure: 0.4745098352432251}, {
            x: 608.7999877929688,
            y: 196,
            pressure: 0.4745098352432251
          }, {x: 608.7999877929688, y: 191.60000610351562, pressure: 0.4745098352432251}, {
            x: 608.4000244140625,
            y: 188,
            pressure: 0.4745098352432251
          }, {x: 607.6000366210938, y: 185.1999969482422, pressure: 0.4745098352432251}, {
            x: 606.7999877929688,
            y: 182.8000030517578,
            pressure: 0.4745098352432251
          }, {x: 605.2000122070312, y: 180.8000030517578, pressure: 0.4745098352432251}, {
            x: 603.2000122070312,
            y: 178.8000030517578,
            pressure: 0.4745098352432251
          }, {x: 601.2000122070312, y: 177.1999969482422, pressure: 0.4745098352432251}, {
            x: 598.7999877929688,
            y: 176.40000915527344,
            pressure: 0.4745098352432251
          }, {x: 596, y: 176, pressure: 0.4745098352432251}, {x: 592.4000244140625, y: 176, pressure: 0.4745098352432251}, {
            x: 587.6000366210938,
            y: 177.60000610351562,
            pressure: 0.4745098352432251
          }, {x: 581.2000122070312, y: 181.1999969482422, pressure: 0.4745098352432251}, {
            x: 573.2000122070312,
            y: 187.60000610351562,
            pressure: 0.4745098352432251
          }, {x: 564.4000244140625, y: 195.60000610351562, pressure: 0.4745098352432251}, {
            x: 555.6000366210938,
            y: 204.8000030517578,
            pressure: 0.4745098352432251
          }, {x: 548, y: 214, pressure: 0.4745098352432251}, {
            x: 540.4000244140625,
            y: 223.60000610351562,
            pressure: 0.4745098352432251
          }, {x: 533.6000366210938, y: 233.60000610351562, pressure: 0.4745098352432251}, {
            x: 527.6000366210938,
            y: 244,
            pressure: 0.4745098352432251
          }, {x: 522.4000244140625, y: 253.60000610351562, pressure: 0.4745098352432251}, {
            x: 518.7999877929688,
            y: 262.8000183105469,
            pressure: 0.4745098352432251
          }, {x: 516, y: 271.20001220703125, pressure: 0.4745098352432251}, {x: 514.7999877929688, y: 278, pressure: 0.4745098352432251}, {
            x: 514,
            y: 283.6000061035156,
            pressure: 0.4745098352432251
          }, {x: 514, y: 288.3999938964844, pressure: 0.4745098352432251}, {
            x: 514.7999877929688,
            y: 292.3999938964844,
            pressure: 0.4745098352432251
          }, {x: 516.4000244140625, y: 295.6000061035156, pressure: 0.4745098352432251}, {
            x: 518.7999877929688,
            y: 298,
            pressure: 0.4745098352432251
          }, {x: 522, y: 299.20001220703125, pressure: 0.4745098352432251}, {x: 526.4000244140625, y: 300, pressure: 0.4745098352432251}, {
            x: 532,
            y: 300,
            pressure: 0.4745098352432251
          }, {x: 540, y: 298, pressure: 0.4745098352432251}, {x: 548.7999877929688, y: 294, pressure: 0.4745098352432251}, {
            x: 559.2000122070312,
            y: 287.6000061035156,
            pressure: 0.4745098352432251
          }, {x: 569.6000366210938, y: 279.20001220703125, pressure: 0.4745098352432251}, {
            x: 580.4000244140625,
            y: 269.20001220703125,
            pressure: 0.4745098352432251
          }, {x: 592, y: 257.6000061035156, pressure: 0.4745098352432251}, {
            x: 602.7999877929688,
            y: 244.8000030517578,
            pressure: 0.4745098352432251
          }, {x: 612.7999877929688, y: 231.60000610351562, pressure: 0.4745098352432251}, {
            x: 621.2000122070312,
            y: 220,
            pressure: 0.4745098352432251
          }, {x: 626.4000244140625, y: 212, pressure: 0.4745098352432251}, {
            x: 629.2000122070312,
            y: 207.1999969482422,
            pressure: 0.4745098352432251
          }, {x: 629.6000366210938, y: 204.40000915527344, pressure: 0.4745098352432251}, {
            x: 629.2000122070312,
            y: 203.1999969482422,
            pressure: 0.4745098352432251
          }, {x: 627.6000366210938, y: 202.40000915527344, pressure: 0.4745098352432251}, {
            x: 625.6000366210938,
            y: 202.40000915527344,
            pressure: 0.4745098352432251
          }, {x: 622.4000244140625, y: 202.8000030517578, pressure: 0.4745098352432251}, {
            x: 617.6000366210938,
            y: 205.1999969482422,
            pressure: 0.4745098352432251
          }, {x: 610.7999877929688, y: 209.60000610351562, pressure: 0.4745098352432251}, {
            x: 601.2000122070312,
            y: 217.1999969482422,
            pressure: 0.4745098352432251
          }, {x: 590.4000244140625, y: 227.1999969482422, pressure: 0.4745098352432251}, {
            x: 579.2000122070312,
            y: 238.8000030517578,
            pressure: 0.4745098352432251
          }, {x: 569.2000122070312, y: 250.40000915527344, pressure: 0.4745098352432251}, {
            x: 560.4000244140625,
            y: 262.8000183105469,
            pressure: 0.4745098352432251
          }, {x: 552.7999877929688, y: 274.3999938964844, pressure: 0.4745098352432251}, {
            x: 546,
            y: 285.20001220703125,
            pressure: 0.4745098352432251
          }, {x: 540.4000244140625, y: 296, pressure: 0.4745098352432251}, {
            x: 536.7999877929688,
            y: 306,
            pressure: 0.4745098352432251
          }, {x: 534.4000244140625, y: 314.8000183105469, pressure: 0.4745098352432251}, {
            x: 533.2000122070312,
            y: 322,
            pressure: 0.4745098352432251
          }, {x: 534, y: 328.8000183105469, pressure: 0.4745098352432251}, {
            x: 537.2000122070312,
            y: 333.6000061035156,
            pressure: 0.4745098352432251
          }, {x: 543.2000122070312, y: 336.8000183105469, pressure: 0.43137258291244507}, {
            x: 551.6000366210938,
            y: 338,
            pressure: 0.30588236451148987
          }], objet:
            647, delta: 857, alpha: 0.10000000000000001
        }
      ]
    },
      {
         sujet: 2, start: 1644937210536, bounds: null, life: 13927, transforms: [], strokes:
          [{

            color: 'RGB(26,9,0)',
            width: 2,
            start: 1644937217584,
            line: [{x: 461.20001220703125, y: 133.1999969482422, pressure: 0}, {
              x: 461.20001220703125,
              y: 133.1999969482422,
              pressure: 0.3137255012989044
            }, {x: 461.20001220703125, y: 133.1999969482422, pressure: 0.3450980484485626}, {
              x: 460.79998779296875,
              y: 132,
              pressure: 0.3686274588108063
            }, {x: 456.79998779296875, y: 130.40000915527344, pressure: 0.38431376218795776}, {
              x: 452.4000244140625,
              y: 128.8000030517578,
              pressure: 0.3921568989753723
            }, {x: 448.3999938964844, y: 128.40000915527344, pressure: 0.40000003576278687}, {
              x: 449.20001220703125,
              y: 128.40000915527344,
              pressure: 0.40000003576278687
            }, {x: 451.20001220703125, y: 128.40000915527344, pressure: 0.40000003576278687}, {
              x: 454.4000244140625,
              y: 128.40000915527344,
              pressure: 0.40000003576278687
            }, {x: 458.79998779296875, y: 128.40000915527344, pressure: 0.40000003576278687}, {
              x: 464.4000244140625,
              y: 128.40000915527344,
              pressure: 0.40000003576278687
            }, {x: 471.20001220703125, y: 128.40000915527344, pressure: 0.4078431725502014}, {
              x: 479.60003662109375,
              y: 128,
              pressure: 0.4078431725502014
            }, {x: 488.79998779296875, y: 128, pressure: 0.4078431725502014}, {
              x: 498,
              y: 127.20000457763672,
              pressure: 0.4078431725502014
            }, {x: 508, y: 126.80000305175781, pressure: 0.4078431725502014}, {
              x: 518.7999877929688,
              y: 126,
              pressure: 0.4078431725502014
            }, {x: 529.6000366210938, y: 124.80000305175781, pressure: 0.4078431725502014}, {
              x: 541.2000122070312,
              y: 123.5999984741211,
              pressure: 0.4078431725502014
            }, {x: 553.2000122070312, y: 122.4000015258789, pressure: 0.4078431725502014}, {
              x: 565.2000122070312,
              y: 120.80000305175781,
              pressure: 0.4078431725502014
            }, {x: 577.6000366210938, y: 119.20000457763672, pressure: 0.4078431725502014}, {
              x: 590,
              y: 117.20000457763672,
              pressure: 0.4078431725502014
            }, {x: 602, y: 115.20000457763672, pressure: 0.4078431725502014}, {
              x: 613.6000366210938,
              y: 112.80000305175781,
              pressure: 0.4078431725502014
            }, {x: 624.7999877929688, y: 110.80000305175781, pressure: 0.4078431725502014}, {
              x: 635.2000122070312,
              y: 108.80000305175781,
              pressure: 0.4117647409439087
            }, {x: 644.7999877929688, y: 107.5999984741211, pressure: 0.4117647409439087}, {
              x: 652.7999877929688,
              y: 106.80000305175781,
              pressure: 0.4117647409439087
            }, {x: 660, y: 106.4000015258789, pressure: 0.4117647409439087}, {
              x: 665.6000366210938,
              y: 106.4000015258789,
              pressure: 0.4117647409439087
            }, {x: 670, y: 106.4000015258789, pressure: 0.4117647409439087}, {
              x: 673.6000366210938,
              y: 106.80000305175781,
              pressure: 0.41568630933761597
            }, {x: 676, y: 108, pressure: 0.4235294461250305}, {
              x: 677.6000366210938,
              y: 109.20000457763672,
              pressure: 0.4274510145187378
            }, {x: 678.7999877929688, y: 111.20000457763672, pressure: 0.4274510145187378}, {
              x: 679.2000122070312,
              y: 113.20000457763672,
              pressure: 0.4274510145187378
            }, {x: 679.6000366210938, y: 115.5999984741211, pressure: 0.4274510145187378}, {
              x: 680,
              y: 118,
              pressure: 0.43137258291244507
            }, {x: 680, y: 120.80000305175781, pressure: 0.4392157196998596}, {
              x: 679.6000366210938,
              y: 124,
              pressure: 0.4431372880935669
            }, {x: 679.2000122070312, y: 127.5999984741211, pressure: 0.4431372880935669}, {
              x: 678.7999877929688,
              y: 131.60000610351562,
              pressure: 0.4431372880935669
            }, {x: 678, y: 136, pressure: 0.4431372880935669}, {
              x: 677.6000366210938,
              y: 141.1999969482422,
              pressure: 0.4431372880935669
            }, {x: 677.2000122070312, y: 146.8000030517578, pressure: 0.4431372880935669}, {
              x: 676.7999877929688,
              y: 153.1999969482422,
              pressure: 0.4431372880935669
            }, {x: 676.4000244140625, y: 160, pressure: 0.4431372880935669}, {
              x: 676,
              y: 166.40000915527344,
              pressure: 0.4431372880935669
            }, {x: 676.4000244140625, y: 2.1999969482422, pressure: 0.4431372880935669}, {
              x: 676.4000244140625,
              y: 180.40000915527344,
              pressure: 0.4431372880935669
            }, {x: 677.2000122070312, y: 187.1999969482422, pressure: 0.4431372880935669}, {
              x: 677.6000366210938,
              y: 194.40000915527344,
              pressure: 0.4431372880935669
            }, {x: 678.7999877929688, y: 201.60000610351562, pressure: 0.4431372880935669}, {
              x: 680,
              y: 208.40000915527344,
              pressure: 0.44705885648727417
            }, {x: 681.6000366210938, y: 215.1999969482422, pressure: 0.4549019932746887}, {
              x: 682.7999877929688,
              y: 221.60000610351562,
              pressure: 0.4549019932746887
            }, {x: 684.4000244140625, y: 227.60000610351562, pressure: 0.4549019932746887}, {
              x: 685.6000366210938,
              y: 232.8000030517578,
              pressure: 0.4549019932746887
            }, {x: 687.2000122070312, y: 238, pressure: 0.4549019932746887}, {
              x: 688.4000244140625,
              y: 242.40000915527344,
              pressure: 0.4549019932746887
            }, {x: 689.2000122070312, y: 245.60000610351562, pressure: 0.4549019932746887}, {
              x: 690,
              y: 248,
              pressure: 0.4549019932746887
            }, {x: 690.7999877929688, y: 250, pressure: 0.4549019932746887}, {
              x: 691.2000122070312,
              y: 251.1999969482422,
              pressure: 0.4549019932746887
            }, {x: 691.6000366210938, y: 252, pressure: 0.4549019932746887}, {
              x: 691.6000366210938,
              y: 252.40000915527344,
              pressure: 0.458823561668396
            }, {x: 690.7999877929688, y: 252.40000915527344, pressure: 0.458823561668396}, {
              x: 689.2000122070312,
              y: 252.40000915527344,
              pressure: 0.458823561668396
            }, {x: 687.2000122070312, y: 252, pressure: 0.458823561668396}, {
              x: 684.7999877929688,
              y: 251.60000610351562,
              pressure: 0.458823561668396
            }, {x: 682, y: 250.8000030517578, pressure: 0.458823561668396}, {
              x: 678.4000244140625,
              y: 250.40000915527344,
              pressure: 0.458823561668396
            }, {x: 674.4000244140625, y: 249.60000610351562, pressure: 0.458823561668396}, {
              x: 669.6000366210938,
              y: 249.1999969482422,
              pressure: 0.458823561668396
            }, {x: 664, y: 249.1999969482422, pressure: 0.458823561668396}, {
              x: 656.4000244140625,
              y: 249.1999969482422,
              pressure: 0.458823561668396
            }, {x: 647.6000366210938, y: 249.60000610351562, pressure: 0.458823561668396}, {
              x: 637.6000366210938,
              y: 250.8000030517578,
              pressure: 0.458823561668396
            }, {x: 628, y: 252.40000915527344, pressure: 0.458823561668396}, {
              x: 617.2000122070312,
              y: 254.40000915527344,
              pressure: 0.458823561668396
            }, {x: 606.4000244140625, y: 256, pressure: 0.458823561668396}, {
              x: 595.2000122070312,
              y: 257.6000061035156,
              pressure: 0.458823561668396
            }, {x: 584, y: 259.6000061035156, pressure: 0.458823561668396}, {
              x: 572.4000244140625,
              y: 261.20001220703125,
              pressure: 0.458823561668396
            }, {x: 561.2000122070312, y: 263.20001220703125, pressure: 0.458823561668396}, {
              x: 550,
              y: 265.20001220703125,
              pressure: 0.458823561668396
            }, {x: 539.2000122070312, y: 267.20001220703125, pressure: 0.458823561668396}, {
              x: 528.4000244140625,
              y: 269.6000061035156,
              pressure: 0.458823561668396
            }, {x: 518, y: 272.3999938964844, pressure: 0.458823561668396}, {
              x: 507.60003662109375,
              y: 274.8000183105469,
              pressure: 0.458823561668396
            }, {x: 497.60003662109375, y: 277.6000061035156, pressure: 0.458823561668396}, {
              x: 488.4000244140625,
              y: 280,
              pressure: 0.458823561668396
            }, {x: 479.60003662109375, y: 282, pressure: 0.458823561668396}, {
              x: 471.60003662109375,
              y: 284,
              pressure: 0.458823561668396
            }, {x: 464, y: 285.6000061035156, pressure: 0.458823561668396}, {
              x: 457.20001220703125,
              y: 286.8000183105469,
              pressure: 0.458823561668396
            }, {x: 451.20001220703125, y: 287.6000061035156, pressure: 0.458823561668396}, {
              x: 446,
              y: 287.6000061035156,
              pressure: 0.458823561668396
            }, {x: 441.20001220703125, y: 287.6000061035156, pressure: 0.458823561668396}, {
              x: 437.20001220703125,
              y: 287.6000061035156,
              pressure: 0.458823561668396
            }, {x: 434.3999938964844, y: 286.8000183105469, pressure: 0.458823561668396}, {
              x: 432,
              y: 286,
              pressure: 0.458823561668396
            }, {x: 430.3999938964844, y: 284.8000183105469, pressure: 0.458823561668396}, {
              x: 428.8000183105469,
              y: 283.6000061035156,
              pressure: 0.458823561668396
            }, {x: 428, y: 282, pressure: 0.458823561668396}, {
              x: 427.6000061035156,
              y: 280,
              pressure: 0.458823561668396
            }, {x: 427.20001220703125, y: 278, pressure: 0.458823561668396}, {
              x: 427.20001220703125,
              y: 275.20001220703125,
              pressure: 0.458823561668396
            }, {x: 427.20001220703125, y: 272.3999938964844, pressure: 0.458823561668396}, {
              x: 427.6000061035156,
              y: 268.8000183105469,
              pressure: 0.458823561668396
            }, {x: 428.8000183105469, y: 264, pressure: 0.458823561668396}, {
              x: 430,
              y: 258.8000183105469,
              pressure: 0.458823561668396
            }, {x: 431.6000061035156, y: 252, pressure: 0.458823561668396}, {
              x: 433.20001220703125,
              y: 244.40000915527344,
              pressure: 0.458823561668396
            }, {x: 434.3999938964844, y: 236.40000915527344, pressure: 0.458823561668396}, {
              x: 435.20001220703125,
              y: 228.8000030517578,
              pressure: 0.458823561668396
            }, {x: 435.6000061035156, y: 220.8000030517578, pressure: 0.458823561668396}, {
              x: 436.3999938964844,
              y: 213.1999969482422,
              pressure: 0.458823561668396
            }, {x: 436.3999938964844, y: 205.60000610351562, pressure: 0.458823561668396}, {
              x: 436.3999938964844,
              y: 198.40000915527344,
              pressure: 0.458823561668396
            }, {x: 436, y: 190.8000030517578, pressure: 0.458823561668396}, {
              x: 435.20001220703125,
              y: 183.1999969482422,
              pressure: 0.458823561668396
            }, {x: 434.8000183105469, y: 176, pressure: 0.458823561668396}, {
              x: 434,
              y: 168.40000915527344,
              pressure: 0.458823561668396
            }, {x: 432.8000183105469, y: 161.60000610351562, pressure: 0.458823561668396}, {
              x: 431.6000061035156,
              y: 155.1999969482422,
              pressure: 0.458823561668396
            }, {x: 430, y: 148.8000030517578, pressure: 0.458823561668396}, {
              x: 429.20001220703125,
              y: 142.8000030517578,
              pressure: 0.458823561668396
            }, {x: 428, y: 136.8000030517578, pressure: 0.458823561668396}, {
              x: 426.8000183105469,
              y: 131.1999969482422,
              pressure: 0.458823561668396
            }, {x: 426, y: 126, pressure: 0.458823561668396}, {
              x: 425.20001220703125,
              y: 121.5999984741211,
              pressure: 0.458823561668396
            }, {x: 424.8000183105469, y: 118, pressure: 0.458823561668396}, {
              x: 424.3999938964844,
              y: 115.20000457763672,
              pressure: 0.458823561668396
            }, {x: 424, y: 112.80000305175781, pressure: 0.458823561668396}, {
              x: 424,
              y: 111.20000457763672,
              pressure: 0.458823561668396
            }, {x: 424.3999938964844, y: 110, pressure: 0.458823561668396}, {
              x: 425.20001220703125,
              y: 109.20000457763672,
              pressure: 0.458823561668396
            }, {x: 426.3999938964844, y: 108.4000015258789, pressure: 0.458823561668396}, {
              x: 428.3999938964844,
              y: 108.4000015258789,
              pressure: 0.458823561668396
            }, {x: 431.20001220703125, y: 108.80000305175781, pressure: 0.458823561668396}, {
              x: 434.3999938964844,
              y: 109.5999984741211,
              pressure: 0.458823561668396
            }, {x: 438, y: 111.20000457763672, pressure: 0.458823561668396}, {
              x: 442,
              y: 112.80000305175781,
              pressure: 0.458823561668396
            }, {x: 445.6000061035156, y: 114.4000015258789, pressure: 0.458823561668396}, {
              x: 449.6000061035156,
              y: 116,
              pressure: 0.458823561668396
            }, {x: 453.60003662109375, y: 117.20000457763672, pressure: 0.458823561668396}, {
              x: 457.60003662109375,
              y: 118,
              pressure: 0.458823561668396
            }, {x: 461.60003662109375, y: 118.4000015258789, pressure: 0.458823561668396}, {
              x: 466,
              y: 118.4000015258789,
              pressure: 0.458823561668396
            }, {x: 470.4000244140625, y: 118.4000015258789, pressure: 0.458823561668396}, {
              x: 475.20001220703125,
              y: 118,
              pressure: 0.458823561668396
            }, {x: 479.60003662109375, y: 117.20000457763672, pressure: 0.458823561668396}, {
              x: 485.20001220703125,
              y: 116,
              pressure: 0.458823561668396
            }, {x: 490.4000244140625, y: 114.80000305175781, pressure: 0.458823561668396}, {
              x: 496,
              y: 114,
              pressure: 0.458823561668396
            }, {x: 501.20001220703125, y: 113.20000457763672, pressure: 0.458823561668396}, {
              x: 506.4000244140625,
              y: 112.80000305175781,
              pressure: 0.458823561668396
            }, {x: 511.60003662109375, y: 112.4000015258789, pressure: 0.458823561668396}, {
              x: 516.7999877929688,
              y: 112,
              pressure: 0.458823561668396
            }, {x: 521.6000366210938, y: 112, pressure: 0.458823561668396}, {
              x: 526.4000244140625,
              y: 112.4000015258789,
              pressure: 0.458823561668396
            }, {x: 531.2000122070312, y: 113.20000457763672, pressure: 0.43529415130615234}, {
              x: 536,
              y: 114.4000015258789,
              pressure: 0.30588236451148987
            }],
            objet: 648,
            delta: 1396,
            alpha: 0.90000000000000002
          },
            {
               color: 'RGB(102,116,95)',
              width: 20, start: 1644937223685, line:
                [{x: 791.6000366210938, y: 202, pressure: 0}, {x: 791.6000366210938, y: 202, pressure: 0.3607843220233917}, {
                  x: 791.2000122070312,
                  y: 202,
                  pressure: 0.40000003576278687
                }, {x: 786, y: 202, pressure: 0.41960787773132324}, {
                  x: 779.6000366210938,
                  y: 202.40000915527344,
                  pressure: 0.4235294461250305
                }, {x: 772.4000244140625, y: 202.8000030517578, pressure: 0.4274510145187378}, {
                  x: 768,
                  y: 204.40000915527344,
                  pressure: 0.4274510145187378
                }, {x: 762.4000244140625, y: 207.1999969482422, pressure: 0.4274510145187378}, {
                  x: 755.6000366210938,
                  y: 211.1999969482422,
                  pressure: 0.4274510145187378
                }, {x: 748, y: 216, pressure: 0.4274510145187378}, {x: 738.7999877929688, y: 222, pressure: 0.4274510145187378}, {
                  x: 729.2000122070312,
                  y: 228,
                  pressure: 0.4274510145187378
                }, {x: 719.2000122070312, y: 234.40000915527344, pressure: 0.4274510145187378}, {
                  x: 709.2000122070312,
                  y: 240.8000030517578,
                  pressure: 0.4274510145187378
                }, {x: 699.2000122070312, y: 247.1999969482422, pressure: 0.4274510145187378}, {
                  x: 690,
                  y: 253.60000610351562,
                  pressure: 0.4274510145187378
                }, {x: 681.2000122070312, y: 259.6000061035156, pressure: 0.4274510145187378}, {
                  x: 672.7999877929688,
                  y: 265.6000061035156,
                  pressure: 0.4274510145187378
                }, {x: 665.2000122070312, y: 271.6000061035156, pressure: 0.4274510145187378}, {
                  x: 658,
                  y: 278,
                  pressure: 0.4274510145187378
                }, {
                  x: 652,
                  y: 283.6000061035156,
                  pressure: 0.43137258291244507
                }, {x: 647.2000122070312, y: 288.8000183105469, pressure: 0.43137258291244507}, {
                  x: 643.2000122070312,
                  y: 293.6000061035156,
                  pressure: 0.43137258291244507
                }, {x: 640, y: 298, pressure: 0.43529415130615234}, {x: 638, y: 302.3999938964844, pressure: 0.43529415130615234}, {
                  x: 636.4000244140625,
                  y: 306,
                  pressure: 0.4392157196998596
                }, {x: 635.6000366210938, y: 309.6000061035156, pressure: 0.4392157196998596}, {
                  x: 635.2000122070312,
                  y: 313.20001220703125,
                  pressure: 0.4392157196998596
                }, {x: 634.7999877929688, y: 316.3999938964844, pressure: 0.4392157196998596}, {
                  x: 634.7999877929688,
                  y: 320,
                  pressure: 0.4431372880935669
                }, {x: 635.2000122070312, y: 324, pressure: 0.4431372880935669}, {
                  x: 636.4000244140625,
                  y: 327.6000061035156,
                  pressure: 0.4431372880935669
                }, {x: 637.6000366210938, y: 331.6000061035156, pressure: 0.4431372880935669}, {
                  x: 639.6000366210938,
                  y: 334.8000183105469,
                  pressure: 0.4431372880935669
                }, {x: 642, y: 338.3999938964844, pressure: 0.4431372880935669}, {
                  x: 644.7999877929688,
                  y: 341.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 648.4000244140625, y: 344.3999938964844, pressure: 0.4431372880935669}, {
                  x: 652.4000244140625,
                  y: 347.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 657.2000122070312, y: 350, pressure: 0.4431372880935669}, {
                  x: 663.2000122070312,
                  y: 352,
                  pressure: 0.4431372880935669
                }, {x: 669.2000122070312, y: 354, pressure: 0.4431372880935669}, {
                  x: 675.6000366210938,
                  y: 355.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 682.4000244140625, y: 355.6000061035156, pressure: 0.4431372880935669}, {
                  x: 689.6000366210938,
                  y: 356,
                  pressure: 0.4431372880935669
                }, {x: 696.7999877929688, y: 356.3999938964844, pressure: 0.4431372880935669}, {
                  x: 704.7999877929688,
                  y: 356.3999938964844,
                  pressure: 0.4431372880935669
                }, {x: 712.7999877929688, y: 355.6000061035156, pressure: 0.4431372880935669}, {
                  x: 721.2000122070312,
                  y: 354.3999938964844,
                  pressure: 0.4431372880935669
                }, {x: 729.6000366210938, y: 352.3999938964844, pressure: 0.4431372880935669}, {
                  x: 737.6000366210938,
                  y: 349.6000061035156,
                  pressure: 0.4431372880935669
                }, {x: 746, y: 346.3999938964844, pressure: 0.4431372880935669}, {
                  x: 754,
                  y: 342.8000183105469,
                  pressure: 0.4431372880935669
                }, {x: 761.6000366210938, y: 338.8000183105469, pressure: 0.4431372880935669}, {
                  x: 768.7999877929688,
                  y: 334.8000183105469,
                  pressure: 0.4431372880935669
                }, {x: 775.6000366210938, y: 330, pressure: 0.4431372880935669}, {
                  x: 781.6000366210938,
                  y: 325.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 786.7999877929688, y: 320.3999938964844, pressure: 0.4431372880935669}, {
                  x: 791.2000122070312,
                  y: 315.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 794.7999877929688, y: 310.3999938964844, pressure: 0.4431372880935669}, {
                  x: 797.2000122070312,
                  y: 305.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 798.4000244140625, y: 300.3999938964844, pressure: 0.4431372880935669}, {
                  x: 799.2000122070312,
                  y: 295.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 798.7999877929688, y: 290, pressure: 0.4431372880935669}, {
                  x: 798,
                  y: 284.3999938964844,
                  pressure: 0.4431372880935669
                }, {x: 795.6000366210938, y: 279.20001220703125, pressure: 0.4431372880935669}, {
                  x: 792,
                  y: 274,
                  pressure: 0.4431372880935669
                }, {x: 787.6000366210938, y: 269.20001220703125, pressure: 0.4431372880935669}, {
                  x: 783.2000122070312,
                  y: 265.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 778, y: 260.8000183105469, pressure: 0.4431372880935669}, {
                  x: 772.4000244140625,
                  y: 256.8000183105469,
                  pressure: 0.4431372880935669
                }, {x: 766.7999877929688, y: 252.8000030517578, pressure: 0.4431372880935669}, {
                  x: 760.4000244140625,
                  y: 249.60000610351562,
                  pressure: 0.4431372880935669
                }, {x: 753.6000366210938, y: 246.8000030517578, pressure: 0.4431372880935669}, {
                  x: 746.4000244140625,
                  y: 244.40000915527344,
                  pressure: 0.4431372880935669
                }, {x: 738.4000244140625, y: 243.1999969482422, pressure: 0.4431372880935669}, {
                  x: 730,
                  y: 242.40000915527344,
                  pressure: 0.4431372880935669
                }, {x: 721.2000122070312, y: 242.40000915527344, pressure: 0.4431372880935669}, {
                  x: 712,
                  y: 243.1999969482422,
                  pressure: 0.4431372880935669
                }, {x: 702.4000244140625, y: 245.60000610351562, pressure: 0.4431372880935669}, {
                  x: 692.7999877929688,
                  y: 248.8000030517578,
                  pressure: 0.4431372880935669
                }, {x: 682.7999877929688, y: 253.60000610351562, pressure: 0.4431372880935669}, {
                  x: 673.2000122070312,
                  y: 259.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 663.6000366210938, y: 265.20001220703125, pressure: 0.4431372880935669}, {
                  x: 654.7999877929688,
                  y: 271.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 646.7999877929688, y: 277.20001220703125, pressure: 0.4431372880935669}, {
                  x: 639.6000366210938,
                  y: 283.20001220703125,
                  pressure: 0.4431372880935669
                }, {x: 633.2000122070312, y: 288.3999938964844, pressure: 0.4431372880935669}, {
                  x: 628,
                  y: 293.6000061035156,
                  pressure: 0.4431372880935669
                }, {x: 624, y: 298, pressure: 0.4431372880935669}, {
                  x: 621.6000366210938,
                  y: 301.6000061035156,
                  pressure: 0.4431372880935669
                }, {
                  x: 620,
                  y: 304.3999938964844,
                  pressure: 0.4274510145187378
                }, {x: 619.6000366210938, y: 306.3999938964844, pressure: 0.3450980484485626}, {
                  x: 619.6000366210938,
                  y: 307.6000061035156,
                  pressure: 0.250980406999588
                }], objet: 648, delta: 778, alpha: 0.10000000000000001
            },
            {

              color: 'RGB(77,19,80)',
              width: 20,
              start: 1644937220925,
              line: [{x: 774.4000244140625, y: 74, pressure: 0}, {
                x: 774.4000244140625,
                y: 74,
                pressure: 0.2980392277240753
              }, {x: 774.4000244140625, y: 74, pressure: 0.33725491166114807}, {
                x: 773.6000366210938,
                y: 71.5999984741211,
                pressure: 0.3607843220233917
              }, {x: 768, y: 69.20000457763672, pressure: 0.3803921937942505}, {
                x: 761.2000122070312,
                y: 66.4000015258789,
                pressure: 0.38823533058166504
              }, {x: 754, y: 66.4000015258789, pressure: 0.3921568989753723}, {
                x: 750.7999877929688,
                y: 66,
                pressure: 0.3921568989753723
              }, {x: 747.6000366210938, y: 66.80000305175781, pressure: 0.3921568989753723}, {
                x: 744.4000244140625,
                y: 68.4000015258789,
                pressure: 0.3960784673690796
              }, {x: 740.7999877929688, y: 71.20000457763672, pressure: 0.3960784673690796}, {
                x: 736.4000244140625,
                y: 74.80000305175781,
                pressure: 0.3960784673690796
              }, {x: 731.2000122070312, y: 80, pressure: 0.3960784673690796}, {
                x: 725.2000122070312,
                y: 86,
                pressure: 0.3960784673690796
              }, {x: 719.6000366210938, y: 92.4000015258789, pressure: 0.3960784673690796}, {
                x: 714,
                y: 99.20000457763672,
                pressure: 0.40000003576278687
              }, {x: 709.2000122070312, y: 106.80000305175781, pressure: 0.4078431725502014}, {
                x: 704.7999877929688,
                y: 114,
                pressure: 0.41568630933761597
              }, {x: 701.2000122070312, y: 121.5999984741211, pressure: 0.41960787773132324}, {
                x: 698,
                y: 128.8000030517578,
                pressure: 0.41960787773132324
              }, {x: 695.6000366210938, y: 136.40000915527344, pressure: 0.41960787773132324}, {
                x: 694,
                y: 144,
                pressure: 0.41960787773132324
              }, {x: 692.7999877929688, y: 151.60000610351562, pressure: 0.41960787773132324}, {
                x: 692.4000244140625,
                y: 159.60000610351562,
                pressure: 0.41960787773132324
              }, {x: 692.7999877929688, y: 167.60000610351562, pressure: 0.41960787773132324}, {
                x: 694,
                y: 175.1999969482422,
                pressure: 0.41960787773132324
              }, {x: 696, y: 182.40000915527344, pressure: 0.41960787773132324}, {
                x: 699.6000366210938,
                y: 189.60000610351562,
                pressure: 0.41960787773132324
              }, {x: 703.6000366210938, y: 196.40000915527344, pressure: 0.41960787773132324}, {
                x: 708.4000244140625,
                y: 202,
                pressure: 0.41960787773132324
              }, {x: 714.4000244140625, y: 207.1999969482422, pressure: 0.41960787773132324}, {
                x: 720.7999877929688,
                y: 211.60000610351562,
                pressure: 0.41960787773132324
              }, {x: 728, y: 215.1999969482422, pressure: 0.41960787773132324}, {
                x: 735.6000366210938,
                y: 217.60000610351562,
                pressure: 0.41960787773132324
              }, {x: 743.6000366210938, y: 218.8000030517578, pressure: 0.41960787773132324}, {
                x: 752,
                y: 219.1999969482422,
                pressure: 0.41960787773132324
              }, {x: 760.7999877929688, y: 218.40000915527344, pressure: 0.41960787773132324}, {
                x: 769.6000366210938,
                y: 216,
                pressure: 0.41960787773132324
              }, {x: 778.4000244140625, y: 212.8000030517578, pressure: 0.41960787773132324}, {
                x: 787.6000366210938,
                y: 208,
                pressure: 0.41960787773132324
              }, {x: 796, y: 202.8000030517578, pressure: 0.41960787773132324}, {
                x: 804.4000244140625,
                y: 196.40000915527344,
                pressure: 0.41960787773132324
              }, {x: 812, y: 189.60000610351562, pressure: 0.41960787773132324}, {
                x: 818.4000244140625,
                y: 182.8000030517578,
                pressure: 0.41960787773132324
              }, {x: 823.6000366210938, y: 176, pressure: 0.41960787773132324}, {
                x: 827.2000122070312,
                y: 169.60000610351562,
                pressure: 0.41960787773132324
              }, {x: 830.4000244140625, y: 163.1999969482422, pressure: 0.41960787773132324}, {
                x: 832.4000244140625,
                y: 156.40000915527344,
                pressure: 0.41960787773132324
              }, {x: 833.6000366210938, y: 149.60000610351562, pressure: 0.41960787773132324}, {
                x: 834.4000244140625,
                y: 142.40000915527344,
                pressure: 0.41960787773132324
              }, {x: 834.4000244140625, y: 134.8000030517578, pressure: 0.41960787773132324}, {
                x: 834,
                y: 127.5999984741211,
                pressure: 0.41960787773132324
              }, {x: 832.4000244140625, y: 120.80000305175781, pressure: 0.41960787773132324}, {
                x: 830,
                y: 114,
                pressure: 0.41960787773132324
              }, {x: 827.2000122070312, y: 108, pressure: 0.41960787773132324}, {
                x: 823.6000366210938,
                y: 102.4000015258789,
                pressure: 0.41960787773132324
              }, {x: 819.6000366210938, y: 96.80000305175781, pressure: 0.41960787773132324}, {
                x: 815.2000122070312,
                y: 91.20000457763672,
                pressure: 0.41960787773132324
              }, {x: 810.4000244140625, y: 86, pressure: 0.41960787773132324}, {
                x: 804.4000244140625,
                y: 81.20000457763672,
                pressure: 0.41960787773132324
              }, {x: 798, y: 77.20000457763672, pressure: 0.41960787773132324}, {
                x: 790.4000244140625,
                y: 74,
                pressure: 0.41960787773132324
              }, {x: 782, y: 72, pressure: 0.41960787773132324}, {
                x: 773.2000122070312,
                y: 70.80000305175781,
                pressure: 0.41960787773132324
              }, {x: 764.7999877929688, y: 70.4000015258789, pressure: 0.41960787773132324}, {
                x: 756,
                y: 70.80000305175781,
                pressure: 0.41960787773132324
              }, {x: 747.2000122070312, y: 72.4000015258789, pressure: 0.41960787773132324}, {
                x: 738.4000244140625,
                y: 75.20000457763672,
                pressure: 0.41960787773132324
              }, {x: 730.4000244140625, y: 79.20000457763672, pressure: 0.41960787773132324}, {
                x: 722.4000244140625,
                y: 83.20000457763672,
                pressure: 0.4235294461250305
              }, {x: 714.7999877929688, y: 88.4000015258789, pressure: 0.43137258291244507}, {
                x: 708,
                y: 94.4000015258789,
                pressure: 0.43529415130615234
              }, {x: 701.6000366210938, y: 102, pressure: 0.4392157196998596}, {
                x: 695.6000366210938,
                y: 110.4000015258789,
                pressure: 0.4392157196998596
              }, {x: 690.4000244140625, y: 119.20000457763672, pressure: 0.4392157196998596}, {
                x: 686.4000244140625,
                y: 128.8000030517578,
                pressure: 0.4392157196998596
              }, {x: 683.2000122070312, y: 138.40000915527344, pressure: 0.4392157196998596}, {
                x: 681.2000122070312,
                y: 148.40000915527344,
                pressure: 0.4392157196998596
              }, {x: 680, y: 158.8000030517578, pressure: 0.4392157196998596}, {
                x: 680,
                y: 168.8000030517578,
                pressure: 0.4392157196998596
              }, {x: 681.2000122070312, y: 178.40000915527344, pressure: 0.4392157196998596}, {
                x: 684.4000244140625,
                y: 187.60000610351562,
                pressure: 0.4392157196998596
              }, {x: 689.2000122070312, y: 196, pressure: 0.4392157196998596}, {
                x: 696.7999877929688,
                y: 203.1999969482422,
                pressure: 0.3803921937942505
              }, {x: 706, y: 208.8000030517578, pressure: 0.26274511218070984}],
              objet: 648,
              delta: 703,
              alpha: 0.10000000000000001
            }, {
             color: 'RGB(77, 19, 80)', width:
              20, start:
              1644937212536, line:
              [{x: 365.20001220703125, y: 70.80000305175781, pressure: 0}, {
                x: 365.20001220703125,
                y: 70.80000305175781,
                pressure: 0.35686275362968445
              }, {x: 365.20001220703125, y: 70.80000305175781, pressure: 0.364705890417099}, {
                x: 365.20001220703125,
                y: 70.80000305175781,
                pressure: 0.3686274588108063
              }, {x: 365.20001220703125, y: 70.80000305175781, pressure: 0.37254902720451355}, {
                x: 363.6000061035156,
                y: 72,
                pressure: 0.37254902720451355
              }, {x: 362, y: 79.20000457763672, pressure: 0.37254902720451355}, {
                x: 360.3999938964844,
                y: 90.4000015258789,
                pressure: 0.3764706254005432
              }, {x: 360.8000183105469, y: 104, pressure: 0.38431376218795776}, {
                x: 360.8000183105469,
                y: 114.4000015258789,
                pressure: 0.38431376218795776
              }, {x: 360.8000183105469, y: 125.20000457763672, pressure: 0.38431376218795776}, {
                x: 360.8000183105469,
                y: 136,
                pressure: 0.38431376218795776
              }, {x: 361.20001220703125, y: 146.40000915527344, pressure: 0.38431376218795776}, {
                x: 362,
                y: 156.8000030517578,
                pressure: 0.38431376218795776
              }, {x: 363.6000061035156, y: 166.40000915527344, pressure: 0.38431376218795776}, {
                x: 366.3999938964844,
                y: 175.1999969482422,
                pressure: 0.38823533058166504
              }, {x: 369.6000061035156, y: 183.60000610351562, pressure: 0.38823533058166504}, {
                x: 373.6000061035156,
                y: 190.8000030517578,
                pressure: 0.38823533058166504
              }, {x: 378.8000183105469, y: 197.60000610351562, pressure: 0.3921568989753723}, {
                x: 384.3999938964844,
                y: 203.1999969482422,
                pressure: 0.3960784673690796
              }, {x: 391.20001220703125, y: 208, pressure: 0.40000003576278687}, {
                x: 399.20001220703125,
                y: 212,
                pressure: 0.40392160415649414
              }, {x: 408, y: 215.1999969482422, pressure: 0.40392160415649414}, {
                x: 417.6000061035156,
                y: 217.1999969482422,
                pressure: 0.40392160415649414
              }, {x: 428, y: 218.40000915527344, pressure: 0.40392160415649414}, {
                x: 438.8000183105469,
                y: 218.8000030517578,
                pressure: 0.40392160415649414
              }, {x: 450, y: 218, pressure: 0.40392160415649414}, {
                x: 461.60003662109375,
                y: 216,
                pressure: 0.40392160415649414
              }, {x: 473.60003662109375, y: 212.40000915527344, pressure: 0.40392160415649414}, {
                x: 485.60003662109375,
                y: 207.1999969482422,
                pressure: 0.40392160415649414
              }, {x: 497.60003662109375, y: 201.1999969482422, pressure: 0.40392160415649414}, {
                x: 508.79998779296875,
                y: 194.40000915527344,
                pressure: 0.40392160415649414
              }, {x: 519.2000122070312, y: 186.40000915527344, pressure: 0.40392160415649414}, {
                x: 528.4000244140625,
                y: 178.40000915527344,
                pressure: 0.40392160415649414
              }, {x: 536.4000244140625, y: 170.8000030517578, pressure: 0.40392160415649414}, {
                x: 542.4000244140625,
                y: 163.60000610351562,
                pressure: 0.40392160415649414
              }, {x: 547.2000122070312, y: 156.8000030517578, pressure: 0.40392160415649414}, {
                x: 550,
                y: 150.8000030517578,
                pressure: 0.40392160415649414
              }, {x: 551.6000366210938, y: 144.8000030517578, pressure: 0.40392160415649414}, {
                x: 552,
                y: 140,
                pressure: 0.40392160415649414
              }, {x: 552, y: 135.1999969482422, pressure: 0.40392160415649414}, {
                x: 550.7999877929688,
                y: 131.1999969482422,
                pressure: 0.40392160415649414
              }, {x: 549.2000122070312, y: 127.20000457763672, pressure: 0.40392160415649414}, {
                x: 546.7999877929688,
                y: 124,
                pressure: 0.40392160415649414
              }, {x: 543.6000366210938, y: 120.80000305175781, pressure: 0.40392160415649414}, {
                x: 540,
                y: 118,
                pressure: 0.40392160415649414
              }, {x: 535.2000122070312, y: 114.80000305175781, pressure: 0.40392160415649414}, {
                x: 529.6000366210938,
                y: 111.5999984741211,
                pressure: 0.40392160415649414
              }, {x: 522.7999877929688, y: 109.5999984741211, pressure: 0.40392160415649414}, {
                x: 515.6000366210938,
                y: 108.80000305175781,
                pressure: 0.40392160415649414
              }, {x: 507.60003662109375, y: 108.4000015258789, pressure: 0.40392160415649414}, {
                x: 499.20001220703125,
                y: 108.80000305175781,
                pressure: 0.40392160415649414
              }, {x: 490, y: 110.4000015258789, pressure: 0.4078431725502014}, {
                x: 480.4000244140625,
                y: 113.20000457763672,
                pressure: 0.4078431725502014
              }, {x: 470.4000244140625, y: 117.20000457763672, pressure: 0.4078431725502014}, {
                x: 460.4000244140625,
                y: 122.4000015258789,
                pressure: 0.4117647409439087
              }, {x: 450.8000183105469, y: 128.40000915527344, pressure: 0.4117647409439087}, {
                x: 441.20001220703125,
                y: 135.1999969482422,
                pressure: 0.4117647409439087
              }, {x: 433.20001220703125, y: 142.40000915527344, pressure: 0.4117647409439087}, {
                x: 426.3999938964844,
                y: 150,
                pressure: 0.4117647409439087
              }, {x: 421.6000061035156, y: 157.1999969482422, pressure: 0.4117647409439087}, {
                x: 418,
                y: 164.40000915527344,
                pressure: 0.4117647409439087
              }, {x: 416.3999938964844, y: 172, pressure: 0.4117647409439087}, {
                x: 416,
                y: 179.60000610351562,
                pressure: 0.4117647409439087
              }, {x: 416.8000183105469, y: 188, pressure: 0.4117647409439087}, {
                x: 419.20001220703125,
                y: 196.8000030517578,
                pressure: 0.4117647409439087
              }, {x: 424, y: 205.60000610351562, pressure: 0.4117647409439087}, {
                x: 430.8000183105469,
                y: 214.40000915527344,
                pressure: 0.40000003576278687
              }, {x: 438.3999938964844, y: 222.40000915527344, pressure: 0.29411765933036804}], objet:
              648, delta:
              579, alpha:
              0.10000000000000001
          }
            ,
            {

              color:
                'RGB(26,9,0)',
              width: 2,
              start: 1644937216008,
              line: [{x: 252, y: 78.80000305175781, pressure: 0}, {x: 252, y: 78.80000305175781, pressure: 0.29411765933036804}, {
                x: 252,
                y: 78.80000305175781,
                pressure: 0.32156863808631897
              }, {x: 252, y: 78.80000305175781, pressure: 0.3490196168422699}, {
                x: 252,
                y: 78.80000305175781,
                pressure: 0.364705890417099
              }, {x: 252, y: 84.80000305175781, pressure: 0.37254902720451355}, {
                x: 251.60000610351562,
                y: 93.20000457763672,
                pressure: 0.3803921937942505
              }, {x: 251.20001220703125, y: 106, pressure: 0.38823533058166504}, {
                x: 251.20001220703125,
                y: 116.4000015258789,
                pressure: 0.40000003576278687
              }, {x: 251.20001220703125, y: 128.8000030517578, pressure: 0.40000003576278687}, {
                x: 250.80001831054688,
                y: 142.40000915527344,
                pressure: 0.40000003576278687
              }, {x: 250.39999389648438, y: 157.1999969482422, pressure: 0.40000003576278687}, {
                x: 249.60000610351562,
                y: 2.1999969482422,
                pressure: 0.40000003576278687
              }, {x: 248.39999389648438, y: 190, pressure: 0.40000003576278687}, {
                x: 247.20001220703125,
                y: 207.1999969482422,
                pressure: 0.4078431725502014
              }, {x: 246, y: 224, pressure: 0.4078431725502014}, {
                x: 244.39999389648438,
                y: 240.8000030517578,
                pressure: 0.4078431725502014
              }, {x: 242.80001831054688, y: 257.20001220703125, pressure: 0.4078431725502014}, {
                x: 241.20001220703125,
                y: 272.8000183105469,
                pressure: 0.4078431725502014
              }, {x: 240, y: 287.20001220703125, pressure: 0.4078431725502014}, {
                x: 238.80001831054688,
                y: 300.8000183105469,
                pressure: 0.4078431725502014
              }, {x: 238, y: 313.20001220703125, pressure: 0.4078431725502014}, {
                x: 238,
                y: 323.6000061035156,
                pressure: 0.4078431725502014
              }, {x: 238.39999389648438, y: 332, pressure: 0.4078431725502014}, {
                x: 240.39999389648438,
                y: 338.3999938964844,
                pressure: 0.4078431725502014
              }, {x: 242.80001831054688, y: 343.20001220703125, pressure: 0.4078431725502014}, {
                x: 246,
                y: 347.20001220703125,
                pressure: 0.4078431725502014
              }, {x: 250, y: 349.6000061035156, pressure: 0.4078431725502014}, {
                x: 255.20001220703125,
                y: 350.8000183105469,
                pressure: 0.4078431725502014
              }, {x: 260.8000183105469, y: 351.20001220703125, pressure: 0.4117647409439087}, {
                x: 267.6000061035156,
                y: 350.8000183105469,
                pressure: 0.4117647409439087
              }, {x: 275.6000061035156, y: 349.20001220703125, pressure: 0.4117647409439087}, {
                x: 284.3999938964844,
                y: 346.3999938964844,
                pressure: 0.4117647409439087
              }, {x: 294, y: 343.20001220703125, pressure: 0.4117647409439087}, {
                x: 304.3999938964844,
                y: 340,
                pressure: 0.4117647409439087
              }, {x: 315.20001220703125, y: 336.3999938964844, pressure: 0.4117647409439087}, {
                x: 326.3999938964844,
                y: 333.20001220703125,
                pressure: 0.4117647409439087
              }, {x: 337.20001220703125, y: 330.8000183105469, pressure: 0.4117647409439087}, {
                x: 348.3999938964844,
                y: 328.8000183105469,
                pressure: 0.4117647409439087
              }, {x: 359.20001220703125, y: 327.6000061035156, pressure: 0.4117647409439087}, {
                x: 369.6000061035156,
                y: 326.8000183105469,
                pressure: 0.4117647409439087
              }, {x: 380, y: 326, pressure: 0.4117647409439087}, {
                x: 390.8000183105469,
                y: 326,
                pressure: 0.4117647409439087
              }, {x: 401.20001220703125, y: 326, pressure: 0.4117647409439087}, {
                x: 412,
                y: 326,
                pressure: 0.4117647409439087
              }, {x: 422.8000183105469, y: 326, pressure: 0.4117647409439087}, {
                x: 433.6000061035156,
                y: 326.3999938964844,
                pressure: 0.41568630933761597
              }, {x: 444, y: 326, pressure: 0.41960787773132324}, {x: 454, y: 326, pressure: 0.4235294461250305}, {
                x: 463.60003662109375,
                y: 325.6000061035156,
                pressure: 0.4274510145187378
              }, {x: 472.4000244140625, y: 325.20001220703125, pressure: 0.4274510145187378}, {
                x: 480.79998779296875,
                y: 324.3999938964844,
                pressure: 0.4274510145187378
              }, {x: 488.4000244140625, y: 323.20001220703125, pressure: 0.4274510145187378}, {
                x: 495.60003662109375,
                y: 322.3999938964844,
                pressure: 0.4274510145187378
              }, {x: 502, y: 320.8000183105469, pressure: 0.4274510145187378}, {
                x: 507.20001220703125,
                y: 318.8000183105469,
                pressure: 0.4274510145187378
              }, {x: 512, y: 316.8000183105469, pressure: 0.4274510145187378}, {
                x: 516,
                y: 314.3999938964844,
                pressure: 0.4274510145187378
              }, {x: 519.2000122070312, y: 311.6000061035156, pressure: 0.4274510145187378}, {
                x: 521.2000122070312,
                y: 308.8000183105469,
                pressure: 0.4274510145187378
              }, {x: 522.4000244140625, y: 305.6000061035156, pressure: 0.4274510145187378}, {
                x: 523.2000122070312,
                y: 302.3999938964844,
                pressure: 0.4274510145187378
              }, {x: 523.2000122070312, y: 298, pressure: 0.4274510145187378}, {
                x: 523.6000366210938,
                y: 293.20001220703125,
                pressure: 0.4274510145187378
              }, {x: 522.7999877929688, y: 286.3999938964844, pressure: 0.4274510145187378}, {
                x: 521.6000366210938,
                y: 278.8000183105469,
                pressure: 0.4274510145187378
              }, {x: 520.4000244140625, y: 270.8000183105469, pressure: 0.4274510145187378}, {
                x: 518.7999877929688,
                y: 262.3999938964844,
                pressure: 0.4274510145187378
              }, {x: 517.2000122070312, y: 253.1999969482422, pressure: 0.4274510145187378}, {
                x: 515.6000366210938,
                y: 243.1999969482422,
                pressure: 0.4274510145187378
              }, {x: 514.4000244140625, y: 232.40000915527344, pressure: 0.4274510145187378}, {
                x: 513.6000366210938,
                y: 220.40000915527344,
                pressure: 0.4274510145187378
              }, {x: 512.7999877929688, y: 208.40000915527344, pressure: 0.4274510145187378}, {
                x: 512.4000244140625,
                y: 195.1999969482422,
                pressure: 0.4274510145187378
              }, {x: 512.4000244140625, y: 182, pressure: 0.4274510145187378}, {
                x: 512.7999877929688,
                y: 168.40000915527344,
                pressure: 0.4274510145187378
              }, {x: 513.6000366210938, y: 155.60000610351562, pressure: 0.4274510145187378}, {
                x: 514.7999877929688,
                y: 143.1999969482422,
                pressure: 0.4274510145187378
              }, {x: 516, y: 131.60000610351562, pressure: 0.4274510145187378}, {
                x: 517.6000366210938,
                y: 120.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 518.7999877929688, y: 110.4000015258789, pressure: 0.4274510145187378}, {
                x: 520,
                y: 101.20000457763672,
                pressure: 0.4274510145187378
              }, {x: 520.7999877929688, y: 92.80000305175781, pressure: 0.4274510145187378}, {
                x: 521.6000366210938,
                y: 85.20000457763672,
                pressure: 0.4274510145187378
              }, {x: 521.6000366210938, y: 78.80000305175781, pressure: 0.4274510145187378}, {
                x: 520.7999877929688,
                y: 73.5999984741211,
                pressure: 0.4274510145187378
              }, {x: 519.2000122070312, y: 69.20000457763672, pressure: 0.4274510145187378}, {
                x: 516,
                y: 65.5999984741211,
                pressure: 0.4274510145187378
              }, {x: 512.4000244140625, y: 63.20000076293945, pressure: 0.4274510145187378}, {
                x: 508.4000244140625,
                y: 60.79999923706055,
                pressure: 0.4274510145187378
              }, {x: 503.20001220703125, y: 59.20000076293945, pressure: 0.4274510145187378}, {
                x: 496.79998779296875,
                y: 58,
                pressure: 0.4274510145187378
              }, {x: 489.60003662109375, y: 56.79999923706055, pressure: 0.4274510145187378}, {
                x: 482,
                y: 56.400001525878906,
                pressure: 0.4274510145187378
              }, {x: 474.4000244140625, y: 56, pressure: 0.4274510145187378}, {
                x: 465.60003662109375,
                y: 56,
                pressure: 0.4274510145187378
              }, {x: 456.4000244140625, y: 56, pressure: 0.4274510145187378}, {
                x: 446.3999938964844,
                y: 56.79999923706055,
                pressure: 0.4274510145187378
              }, {x: 436, y: 58.400001525878906, pressure: 0.4274510145187378}, {
                x: 424.8000183105469,
                y: 60,
                pressure: 0.4274510145187378
              }, {x: 413.6000061035156, y: 62, pressure: 0.4274510145187378}, {
                x: 402,
                y: 64.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 389.6000061035156, y: 66.4000015258789, pressure: 0.4274510145187378}, {
                x: 377.20001220703125,
                y: 69.20000457763672,
                pressure: 0.4274510145187378
              }, {x: 364, y: 71.5999984741211, pressure: 0.4274510145187378}, {
                x: 350.3999938964844,
                y: 74.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 337.6000061035156, y: 76.80000305175781, pressure: 0.4274510145187378}, {
                x: 324.8000183105469,
                y: 78.80000305175781,
                pressure: 0.4274510145187378
              }, {x: 312.3999938964844, y: 80.4000015258789, pressure: 0.4274510145187378}, {
                x: 300,
                y: 81.5999984741211,
                pressure: 0.4274510145187378
              }, {x: 288.8000183105469, y: 82.80000305175781, pressure: 0.4274510145187378}, {
                x: 277.6000061035156,
                y: 84,
                pressure: 0.4274510145187378
              }, {x: 267.6000061035156, y: 85.20000457763672, pressure: 0.4274510145187378}, {
                x: 258,
                y: 86.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 250, y: 87.20000457763672, pressure: 0.4274510145187378}, {
                x: 243.20001220703125,
                y: 88,
                pressure: 0.4274510145187378
              }, {x: 238, y: 88.80000305175781, pressure: 0.4274510145187378}, {
                x: 234,
                y: 89.20000457763672,
                pressure: 0.4274510145187378
              }, {x: 231.60000610351562, y: 89.20000457763672, pressure: 0.4274510145187378}, {
                x: 230.39999389648438,
                y: 90,
                pressure: 0.4274510145187378
              }, {x: 229.60000610351562, y: 90.4000015258789, pressure: 0.3529411852359772}, {
                x: 229.60000610351562,
                y: 91.20000457763672,
                pressure: 0.25882354378700256
              }],
              objet: 648,
              delta: 1014,
              alpha: 0.90000000000000002
            }]
      }, {
         sujet: 2, start: 1644937236854, bounds: null, life: 17035, transforms: [], strokes: [{
           color: 'RGB(77, 19, 80)', width:
            20, start:
            1644937238741, line:
            [{x: 816.4000244140625, y: 44.400001525878906, pressure: 0}, {
              x: 815.6000366210938,
              y: 44.79999923706055,
              pressure: 0.35686275362968445
            }, {x: 809.2000122070312, y: 45.20000076293945, pressure: 0.37254902720451355}, {
              x: 801.6000366210938,
              y: 46,
              pressure: 0.3803921937942505
            }, {x: 793.2000122070312, y: 46.400001525878906, pressure: 0.38431376218795776}, {
              x: 788.4000244140625,
              y: 47.20000076293945,
              pressure: 0.38823533058166504
            }, {x: 782.7999877929688, y: 48.400001525878906, pressure: 0.3921568989753723}, {
              x: 776.7999877929688,
              y: 50.79999923706055,
              pressure: 0.3960784673690796
            }, {x: 769.2000122070312, y: 54, pressure: 0.40000003576278687}, {
              x: 760.4000244140625,
              y: 58,
              pressure: 0.40000003576278687
            }, {x: 750.4000244140625, y: 62.79999923706055, pressure: 0.40392160415649414}, {
              x: 739.6000366210938,
              y: 68,
              pressure: 0.40392160415649414
            }, {x: 728, y: 74, pressure: 0.4078431725502014}, {
              x: 715.6000366210938,
              y: 80.80000305175781,
              pressure: 0.4117647409439087
            }, {x: 702.4000244140625, y: 87.5999984741211, pressure: 0.4117647409439087}, {
              x: 688,
              y: 95.5999984741211,
              pressure: 0.4117647409439087
            }, {x: 672.4000244140625, y: 104.4000015258789, pressure: 0.4117647409439087}, {
              x: 655.6000366210938,
              y: 114,
              pressure: 0.4117647409439087
            }, {x: 638, y: 124, pressure: 0.4117647409439087}, {
              x: 620.7999877929688,
              y: 133.60000610351562,
              pressure: 0.4117647409439087
            }, {x: 603.6000366210938, y: 143.1999969482422, pressure: 0.4117647409439087}, {
              x: 586.4000244140625,
              y: 152.8000030517578,
              pressure: 0.4117647409439087
            }, {x: 569.2000122070312, y: 162.8000030517578, pressure: 0.4117647409439087}, {
              x: 551.6000366210938,
              y: 172.40000915527344,
              pressure: 0.4117647409439087
            }, {x: 534, y: 182, pressure: 0.41568630933761597}, {
              x: 516.4000244140625,
              y: 191.1999969482422,
              pressure: 0.43137258291244507
            }, {x: 498.79998779296875, y: 200.40000915527344, pressure: 0.4431372880935669}, {
              x: 482.4000244140625,
              y: 208.8000030517578,
              pressure: 0.45098042488098145
            }, {x: 466, y: 216.8000030517578, pressure: 0.45098042488098145}, {
              x: 450,
              y: 224.8000030517578,
              pressure: 0.45098042488098145
            }, {x: 434, y: 232.40000915527344, pressure: 0.45098042488098145}, {
              x: 418.3999938964844,
              y: 240,
              pressure: 0.45098042488098145
            }, {x: 402.8000183105469, y: 248, pressure: 0.45098042488098145}, {
              x: 388,
              y: 255.60000610351562,
              pressure: 0.45098042488098145
            }, {x: 373.6000061035156, y: 263.6000061035156, pressure: 0.45098042488098145}, {
              x: 358.3999938964844,
              y: 272,
              pressure: 0.45098042488098145
            }, {x: 342.8000183105469, y: 280, pressure: 0.45098042488098145}, {
              x: 327.20001220703125,
              y: 288.3999938964844,
              pressure: 0.45098042488098145
            }, {x: 311.6000061035156, y: 297.20001220703125, pressure: 0.45098042488098145}, {
              x: 296.8000183105469,
              y: 305.6000061035156,
              pressure: 0.45098042488098145
            }, {x: 283.20001220703125, y: 312.8000183105469, pressure: 0.45098042488098145}, {
              x: 270.8000183105469,
              y: 318.8000183105469,
              pressure: 0.45098042488098145
            }, {x: 259.6000061035156, y: 323.6000061035156, pressure: 0.45098042488098145}, {
              x: 249.60000610351562,
              y: 327.20001220703125,
              pressure: 0.45098042488098145
            }, {x: 240.80001831054688, y: 330, pressure: 0.45098042488098145}, {
              x: 233.20001220703125,
              y: 332,
              pressure: 0.45098042488098145
            }, {x: 226.39999389648438, y: 333.6000061035156, pressure: 0.45098042488098145}, {
              x: 220.80001831054688,
              y: 334.3999938964844,
              pressure: 0.45098042488098145
            }, {x: 216, y: 335.20001220703125, pressure: 0.45098042488098145}, {
              x: 212,
              y: 335.6000061035156,
              pressure: 0.45098042488098145
            }, {x: 209.20001220703125, y: 336, pressure: 0.45098042488098145}, {
              x: 206.80001831054688,
              y: 336.3999938964844,
              pressure: 0.45098042488098145
            }, {x: 205.20001220703125, y: 336.3999938964844, pressure: 0.45098042488098145}, {
              x: 204,
              y: 336.8000183105469,
              pressure: 0.45098042488098145
            }, {x: 203.20001220703125, y: 337.20001220703125, pressure: 0.45098042488098145}, {
              x: 202.80001831054688,
              y: 337.6000061035156,
              pressure: 0.45098042488098145
            }, {x: 202.39999389648438, y: 338.3999938964844, pressure: 0.45098042488098145}, {
              x: 202,
              y: 339.6000061035156,
              pressure: 0.44705885648727417
            }, {x: 202, y: 340.8000183105469, pressure: 0.38823533058166504}, {
              x: 201.60000610351562,
              y: 342.3999938964844,
              pressure: 0.29411765933036804
            }, {x: 201.60000610351562, y: 343.20001220703125, pressure: 0.21176472306251526}], objet:
            649, delta:
            510, alpha:
            0.10000000000000001
        }
          ,
          {
             color:
              'RGB(184,45,191)', width:
              20, start:
              1644937251587, line:
              [{x: 190, y: 228, pressure: 0}, {x: 190, y: 228, pressure: 0.3176470696926117}, {
                x: 190,
                y: 228,
                pressure: 0.35686275362968445
              }, {x: 186.8000030517578, y: 228.40000915527344, pressure: 0.3803921937942505}, {
                x: 183.1999969482422,
                y: 233.60000610351562,
                pressure: 0.3960784673690796
              }, {x: 179.60000610351562, y: 239.60000610351562, pressure: 0.40000003576278687}, {
                x: 180.40000915527344,
                y: 247.1999969482422,
                pressure: 0.4078431725502014
              }, {x: 182, y: 251.60000610351562, pressure: 0.41568630933761597}, {
                x: 184.8000030517578,
                y: 256.3999938964844,
                pressure: 0.41568630933761597
              }, {x: 188.40000915527344, y: 262, pressure: 0.41568630933761597}, {
                x: 192.8000030517578,
                y: 268,
                pressure: 0.41568630933761597
              }, {x: 198, y: 274.3999938964844, pressure: 0.41568630933761597}, {
                x: 204,
                y: 281.20001220703125,
                pressure: 0.41568630933761597
              }, {x: 210.39999389648438, y: 288, pressure: 0.41568630933761597}, {
                x: 217.60000610351562,
                y: 294.8000183105469,
                pressure: 0.41568630933761597
              }, {x: 225.20001220703125, y: 301.6000061035156, pressure: 0.41568630933761597}, {
                x: 233.20001220703125,
                y: 308,
                pressure: 0.41568630933761597
              }, {x: 242, y: 314, pressure: 0.41960787773132324}, {x: 252, y: 319.6000061035156, pressure: 0.4274510145187378}, {
                x: 262.3999938964844,
                y: 324.8000183105469,
                pressure: 0.4274510145187378
              }, {x: 274, y: 329.6000061035156, pressure: 0.4274510145187378}, {
                x: 286.3999938964844,
                y: 334,
                pressure: 0.4274510145187378
              }, {x: 299.6000061035156, y: 338, pressure: 0.4274510145187378}, {
                x: 313.20001220703125,
                y: 341.20001220703125,
                pressure: 0.4274510145187378
              }, {x: 327.6000061035156, y: 344, pressure: 0.4274510145187378}, {
                x: 342.3999938964844,
                y: 346.3999938964844,
                pressure: 0.4274510145187378
              }, {x: 357.6000061035156, y: 348, pressure: 0.4274510145187378}, {
                x: 372.3999938964844,
                y: 349.20001220703125,
                pressure: 0.4274510145187378
              }, {x: 387.6000061035156, y: 349.20001220703125, pressure: 0.4274510145187378}, {
                x: 403.6000061035156,
                y: 347.6000061035156,
                pressure: 0.4274510145187378
              }, {x: 419.20001220703125, y: 344, pressure: 0.4274510145187378}, {
                x: 434.8000183105469,
                y: 339.20001220703125,
                pressure: 0.4274510145187378
              }, {x: 449.6000061035156, y: 332.8000183105469, pressure: 0.4274510145187378}, {
                x: 462.79998779296875,
                y: 326,
                pressure: 0.4274510145187378
              }, {x: 474.79998779296875, y: 319.6000061035156, pressure: 0.4274510145187378}, {
                x: 484.79998779296875,
                y: 313.20001220703125,
                pressure: 0.4274510145187378
              }, {x: 493.20001220703125, y: 307.20001220703125, pressure: 0.4274510145187378}, {
                x: 499.20001220703125,
                y: 301.6000061035156,
                pressure: 0.4274510145187378
              }, {x: 503.60003662109375, y: 296, pressure: 0.4274510145187378}, {
                x: 506.4000244140625,
                y: 290.8000183105469,
                pressure: 0.43137258291244507
              }, {x: 507.60003662109375, y: 286, pressure: 0.43529415130615234}, {
                x: 508,
                y: 281.20001220703125,
                pressure: 0.4392157196998596
              }, {x: 507.20001220703125, y: 276.3999938964844, pressure: 0.4392157196998596}, {
                x: 505.60003662109375,
                y: 271.20001220703125,
                pressure: 0.4392157196998596
              }, {x: 502.79998779296875, y: 265.20001220703125, pressure: 0.44705885648727417}, {
                x: 499.20001220703125,
                y: 259.20001220703125,
                pressure: 0.44705885648727417
              }, {x: 495.20001220703125, y: 253.1999969482422, pressure: 0.44705885648727417}, {
                x: 490,
                y: 246.8000030517578,
                pressure: 0.44705885648727417
              }, {x: 484.4000244140625, y: 240.40000915527344, pressure: 0.44705885648727417}, {
                x: 478,
                y: 233.60000610351562,
                pressure: 0.44705885648727417
              }, {x: 470.4000244140625, y: 227.1999969482422, pressure: 0.44705885648727417}, {
                x: 462,
                y: 220.8000030517578,
                pressure: 0.44705885648727417
              }, {x: 452, y: 214.8000030517578, pressure: 0.44705885648727417}, {
                x: 440.8000183105469,
                y: 209.60000610351562,
                pressure: 0.44705885648727417
              }, {x: 428.8000183105469, y: 204.8000030517578, pressure: 0.44705885648727417}, {
                x: 416.3999938964844,
                y: 200,
                pressure: 0.44705885648727417
              }, {x: 404, y: 195.60000610351562, pressure: 0.44705885648727417}, {
                x: 391.6000061035156,
                y: 192,
                pressure: 0.44705885648727417
              }, {x: 379.20001220703125, y: 189.1999969482422, pressure: 0.44705885648727417}, {
                x: 367.6000061035156,
                y: 186.40000915527344,
                pressure: 0.44705885648727417
              }, {x: 355.6000061035156, y: 184.8000030517578, pressure: 0.44705885648727417}, {
                x: 343.6000061035156,
                y: 183.60000610351562,
                pressure: 0.44705885648727417
              }, {x: 331.6000061035156, y: 183.60000610351562, pressure: 0.44705885648727417}, {
                x: 319.20001220703125,
                y: 185.1999969482422,
                pressure: 0.44705885648727417
              }, {x: 306.8000183105469, y: 188, pressure: 0.44705885648727417}, {
                x: 294.8000183105469,
                y: 192.40000915527344,
                pressure: 0.44705885648727417
              }, {x: 283.20001220703125, y: 197.1999969482422, pressure: 0.44705885648727417}, {
                x: 272.3999938964844,
                y: 203.1999969482422,
                pressure: 0.44705885648727417
              }, {x: 262.3999938964844, y: 210.40000915527344, pressure: 0.44705885648727417}, {
                x: 252.80001831054688,
                y: 218,
                pressure: 0.44705885648727417
              }, {x: 244.39999389648438, y: 226.40000915527344, pressure: 0.45098042488098145}, {
                x: 236.80001831054688,
                y: 235.1999969482422,
                pressure: 0.45098042488098145
              }, {x: 230.39999389648438, y: 244.8000030517578, pressure: 0.45098042488098145}, {
                x: 225.20001220703125,
                y: 255.60000610351562,
                pressure: 0.45098042488098145
              }, {x: 221.20001220703125, y: 266.8000183105469, pressure: 0.45098042488098145}, {
                x: 218,
                y: 278,
                pressure: 0.45098042488098145
              }, {x: 216, y: 289.6000061035156, pressure: 0.45098042488098145}, {
                x: 214.80001831054688,
                y: 301.6000061035156,
                pressure: 0.45098042488098145
              }, {x: 214.39999389648438, y: 314, pressure: 0.45098042488098145}, {
                x: 214.80001831054688,
                y: 325.6000061035156,
                pressure: 0.45098042488098145
              }, {x: 216, y: 336, pressure: 0.45098042488098145}, {
                x: 218.39999389648438,
                y: 345.6000061035156,
                pressure: 0.45098042488098145
              }, {x: 222, y: 354.3999938964844, pressure: 0.45098042488098145}, {
                x: 226,
                y: 362,
                pressure: 0.45098042488098145
              }, {x: 230.80001831054688, y: 368.8000183105469, pressure: 0.45098042488098145}, {
                x: 236,
                y: 374.3999938964844,
                pressure: 0.45098042488098145
              }, {x: 241.60000610351562, y: 378.3999938964844, pressure: 0.45098042488098145}, {
                x: 247.60000610351562,
                y: 381.6000061035156,
                pressure: 0.45098042488098145
              }, {x: 254, y: 383.6000061035156, pressure: 0.38431376218795776}, {
                x: 259.6000061035156,
                y: 384.8000183105469,
                pressure: 0.2666666805744171
              }], objet:
              649, delta:
              746, alpha:
              0.59999999999999998
          }
          ,
          {

            color:
              'RGB(77,19,80)',
            width: 20,
            start: 1644937239649,
            line: [{x: 212.80001831054688, y: 36.400001525878906, pressure: 0}, {
              x: 212.80001831054688,
              y: 36.400001525878906,
              pressure: 0.38431376218795776
            }, {x: 212.80001831054688, y: 36.400001525878906, pressure: 0.40392160415649414}, {
              x: 212.80001831054688,
              y: 36.400001525878906,
              pressure: 0.4117647409439087
            }, {x: 212.80001831054688, y: 36.400001525878906, pressure: 0.41568630933761597}, {
              x: 216,
              y: 37.20000076293945,
              pressure: 0.41568630933761597
            }, {x: 220.80001831054688, y: 42.400001525878906, pressure: 0.41568630933761597}, {
              x: 228.80001831054688,
              y: 50,
              pressure: 0.41568630933761597
            }, {x: 236.39999389648438, y: 59.20000076293945, pressure: 0.41568630933761597}, {
              x: 245.20001220703125,
              y: 65.20000457763672,
              pressure: 0.4235294461250305
            }, {x: 254.39999389648438, y: 71.20000457763672, pressure: 0.4274510145187378}, {
              x: 264.3999938964844,
              y: 78,
              pressure: 0.4274510145187378
            }, {x: 275.20001220703125, y: 84.80000305175781, pressure: 0.43137258291244507}, {
              x: 286.8000183105469,
              y: 92.4000015258789,
              pressure: 0.43137258291244507
            }, {x: 299.20001220703125, y: 100.80000305175781, pressure: 0.43137258291244507}, {
              x: 312.3999938964844,
              y: 109.20000457763672,
              pressure: 0.43137258291244507
            }, {x: 326.3999938964844, y: 117.5999984741211, pressure: 0.43137258291244507}, {
              x: 341.6000061035156,
              y: 126,
              pressure: 0.43137258291244507
            }, {x: 357.20001220703125, y: 134.40000915527344, pressure: 0.43137258291244507}, {
              x: 373.6000061035156,
              y: 142.40000915527344,
              pressure: 0.43137258291244507
            }, {x: 390, y: 150.8000030517578, pressure: 0.43137258291244507}, {
              x: 406.8000183105469,
              y: 159.1999969482422,
              pressure: 0.43137258291244507
            }, {x: 424, y: 168, pressure: 0.43137258291244507}, {
              x: 441.6000061035156,
              y: 177.1999969482422,
              pressure: 0.43137258291244507
            }, {x: 459.20001220703125, y: 186, pressure: 0.43529415130615234}, {
              x: 477.20001220703125,
              y: 195.60000610351562,
              pressure: 0.4392157196998596
            }, {x: 495.20001220703125, y: 205.1999969482422, pressure: 0.45098042488098145}, {
              x: 513.2000122070312,
              y: 214.8000030517578,
              pressure: 0.4549019932746887
            }, {x: 531.6000366210938, y: 224.8000030517578, pressure: 0.458823561668396}, {
              x: 549.6000366210938,
              y: 234.40000915527344,
              pressure: 0.458823561668396
            }, {x: 567.2000122070312, y: 244.40000915527344, pressure: 0.458823561668396}, {
              x: 584,
              y: 254.40000915527344,
              pressure: 0.458823561668396
            }, {x: 600.4000244140625, y: 264, pressure: 0.458823561668396}, {
              x: 616.4000244140625,
              y: 273.20001220703125,
              pressure: 0.458823561668396
            }, {x: 632, y: 281.6000061035156, pressure: 0.458823561668396}, {
              x: 647.2000122070312,
              y: 290,
              pressure: 0.458823561668396
            }, {x: 661.6000366210938, y: 297.6000061035156, pressure: 0.458823561668396}, {
              x: 674.7999877929688,
              y: 305.6000061035156,
              pressure: 0.458823561668396
            }, {x: 687.6000366210938, y: 312.8000183105469, pressure: 0.458823561668396}, {
              x: 699.6000366210938,
              y: 319.20001220703125,
              pressure: 0.458823561668396
            }, {x: 710.7999877929688, y: 325.20001220703125, pressure: 0.458823561668396}, {
              x: 721.2000122070312,
              y: 330.8000183105469,
              pressure: 0.458823561668396
            }, {x: 730.4000244140625, y: 336, pressure: 0.458823561668396}, {
              x: 738.4000244140625,
              y: 340.3999938964844,
              pressure: 0.458823561668396
            }, {x: 745.6000366210938, y: 344.3999938964844, pressure: 0.458823561668396}, {
              x: 751.6000366210938,
              y: 348,
              pressure: 0.458823561668396
            }, {x: 756.4000244140625, y: 351.20001220703125, pressure: 0.458823561668396}, {
              x: 760.4000244140625,
              y: 353.20001220703125,
              pressure: 0.458823561668396
            }, {x: 763.6000366210938, y: 354.8000183105469, pressure: 0.458823561668396}, {
              x: 765.6000366210938,
              y: 356,
              pressure: 0.458823561668396
            }, {x: 767.2000122070312, y: 356.3999938964844, pressure: 0.458823561668396}, {
              x: 768,
              y: 356.8000183105469,
              pressure: 0.458823561668396
            }, {x: 768.7999877929688, y: 356.8000183105469, pressure: 0.46274513006210327}, {
              x: 769.2000122070312,
              y: 356.8000183105469,
              pressure: 0.46274513006210327
            }, {x: 769.2000122070312, y: 356.3999938964844, pressure: 0.46274513006210327}, {
              x: 769.6000366210938,
              y: 356,
              pressure: 0.46274513006210327
            }, {x: 769.6000366210938, y: 355.6000061035156, pressure: 0.46274513006210327}, {
              x: 770,
              y: 355.20001220703125,
              pressure: 0.43529415130615234
            }, {x: 770.7999877929688, y: 354.8000183105469, pressure: 0.3294117748737335}, {
              x: 771.6000366210938,
              y: 354,
              pressure: 0.23137256503105164
            }],
            objet: 649,
            delta: 517,
            alpha: 0.10000000000000001
          }, {
             color: 'RGB(184, 45, 191)', width:
              20, start:
              1644937252962, line:
              [{x: 391.6000061035156, y: 34.79999923706055, pressure: 0}, {
                x: 391.6000061035156,
                y: 34.79999923706055,
                pressure: 0.3490196168422699
              }, {x: 391.6000061035156, y: 34.79999923706055, pressure: 0.3607843220233917}, {
                x: 391.6000061035156,
                y: 34.79999923706055,
                pressure: 0.3686274588108063
              }, {x: 390.8000183105469, y: 36, pressure: 0.37254902720451355}, {
                x: 389.6000061035156,
                y: 43.20000076293945,
                pressure: 0.37254902720451355
              }, {x: 387.6000061035156, y: 53.60000228881836, pressure: 0.3803921937942505}, {
                x: 385.6000061035156,
                y: 66,
                pressure: 0.3921568989753723
              }, {x: 382.8000183105469, y: 76.4000015258789, pressure: 0.40392160415649414}, {
                x: 380.3999938964844,
                y: 87.20000457763672,
                pressure: 0.4117647409439087
              }, {x: 378, y: 98.4000015258789, pressure: 0.41960787773132324}, {
                x: 375.6000061035156,
                y: 110,
                pressure: 0.41960787773132324
              }, {x: 373.20001220703125, y: 122, pressure: 0.41960787773132324}, {
                x: 371.6000061035156,
                y: 133.1999969482422,
                pressure: 0.41960787773132324
              }, {x: 370, y: 144.8000030517578, pressure: 0.41960787773132324}, {
                x: 368.8000183105469,
                y: 156,
                pressure: 0.41960787773132324
              }, {x: 367.6000061035156, y: 166.40000915527344, pressure: 0.41960787773132324}, {
                x: 366.8000183105469,
                y: 176,
                pressure: 0.41960787773132324
              }, {x: 366.3999938964844, y: 184.40000915527344, pressure: 0.41960787773132324}, {
                x: 366,
                y: 192,
                pressure: 0.41960787773132324
              }, {x: 366, y: 198, pressure: 0.41960787773132324}, {x: 366, y: 203.1999969482422, pressure: 0.41960787773132324}, {
                x: 366,
                y: 207.1999969482422,
                pressure: 0.3294117748737335
              }, {x: 366, y: 209.60000610351562, pressure: 0.23137256503105164}], objet:
              649, delta:
              212, alpha:
              0.59999999999999998
          }
          ,
          {

            color:
              'RGB(184,45,191)',
            width: 20,
            start: 1644937253625,
            line: [{x: 374.3999938964844, y: 55.20000076293945, pressure: 0}, {
              x: 374.3999938964844,
              y: 55.20000076293945,
              pressure: 0.3686274588108063
            }, {x: 374.3999938964844, y: 55.20000076293945, pressure: 0.38823533058166504}, {
              x: 374.3999938964844,
              y: 55.20000076293945,
              pressure: 0.3921568989753723
            }, {x: 375.20001220703125, y: 54.400001525878906, pressure: 0.3960784673690796}, {
              x: 381.6000061035156,
              y: 53.60000228881836,
              pressure: 0.3960784673690796
            }, {x: 390, y: 52.79999923706055, pressure: 0.3960784673690796}, {
              x: 400.8000183105469,
              y: 53.20000076293945,
              pressure: 0.3960784673690796
            }, {x: 408.8000183105469, y: 54, pressure: 0.3960784673690796}, {
              x: 416.8000183105469,
              y: 55.20000076293945,
              pressure: 0.40000003576278687
            }, {x: 424.8000183105469, y: 56.79999923706055, pressure: 0.40392160415649414}, {
              x: 433.20001220703125,
              y: 58.79999923706055,
              pressure: 0.40392160415649414
            }, {x: 441.6000061035156, y: 60.79999923706055, pressure: 0.40392160415649414}, {
              x: 450.8000183105469,
              y: 63.20000076293945,
              pressure: 0.4078431725502014
            }, {x: 460.4000244140625, y: 65.5999984741211, pressure: 0.4117647409439087}, {
              x: 470.4000244140625,
              y: 68,
              pressure: 0.41568630933761597
            }, {x: 481.20001220703125, y: 70, pressure: 0.41960787773132324}, {
              x: 492.4000244140625,
              y: 72,
              pressure: 0.41960787773132324
            }, {x: 503.60003662109375, y: 74, pressure: 0.41960787773132324}, {
              x: 515.6000366210938,
              y: 76,
              pressure: 0.41960787773132324
            }, {x: 527.6000366210938, y: 77.5999984741211, pressure: 0.41960787773132324}, {
              x: 539.2000122070312,
              y: 78.80000305175781,
              pressure: 0.41960787773132324
            }, {x: 551.2000122070312, y: 80, pressure: 0.41960787773132324}, {
              x: 562.7999877929688,
              y: 81.20000457763672,
              pressure: 0.41960787773132324
            }, {x: 574.4000244140625, y: 82.80000305175781, pressure: 0.41960787773132324}, {
              x: 585.6000366210938,
              y: 84.4000015258789,
              pressure: 0.41960787773132324
            }, {x: 596, y: 86.80000305175781, pressure: 0.41960787773132324}, {
              x: 606,
              y: 89.20000457763672,
              pressure: 0.41960787773132324
            }, {x: 615.2000122070312, y: 92, pressure: 0.3686274588108063}, {
              x: 622.7999877929688,
              y: 94.80000305175781,
              pressure: 0.25882354378700256
            }],
            objet: 649,
            delta: 264,
            alpha: 0.59999999999999998
          }]
      }, {
         sujet: 2, start: 1644937225738, bounds: null, life: 5142, transforms: [], strokes: [{
           color: 'RGB(26, 9, 0)', width:
            2, start:
            1644937229071, line:
            [{x: 104, y: 72, pressure: 0}, {x: 104, y: 72, pressure: 0.37254902720451355}, {
              x: 103.19999694824219,
              y: 72,
              pressure: 0.38431376218795776
            }, {x: 98, y: 78, pressure: 0.3960784673690796}, {x: 92, y: 86.80000305175781, pressure: 0.3960784673690796}, {
              x: 86.40000915527344,
              y: 98.80000305175781,
              pressure: 0.3960784673690796
            }, {x: 85.19999694824219, y: 108, pressure: 0.3960784673690796}, {
              x: 84.40000915527344,
              y: 118,
              pressure: 0.3960784673690796
            }, {x: 84.40000915527344, y: 128.40000915527344, pressure: 0.3960784673690796}, {
              x: 84.40000915527344,
              y: 138.40000915527344,
              pressure: 0.3960784673690796
            }, {x: 85.60000610351562, y: 148.8000030517578, pressure: 0.3960784673690796}, {
              x: 87.19999694824219,
              y: 159.1999969482422,
              pressure: 0.3960784673690796
            }, {x: 89.60000610351562, y: 168.8000030517578, pressure: 0.3960784673690796}, {
              x: 92.40000915527344,
              y: 178,
              pressure: 0.4078431725502014
            }, {x: 96.40000915527344, y: 186.40000915527344, pressure: 0.4078431725502014}, {
              x: 100.80000305175781,
              y: 194,
              pressure: 0.4235294461250305
            }, {x: 105.60000610351562, y: 201.1999969482422, pressure: 0.4392157196998596}, {
              x: 111.19999694824219,
              y: 207.60000610351562,
              pressure: 0.4392157196998596
            }, {x: 117.60000610351562, y: 213.60000610351562, pressure: 0.44705885648727417}, {
              x: 124.40000915527344,
              y: 218.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 132, y: 222.8000030517578, pressure: 0.45098042488098145}, {
              x: 140.40000915527344,
              y: 226,
              pressure: 0.45098042488098145
            }, {x: 149.1999969482422, y: 228, pressure: 0.45098042488098145}, {
              x: 158.8000030517578,
              y: 229.1999969482422,
              pressure: 0.45098042488098145
            }, {x: 168.40000915527344, y: 229.1999969482422, pressure: 0.45098042488098145}, {
              x: 178.40000915527344,
              y: 228,
              pressure: 0.45098042488098145
            }, {x: 188, y: 225.1999969482422, pressure: 0.45098042488098145}, {
              x: 197.60000610351562,
              y: 220.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 206.39999389648438, y: 214, pressure: 0.45098042488098145}, {
              x: 214.80001831054688,
              y: 206.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 222, y: 197.1999969482422, pressure: 0.45098042488098145}, {
              x: 228.80001831054688,
              y: 186.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 234, y: 175.1999969482422, pressure: 0.45098042488098145}, {
              x: 237.60000610351562,
              y: 164.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 240, y: 154, pressure: 0.45098042488098145}, {
              x: 240.80001831054688,
              y: 144.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 240.39999389648438, y: 134.8000030517578, pressure: 0.45098042488098145}, {
              x: 238.39999389648438,
              y: 126,
              pressure: 0.45098042488098145
            }, {x: 235.20001220703125, y: 117.20000457763672, pressure: 0.45098042488098145}, {
              x: 230.80001831054688,
              y: 109.5999984741211,
              pressure: 0.45098042488098145
            }, {x: 225.60000610351562, y: 102, pressure: 0.45098042488098145}, {
              x: 219.60000610351562,
              y: 94.80000305175781,
              pressure: 0.45098042488098145
            }, {x: 213.60000610351562, y: 88, pressure: 0.45098042488098145}, {
              x: 207.20001220703125,
              y: 81.5999984741211,
              pressure: 0.45098042488098145
            }, {x: 200, y: 76, pressure: 0.45098042488098145}, {
              x: 192.8000030517578,
              y: 71.20000457763672,
              pressure: 0.45098042488098145
            }, {x: 184.8000030517578, y: 67.5999984741211, pressure: 0.45098042488098145}, {
              x: 176.8000030517578,
              y: 64.80000305175781,
              pressure: 0.45098042488098145
            }, {x: 168.40000915527344, y: 62.79999923706055, pressure: 0.45098042488098145}, {
              x: 159.60000610351562,
              y: 62,
              pressure: 0.45098042488098145
            }, {x: 150.40000915527344, y: 62, pressure: 0.45098042488098145}, {
              x: 140.8000030517578,
              y: 63.60000228881836,
              pressure: 0.45098042488098145
            }, {x: 131.1999969482422, y: 66.80000305175781, pressure: 0.45098042488098145}, {
              x: 121.19999694824219,
              y: 72,
              pressure: 0.45098042488098145
            }, {x: 111.19999694824219, y: 78, pressure: 0.45098042488098145}, {
              x: 102,
              y: 85.20000457763672,
              pressure: 0.45098042488098145
            }, {x: 93.60000610351562, y: 93.20000457763672, pressure: 0.45098042488098145}, {
              x: 86,
              y: 102,
              pressure: 0.45098042488098145
            }, {x: 79.60000610351562, y: 111.20000457763672, pressure: 0.45098042488098145}, {
              x: 74.40000915527344,
              y: 120.4000015258789,
              pressure: 0.45098042488098145
            }, {x: 70.40000915527344, y: 129.60000610351562, pressure: 0.45098042488098145}, {
              x: 67.5999984741211,
              y: 138,
              pressure: 0.45098042488098145
            }, {x: 66.4000015258789, y: 146.8000030517578, pressure: 0.45098042488098145}, {
              x: 66,
              y: 154.8000030517578,
              pressure: 0.45098042488098145
            }, {x: 67.20000457763672, y: 162.8000030517578, pressure: 0.45098042488098145}, {
              x: 70,
              y: 170.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 74.40000915527344, y: 177.60000610351562, pressure: 0.45098042488098145}, {
              x: 80.80000305175781,
              y: 184.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 88.40000915527344, y: 190.40000915527344, pressure: 0.45098042488098145}, {
              x: 98,
              y: 196,
              pressure: 0.45098042488098145
            }, {x: 108.80000305175781, y: 200.8000030517578, pressure: 0.45098042488098145}, {
              x: 121.19999694824219,
              y: 205.1999969482422,
              pressure: 0.45098042488098145
            }, {x: 134.8000030517578, y: 208.8000030517578, pressure: 0.45098042488098145}, {
              x: 149.1999969482422,
              y: 211.1999969482422,
              pressure: 0.45098042488098145
            }, {x: 164.8000030517578, y: 213.1999969482422, pressure: 0.43529415130615234}, {
              x: 180.8000030517578,
              y: 213.60000610351562,
              pressure: 0.3294117748737335
            }], objet:
            650, delta:
            634, alpha:
            0.90000000000000002
        }
          ,
          {

            color:
              'RGB(26,9,0)',
            width: 2,
            start: 1644937227980,
            line: [{x: 121.19999694824219, y: 112, pressure: 0}, {
              x: 121.19999694824219,
              y: 112,
              pressure: 0.3921568989753723
            }, {x: 121.19999694824219, y: 112, pressure: 0.4117647409439087}, {
              x: 121.19999694824219,
              y: 112,
              pressure: 0.41960787773132324
            }, {x: 121.19999694824219, y: 112, pressure: 0.4274510145187378}, {
              x: 118.40000915527344,
              y: 112.4000015258789,
              pressure: 0.4274510145187378
            }, {x: 115.19999694824219, y: 119.20000457763672, pressure: 0.4274510145187378}, {
              x: 112,
              y: 130,
              pressure: 0.4274510145187378
            }, {x: 111.60000610351562, y: 144.8000030517578, pressure: 0.4274510145187378}, {
              x: 111.60000610351562,
              y: 157.60000610351562,
              pressure: 0.4274510145187378
            }, {x: 111.19999694824219, y: 170.40000915527344, pressure: 0.4274510145187378}, {
              x: 111.19999694824219,
              y: 183.1999969482422,
              pressure: 0.4274510145187378
            }, {x: 111.19999694824219, y: 196.40000915527344, pressure: 0.4274510145187378}, {
              x: 111.19999694824219,
              y: 209.60000610351562,
              pressure: 0.43137258291244507
            }, {x: 111.60000610351562, y: 222, pressure: 0.4392157196998596}, {
              x: 112,
              y: 234.40000915527344,
              pressure: 0.4392157196998596
            }, {x: 112.40000915527344, y: 246.40000915527344, pressure: 0.4392157196998596}, {
              x: 113.60000610351562,
              y: 258,
              pressure: 0.4392157196998596
            }, {x: 115.19999694824219, y: 268.8000183105469, pressure: 0.4392157196998596}, {
              x: 117.60000610351562,
              y: 278,
              pressure: 0.4392157196998596
            }, {x: 120.40000915527344, y: 286.3999938964844, pressure: 0.4392157196998596}, {
              x: 124,
              y: 294,
              pressure: 0.4392157196998596
            }, {x: 128.40000915527344, y: 300.3999938964844, pressure: 0.4392157196998596}, {
              x: 133.60000610351562,
              y: 306,
              pressure: 0.4392157196998596
            }, {x: 139.60000610351562, y: 310.8000183105469, pressure: 0.4392157196998596}, {
              x: 146,
              y: 314.3999938964844,
              pressure: 0.4392157196998596
            }, {x: 153.60000610351562, y: 317.20001220703125, pressure: 0.4392157196998596}, {
              x: 162,
              y: 318.8000183105469,
              pressure: 0.4392157196998596
            }, {x: 171.1999969482422, y: 320, pressure: 0.4392157196998596}, {
              x: 180.8000030517578,
              y: 320.3999938964844,
              pressure: 0.4392157196998596
            }, {x: 191.1999969482422, y: 320.3999938964844, pressure: 0.4392157196998596}, {
              x: 201.60000610351562,
              y: 320.3999938964844,
              pressure: 0.4392157196998596
            }, {x: 212, y: 319.6000061035156, pressure: 0.4392157196998596}, {
              x: 222.39999389648438,
              y: 318.8000183105469,
              pressure: 0.4392157196998596
            }, {x: 233.20001220703125, y: 316.8000183105469, pressure: 0.4392157196998596}, {
              x: 243.60000610351562,
              y: 314.3999938964844,
              pressure: 0.4392157196998596
            }, {x: 253.60000610351562, y: 311.6000061035156, pressure: 0.4392157196998596}, {
              x: 263.20001220703125,
              y: 308,
              pressure: 0.4392157196998596
            }, {x: 272, y: 304.3999938964844, pressure: 0.4392157196998596}, {
              x: 280.3999938964844,
              y: 300.3999938964844,
              pressure: 0.4392157196998596
            }, {x: 288.3999938964844, y: 296, pressure: 0.4392157196998596}, {
              x: 295.6000061035156,
              y: 291.20001220703125,
              pressure: 0.4392157196998596
            }, {x: 302, y: 286.3999938964844, pressure: 0.4392157196998596}, {
              x: 308,
              y: 281.20001220703125,
              pressure: 0.4392157196998596
            }, {x: 313.20001220703125, y: 275.6000061035156, pressure: 0.4392157196998596}, {
              x: 317.6000061035156,
              y: 269.6000061035156,
              pressure: 0.4392157196998596
            }, {x: 321.20001220703125, y: 263.20001220703125, pressure: 0.4392157196998596}, {
              x: 324.3999938964844,
              y: 256.8000183105469,
              pressure: 0.4392157196998596
            }, {x: 326.8000183105469, y: 249.1999969482422, pressure: 0.4392157196998596}, {
              x: 328.3999938964844,
              y: 241.1999969482422,
              pressure: 0.4392157196998596
            }, {x: 329.20001220703125, y: 232.8000030517578, pressure: 0.4392157196998596}, {
              x: 329.6000061035156,
              y: 224,
              pressure: 0.4392157196998596
            }, {x: 329.20001220703125, y: 216, pressure: 0.4392157196998596}, {
              x: 327.6000061035156,
              y: 208,
              pressure: 0.4392157196998596
            }, {x: 324.8000183105469, y: 200, pressure: 0.4392157196998596}, {
              x: 321.20001220703125,
              y: 192,
              pressure: 0.4392157196998596
            }, {x: 316.3999938964844, y: 183.60000610351562, pressure: 0.4392157196998596}, {
              x: 311.20001220703125,
              y: 175.1999969482422,
              pressure: 0.4392157196998596
            }, {x: 304.8000183105469, y: 166.8000030517578, pressure: 0.4392157196998596}, {
              x: 298.3999938964844,
              y: 158.8000030517578,
              pressure: 0.4392157196998596
            }, {x: 291.20001220703125, y: 151.1999969482422, pressure: 0.4392157196998596}, {
              x: 284,
              y: 144,
              pressure: 0.4392157196998596
            }, {x: 276.3999938964844, y: 137.1999969482422, pressure: 0.4392157196998596}, {
              x: 268.8000183105469,
              y: 131.1999969482422,
              pressure: 0.4392157196998596
            }, {x: 260.8000183105469, y: 126, pressure: 0.4392157196998596}, {
              x: 252.39999389648438,
              y: 122,
              pressure: 0.4392157196998596
            }, {x: 242.80001831054688, y: 119.20000457763672, pressure: 0.4392157196998596}, {
              x: 232,
              y: 118,
              pressure: 0.4392157196998596
            }, {x: 220.39999389648438, y: 118, pressure: 0.4392157196998596}, {
              x: 207.60000610351562,
              y: 120,
              pressure: 0.4392157196998596
            }, {x: 194.8000030517578, y: 123.20000457763672, pressure: 0.4392157196998596}, {
              x: 181.1999969482422,
              y: 128.40000915527344,
              pressure: 0.4392157196998596
            }, {x: 168, y: 134, pressure: 0.4392157196998596}, {
              x: 155.1999969482422,
              y: 140.40000915527344,
              pressure: 0.4392157196998596
            }, {x: 142.40000915527344, y: 148, pressure: 0.4392157196998596}, {
              x: 130.40000915527344,
              y: 156,
              pressure: 0.4392157196998596
            }, {x: 119.19999694824219, y: 164.8000030517578, pressure: 0.4392157196998596}, {
              x: 108.80000305175781,
              y: 174,
              pressure: 0.4392157196998596
            }, {x: 99.60000610351562, y: 183.60000610351562, pressure: 0.4392157196998596}, {
              x: 91.19999694824219,
              y: 193.60000610351562,
              pressure: 0.4392157196998596
            }, {x: 84.40000915527344, y: 204, pressure: 0.44705885648727417}, {
              x: 78.80000305175781,
              y: 214.40000915527344,
              pressure: 0.45098042488098145
            }, {x: 75.19999694824219, y: 224, pressure: 0.45098042488098145}, {
              x: 72.80000305175781,
              y: 233.1999969482422,
              pressure: 0.45098042488098145
            }, {x: 71.60000610351562, y: 242, pressure: 0.45098042488098145}, {
              x: 71.60000610351562,
              y: 250.8000030517578,
              pressure: 0.45098042488098145
            }, {x: 72.80000305175781, y: 258.8000183105469, pressure: 0.45098042488098145}, {
              x: 75.60000610351562,
              y: 265.6000061035156,
              pressure: 0.45098042488098145
            }, {x: 79.60000610351562, y: 272, pressure: 0.45098042488098145}, {
              x: 85.19999694824219,
              y: 277.20001220703125,
              pressure: 0.45098042488098145
            }, {x: 91.60000610351562, y: 282, pressure: 0.45098042488098145}, {
              x: 99.19999694824219,
              y: 285.6000061035156,
              pressure: 0.45098042488098145
            }, {x: 107.60000610351562, y: 288.3999938964844, pressure: 0.45098042488098145}, {
              x: 116.40000915527344,
              y: 290.3999938964844,
              pressure: 0.4431372880935669
            }, {x: 125.19999694824219, y: 291.20001220703125, pressure: 0.3450980484485626}],
            objet: 650,
            delta: 799,
            alpha: 0.90000000000000002
          }, {
             color: 'RGB(26, 9, 0)', width:
              2, start:
              1644937230269, line:
              [{x: 208.80001831054688, y: 42.400001525878906, pressure: 0}, {x: 202, y: 42, pressure: 0.3921568989753723}, {
                x: 194.8000030517578,
                y: 42,
                pressure: 0.4078431725502014
              }, {x: 188, y: 43.20000076293945, pressure: 0.41568630933761597}, {
                x: 185.1999969482422,
                y: 45.60000228881836,
                pressure: 0.41568630933761597
              }, {x: 183.1999969482422, y: 49.20000076293945, pressure: 0.41568630933761597}, {
                x: 181.60000610351562,
                y: 53.60000228881836,
                pressure: 0.41568630933761597
              }, {x: 180.40000915527344, y: 59.20000076293945, pressure: 0.41568630933761597}, {
                x: 178.8000030517578,
                y: 67.20000457763672,
                pressure: 0.4274510145187378
              }, {x: 178, y: 76.4000015258789, pressure: 0.4274510145187378}, {
                x: 177.1999969482422,
                y: 86.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 176.8000030517578, y: 96.4000015258789, pressure: 0.4274510145187378}, {
                x: 177.1999969482422,
                y: 106.80000305175781,
                pressure: 0.4274510145187378
              }, {x: 178.40000915527344, y: 116.80000305175781, pressure: 0.4274510145187378}, {
                x: 180.8000030517578,
                y: 126.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 183.60000610351562, y: 135.1999969482422, pressure: 0.4274510145187378}, {
                x: 187.1999969482422,
                y: 143.60000610351562,
                pressure: 0.4274510145187378
              }, {x: 191.60000610351562, y: 152, pressure: 0.4274510145187378}, {
                x: 196.39999389648438,
                y: 159.1999969482422,
                pressure: 0.4274510145187378
              }, {x: 202.39999389648438, y: 166, pressure: 0.4274510145187378}, {
                x: 209.20001220703125,
                y: 172,
                pressure: 0.4274510145187378
              }, {x: 217.20001220703125, y: 177.1999969482422, pressure: 0.4274510145187378}, {
                x: 226,
                y: 181.1999969482422,
                pressure: 0.4274510145187378
              }, {x: 235.60000610351562, y: 184, pressure: 0.4274510145187378}, {x: 246, y: 186, pressure: 0.4274510145187378}, {
                x: 256.3999938964844,
                y: 187.1999969482422,
                pressure: 0.4274510145187378
              }, {x: 267.20001220703125, y: 186.40000915527344, pressure: 0.4274510145187378}, {
                x: 277.6000061035156,
                y: 184,
                pressure: 0.4274510145187378
              }, {x: 288, y: 179.60000610351562, pressure: 0.4274510145187378}, {
                x: 297.6000061035156,
                y: 2.60000610351562,
                pressure: 0.4274510145187378
              }, {x: 307.6000061035156, y: 166.40000915527344, pressure: 0.4274510145187378}, {
                x: 316.3999938964844,
                y: 158,
                pressure: 0.4274510145187378
              }, {x: 324, y: 149.60000610351562, pressure: 0.4274510145187378}, {
                x: 330,
                y: 140.40000915527344,
                pressure: 0.4274510145187378
              }, {x: 335.20001220703125, y: 131.1999969482422, pressure: 0.4274510145187378}, {
                x: 338.8000183105469,
                y: 122.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 340.8000183105469, y: 114.4000015258789, pressure: 0.4274510145187378}, {
                x: 341.6000061035156,
                y: 99.5999984741211,
                pressure: 0.4274510145187378
              }, {x: 340.3999938964844, y: 92.80000305175781, pressure: 0.4274510145187378}, {
                x: 338,
                y: 85.5999984741211,
                pressure: 0.4274510145187378
              }, {x: 334.3999938964844, y: 78.80000305175781, pressure: 0.4274510145187378}, {
                x: 329.6000061035156,
                y: 72.4000015258789,
                pressure: 0.4274510145187378
              }, {x: 324.3999938964844, y: 66.80000305175781, pressure: 0.4274510145187378}, {
                x: 318.3999938964844,
                y: 61.20000076293945,
                pressure: 0.4274510145187378
              }, {x: 312, y: 56, pressure: 0.4274510145187378}, {
                x: 304.8000183105469,
                y: 51.20000076293945,
                pressure: 0.4274510145187378
              }, {x: 297.20001220703125, y: 46.79999923706055, pressure: 0.4274510145187378}, {
                x: 289.20001220703125,
                y: 42.79999923706055,
                pressure: 0.4274510145187378
              }, {x: 281.20001220703125, y: 39.20000076293945, pressure: 0.4274510145187378}, {
                x: 272.8000183105469,
                y: 36.400001525878906,
                pressure: 0.4274510145187378
              }, {x: 264.3999938964844, y: 34.400001525878906, pressure: 0.4274510145187378}, {
                x: 256,
                y: 33.20000076293945,
                pressure: 0.4274510145187378
              }, {x: 247.60000610351562, y: 32.400001525878906, pressure: 0.4274510145187378}, {
                x: 239.20001220703125,
                y: 32,
                pressure: 0.4274510145187378
              }, {x: 230.80001831054688, y: 32.79999923706055, pressure: 0.4274510145187378}, {
                x: 222.39999389648438,
                y: 34.400001525878906,
                pressure: 0.4274510145187378
              }, {x: 214.80001831054688, y: 38, pressure: 0.4274510145187378}, {
                x: 207.20001220703125,
                y: 42.79999923706055,
                pressure: 0.4274510145187378
              }, {x: 200, y: 48.79999923706055, pressure: 0.4274510145187378}, {
                x: 193.60000610351562,
                y: 56,
                pressure: 0.4274510145187378
              }, {x: 187.60000610351562, y: 64.4000015258789, pressure: 0.43137258291244507}, {
                x: 182.40000915527344,
                y: 73.20000457763672,
                pressure: 0.43137258291244507
              }, {x: 178, y: 82.4000015258789, pressure: 0.43137258291244507}, {
                x: 175.1999969482422,
                y: 91.5999984741211,
                pressure: 0.43137258291244507
              }, {x: 2.60000610351562, y: 101.20000457763672, pressure: 0.43137258291244507}, {
                x: 172.8000030517578,
                y: 110.80000305175781,
                pressure: 0.43137258291244507
              }, {x: 2.1999969482422, y: 120.4000015258789, pressure: 0.43137258291244507}, {
                x: 174.8000030517578,
                y: 129.60000610351562,
                pressure: 0.43137258291244507
              }, {x: 178, y: 138, pressure: 0.43137258291244507}, {
                x: 183.1999969482422,
                y: 146.40000915527344,
                pressure: 0.43137258291244507
              }, {x: 189.60000610351562, y: 154, pressure: 0.41568630933761597}, {
                x: 197.60000610351562,
                y: 160.40000915527344,
                pressure: 0.3019607961177826
              }], objet:
              650, delta:
              611, alpha:
              0.90000000000000002
          }
        ]
      }
      ,
      {
         sujet:
          2, start:
          1644937257315, bounds:
          null, life:
          null, transforms:
          [], strokes:
          []
      }
    ]
  },
  apiFiles: 'https://memigration.info',
  access:['hidden', 'private', 'public', 'link']
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
