import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {RestService} from '../rest/rest.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.css']
})
export class FileUploadComponent implements OnInit {

  fileName = '';
  uploadProgress: number;
  uploadSub: Subscription;
@Input() userId: number;
  @Output() save = new EventEmitter<string>();
  constructor(private restService: RestService) {}

  onFileSelected(event): void {

    const file: File = event.target.files[0];

    if (file) {

      this.fileName = file.name;

      const formData = new FormData();

      formData.append('uploadedFile', file, this.fileName);
      formData.append('userId', this.userId.toString());
      const upload$ = this.restService.fileUpload(formData);

      upload$.subscribe(a=>{

        this.save.emit(a);

      });
    }
  }

  ngOnInit(): void {
  }
}
