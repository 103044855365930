<canvas [style.display]="sel ? 'none' : 'block'" #ca [width]="w" [height]="h">

</canvas>
<div (touchmove)="$event.preventDefault()" [style.height.px]="h-hm" [style.width.px]="w" [style.paddingTop.px]="hm"
     class="con">


  <ion-icon *ngIf="!main" class="cl" name="menu" [mat-dialog-close]="false"></ion-icon>

  <ng-container *ngIf="!ppl">

  <app-svg [type]="'player'"
           [style.marginLeft.px]="wm"
           [start]="curTime"
           [style.width.px]="wwm"
           [playing]="pl"
           [style.height.px]="hhm -48"
           (pos)="ini($event)"
           [width]="wwm"
           [height]="hhm"
           [mat]="mat"
           [ww]="ww">
  </app-svg>


  <div class="bt" *ngIf="end > start" [style.width.px]="w">
    <div>
      <ion-icon class="nm" *ngIf="pl" name="pause-circle" (click)="stop()"></ion-icon>
      <ion-icon class="nm" *ngIf="!pl" (click)="contin()" name="play-circle"></ion-icon>
    </div>
    <div class="in">
      <div class="pro"
           [style.background]="'linear-gradient(90deg, rgba(2,0,36,1) '+per.toString()+'%, rgba(160,160,155,1) '+per.toString()+'%, rgba(160,160,155,1) 100%)'"></div>
      <div class="pui">
        <div>
          <div [style.left.%]="per" class="inmas">
            <ion-icon *ngIf="pl" name="pause-circle"
                      (pan)="drging($event)"
                      (press)="stDr($event)"
            ></ion-icon>

            <ion-icon *ngIf="!pl" name="play-circle"
                      (pan)="drging($event)"
                      (click)="contin()"
            ></ion-icon>

          </div>
        </div>
      </div>
    </div>

    <div class="btnsJ">

      <span [class]="speed === 1 ? 'act' : '' " (click)="spCh(1)">1X</span>
      <span [class]="speed === 2 ? 'act' : '' " (click)="spCh(2)">2X</span>
      <span [class]="speed === 4 ? 'act' : '' " (click)="spCh(4)">4X</span>
    </div>
  </div>
  </ng-container>
 <!-- -->

  <app-paint [style.display]="ppl ? 'block' : 'none'" *ngIf="main && size" (colorCh)="colorCh($event)"
             [userR]="userR"        [userO]="authService.userOb" (draw)="draw($event)" [sujet]="currentsujet" [par]="mClosedP"
             [size]="size"></app-paint>

</div>
