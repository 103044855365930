import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../services/auth.service';
import {User} from '../user/user';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  formReg: boolean;
  emOk = false;

  @Output() logged = new EventEmitter<any>();
  found = false;

  constructor(
    private fb: FormBuilder,
    private userService: AuthService
  ) {
  }


  ngOnInit(): void {

    this.form = this.fb.group({
      email: [
        '',
        [Validators.required, Validators.email]
      ],
      password: [
        '',
        Validators.required
      ]
    });
  }

  onSubmit(): void {
    const formData = this.form.getRawValue();
    if (!this.emOk) {
      this.emOk = true;
      this.find().subscribe(
        f => {

          if (f === 1) {
this.found = true;

          }else{


          }
        }
      );

    } else {
      this.userService.login(formData.email, formData.password)
        .subscribe(u => {
          console.log('ffff',u);
          localStorage.setItem('access_token', u.access_token);
          const ob = this.userService.check();
          this.userService.userOb = ob;
          ob.subscribe(o => {
            localStorage.setItem('user_id', o.id);
            localStorage.setItem('user_name', o.name);
            const user = new User(o.id, o.name);
            this.logged.emit(user);
          });

        });
    }
  }

  reg() {
    if(!this.emOk) {
      this.emOk = true;
      this.find().subscribe(
        f => {

          if (f === 1) {
            this.found = true;

          }
        }
      );
    }else{
      const formData = this.form.getRawValue();
      this.userService.register({first_name: formData.email,
        email: formData.email,
        password: formData.password,
        password_confirmation:formData.password})
        .subscribe(
          u=>{
console.log(u);
          }
        )


    }

  }

  find(): Subject<number> {
    const formData = this.form.getRawValue();
    const ret = new Subject<number>();
    this.formReg = true;
    this.userService.find(formData.email).subscribe(
      a => {
        ret.next(a.records.length);
      }
    );
    return ret;
  }
}
