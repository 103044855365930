import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {SvgComponent} from '../svg/svg.component';
import {LineS} from '../paper/lineS';
import {RestService} from '../rest/rest.service';
import {interval, Observable, Subject} from 'rxjs';
import {Sujet} from '../paper/sujet';
import paper from 'paper';
import {getStroke} from 'perfect-freehand';

import {PathS} from '../paper/pathS';
import {DialogData} from '../guest/start-dialog/start-dialog.component';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {HelpComponent} from '../help/help.component';
import {AuthService} from '../services/auth.service';
import {SharpService} from '../services/sharp.service';
import {User} from '../user/user';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.css']
})
export class PlayerComponent implements OnInit {
  @ViewChild('ca') canvasElement: ElementRef;
  h: number;
  @Input() main: string;
  @Input() userR: User;
  @Input() dataD: DialogData;
  @Output() pos = new EventEmitter<any>();
  @Input() command: Observable<string>;
  @Output() playeed = new EventEmitter<any>();
  w: number;
  hm: number;
  wm: number;
  mat: paper.Matrix;
  size: any;
  start: number;
  end: number;
  pl: boolean;
  curTime: number;
  per: number;
  currentsujet: Sujet;
  playing: Subject<number>;
  emi: Subject<number>;
  coef: number;
  sub: any;
  ww: number;
  hh: number;
  wwm: number;
  public mClosedP = new Subject<number>();
  mClosedC = false;
  public speed = 1;
  played = 0;
  newD = 0;
  curPath: PathS;
  curGs: LineS;
  calcu = 0;
  user: User;
  userO: Observable<User>;
  /*




   */
  hhm: number;
  logg = false;
  ppl = false;
  sel = true;
  private svg: SvgComponent;
  private sta: number;
  private dragging: boolean;
  private drawing = 0;
  private newP: any[];
  private tempAr: any[];
  private currentObjet: LineS;
  private openDialog: MatDialogRef<HelpComponent, any>;
  private pap: paper.Item;
  private lnEnd: number;

  constructor(private sharpService: SharpService, private authService: AuthService, public restService: RestService, public menuDialog: MatDialog) {


  }

  ini(sv: SvgComponent): void {
    this.svg = sv;
    this.svg.gsR = [];
    this.lnEnd = Date.now();
    this.svg.gs = [];
    this.svg.colors = [];
    this.currentsujet = this.dataD.sujet;


    if (this.dataD.sujet.objet.length > 0) {
      this.start = this.dataD.sujet.objet[0].start;

      this.loadLines(this.dataD.sujet.objet[0], 0);
    }
  }

  getSvgPathFromStroke(stroke: any[]): string {
    if (!stroke.length) {
      return '';
    }

    const d = stroke.reduce(
      (acc, [x0, y0], i, arr) => {
        const [x1, y1] = arr[(i + 1) % arr.length];
        acc.push(x0, y0, (x0 + x1) / 2, (y0 + y1) / 2);
        return acc;
      },
      ['M', ...stroke[0], 'Q']
    );

    d.push('Z');
    return d.join(' ');
  }

  loadLines(gid, n): void {
    const id = gid.id;
    /***
     * loading lines from DB
     *
     *
     */

    let end = 0;
    let sta = 0;

    this.restService.loadItem('strokes', 'filter=objet,eq,' + id).subscribe(gg => {


      if (gg.length > 0) {
        const g = gg.sort((a, b) => (a.start < b.start ? -1 : 1));
        const li = new LineS(n);
        this.lnEnd = Math.min(g[0].start + g[0].delta, this.lnEnd + 1000);


        if (gid.transforms) {
          li.transforms = JSON.parse(gid.transforms).map(nu => {
            return {
              start: nu[0],
              delta: nu[1],
              matrix: new paper.Matrix(nu[2], 0, 0, nu[2], nu[3], nu[4])
            };

          });
        } else {
          li.transforms = [];
        }
        if (n === 0) {
          /***
           *
           *
           * objet start = first line start
           */
          li.start = g[0].start;
        } else {
          const laast = this.svg.gs[n - 1];
          li.start = laast.start + laast.delta + 1000;
          // console.log('st',laast, Math.floor(li.start/360000));
        }

        if (this.start === 0) {
          this.start = g[0].start;
        }
        li.paths = [];
        li.delta = 0;
        g.forEach((u, i) => {
          // console.log(u.line.indexOf('{'));

          this.calcu += u.delta;
          const lin = new PathS(u.alpha, u.width);
          lin.color = u.color;
          if (u.width > 6 && u.alpha < 0.4) {
            lin.filter = 'url(#f1)';
          }
          lin.id = u.id;
          lin.delta = u.delta;
          if (i === 0) {
            lin.start = u.start;
          } else {
            const uu = li.paths[i - 1];
            //

            lin.start = Math.min(this.lnEnd + 1000, u.start);
            // console.log('old', u.start - lin.start);
          }
          this.lnEnd = lin.start + u.delta;
          const line = JSON.parse(u.line);


          lin.line = line.map(t => {
            return {x: t.x * this.coef, y: t.y * this.coef, pressure: t.pressure * this.coef};

          });


          end = lin.start + u.delta;
          const opts = {
            size: u.width,
          };
          const ar = getStroke(lin.line, opts);
          lin.d = this.getSvgPathFromStroke(ar);
          li.paths.push(lin);

        });


        const last = g[g.length - 1];
        //   console.log(this.lnEnd - this.end ,this.lnEnd ,this.end );
        //  this.end = this.lnEnd;

        li.delta = this.lnEnd - li.start;

        this.svg.gs.push(li);


        console.log(this.svg.gs.length);
        if (!this.pl) {
          this.nn(-100);
          this.pl = true;
        }
      }

      if (n < this.dataD.sujet.objet.length - 1) {

        this.loadLines(this.dataD.sujet.objet[n + 1], n + 1);

      }

    });

  }

  saveWEBP() {

    this.sharpService.publish(this.svg.gs, this.pap, this.dataD.sujet.name, this.dataD.sujet.id, this.ww, this.hh);


  }

  ngAfterViewInit() {

    paper.setup(this.canvasElement.nativeElement);
    paper.project.activeLayer.addChild(new paper.Group());
    this.pap = paper.project.activeLayer.children[0];
  }

  ngOnInit(): void {
    this.pos.emit(this);
    this.command.subscribe(a => {
      this.help(a);

    });
    this.playing = new Subject<number>();

    this.per = 0;
    this.end = Date.now();
    this.h = window.innerHeight;
    this.w = window.innerWidth;
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--act-margL', ((this.w - 238) / 2).toString() + 'px');
    console.log(this.dataD.sujet.objet);
    this.dataD.sujet.objet = this.dataD.sujet.objet.sort((a, b) => {
    if (
          a.type === 'temp' ) {
          return -1;
        } else {
          return 1;
        }
        if (a.start < b.start){
return 1;
        } else{
          return -1;
        }

      }
    );
    console.log(this.dataD.sujet.objet);
    const objets = this.dataD.sujet.objet;

    let ww;
    let hh;
    if (objets.length > 0) {

      objets.forEach((f, i) => {
        if (f.start > this.end + 800 && i > 0) {
          f.start = this.end + 800;

        }

        console.log(f.start + f.delta - this.end);
        this.end = f.start + f.delta;
      });
      this.start = objets[0].start;
    }
    if (this.dataD.lis.size) {

      const sa = this.dataD.lis.size.split(',').map(a => {
        return Number(a);
      });
      hh = sa[1];
      ww = sa[0];
      this.ww = ww;
      this.hh = hh;
    } else {

      hh = this.h;
      ww = this.w;
      this.ww = ww;
      this.hh = hh;
    }


    if (ww > hh) {

      /* horisontal
       */
      if (this.w > this.h) {
        this.wm = 0;
        this.hm = (this.h - this.h * this.w / ww) * 0.5;
        this.coef = this.w / ww;

      } else {
        this.wm = 0;
        this.hm = (this.h - hh * this.w / ww) * 0.5;
        this.coef = this.w / ww;
      }
    } else {

      if (this.w > this.h) {
        this.wm = (this.w - ww * this.h / hh) * 0.5;

        this.hm = 0;
      } else {

        this.wm = (this.w - ww * this.h / hh) * 0.5;

        this.hm = 0;
      }
      this.coef = this.h / hh;
    }
    this.wwm = this.w - this.wm * 2;
    this.hhm = this.h - this.hm * 2;
    this.size = {};
    this.size.wm = this.wm;
    this.size.wwm = Math.min(this.wwm, this.w);
    this.size.h = this.h;


    this.playing.subscribe(
      o => {

        this.svg.gs.forEach((a, i) => {

            if (a.start <= o && a.start + a.delta >= o && (this.svg.gsR.length === 0 || a.id !== this.svg.gsR[this.svg.gsR.length - 1].id)) {
              a.trans = 'matrix(1,0,0,1,0,0)';
              this.svg.gsR.push(a);
              this.curGs = a;
              this.curPath = a.paths[0];

            }
            if (a.start + a.delta < o && a.data !== 'done') {
              a.data = 'done';

              a.trans = this.svg.gsR[this.svg.gsR.length - 1].trans;
              // this.svg.gsR = [];
            }
          }
        );
        if (this.svg.gsR.length > 0) {

          this.svg.gsR.forEach(rt => {
            let tra;
            /***
             * scale and move group
             *
             *
             *
             */
            rt.transforms.forEach((t, i) => {

              if (t.start < o && o < t.start + t.delta) {


                let tra0;

                if (i > 0 && rt.transforms[i - 1]) {

                  tra0 = rt.transforms[i - 1].matrix;

                } else {
                  tra0 = new paper.Matrix();
                }
                tra = t.matrix;

                const co = (o - t.start) / t.delta;
                const tt = tra0.a + (tra.a - tra0.a) * co;

                const nu = new paper.Matrix(tt, 0, 0, tt,
                  tra0.tx + (tra.tx - tra0.tx) * co,
                  tra0.ty + (tra.ty - tra0.ty) * co);
                rt.trans = 'matrix('.concat([nu.a, nu.b, nu.c, nu.d, nu.tx, nu.ty].join(' '), ')');
              }
            });
          });
          /***
           *
           *
           *
           */


          this.curGs.paths.forEach((u, i) => {

            if (o >= u.start && o < u.start + u.delta) {
              // if ( i > 0) {
              //  const ux = gsR.paths[i - 1];
              //
              // }

              if (u.build === 0) {
                this.curPath = u;
                u.build = 1;

                this.curPath.build = 1;
              }
              //  break;
            } else {
              if (u.build === 1) {
              }
              if (o >= u.start + u.delta) {
                u.build = 0;
                u.dR = u.d;
              } else {
                u.dR = '';
              }
            }


          });

          // if (this.curPath.build === 1) { }
          const sl = this.curPath.line.slice(0, Math.ceil(this.curPath.line.length * (o - this.curPath.start) / this.curPath.delta));


          const opts = {
            size: this.curPath.radius * this.coef,
          };

          const ar = getStroke(sl, opts);
          this.curPath.dR = this.getSvgPathFromStroke(ar);


        }
      }
    );

  }

  nn(vl) {
    this.pl = true;

    this.sta = Date.now();
    if (this.start + vl < this.end) {


      this.svg.playingObs.next(true);

      const num = interval(10);
      this.sub = num.subscribe(n => {

        this.curTime = (Date.now() - this.sta) * this.speed + this.start + vl;
        this.per = ((Date.now() - this.sta) * this.speed + vl) / (this.end - this.start) * 100;
        this.playing.next(this.curTime);

        if (this.curTime >= this.end) {
          this.goEnd();
        }
      });


    }
  }

  stop() {
    if (this.pl) {
      this.sub.unsubscribe();
      this.ppl = true;
      this.pl = false;
    }
  }

  contin() {
    this.pl = true;
    this.ppl = false;

    this.nn(this.curTime - this.start);
  }

  stDr($event: any) {
    this.sub.unsubscribe();
    this.pl = false;
    this.dragging = true;
  }

  drging($event: any) {
    if (!this.pl) {

      const ww = this.w - 32 - 24;

      this.per = Math.min(100, Math.max(0, this.per + $event.velocityX * 1000 / ww));
      this.curTime = this.start + this.per / 100 * (this.end - this.start);
    }
  }


  draw(se): void {
    console.log(se);
    this.sel = se;

  }

  draw1(event: any): void {
    if (event && event.length === 5) {
      if (!this.svg.newL) {
        this.currentObjet = new LineS(45);

        this.svg.newL = [this.currentObjet];
      }
      if (event[4] === 0) {
        const brush = event[3];
        this.tempAr = [{x: event[0], y: event[1], pressure: event[2]}];
        const p = new PathS(brush.color.alpha, brush.radius);
        this.currentObjet.paths.push(p);
        p.color = 'RGB(' + brush.color.red + ',' + brush.color.green + ',' + brush.color.blue + ')';
        const opts = {
          size: p.radius,
        };
        const ar = getStroke(this.tempAr, opts);
        p.d = this.getSvgPathFromStroke(ar);
      }
      if (event[4] === 1) {
        this.tempAr.push({x: event[0], y: event[1], pressure: event[2]});
        const p = this.currentObjet.paths[this.currentObjet.paths.length - 1];
        const opts = {
          size: p.radius,
        };
        const ar = getStroke(this.tempAr, opts);
        p.d = this.getSvgPathFromStroke(ar);
      }
      if (event[4] === 2) {
        this.tempAr.push({x: event[0], y: event[1], pressure: event[2]});
        const p = this.currentObjet.paths[this.currentObjet.paths.length - 1];
        const opts = {
          size: p.radius,
        };
        const ar = getStroke(this.tempAr, opts);
        p.d = this.getSvgPathFromStroke(ar);

      }
    }
  }

  startDraw() {
    this.drawing = (this.drawing + 1) % 2;
    this.mClosedP.next(this.drawing);
    this.svg.gs.forEach(u => {
      u.data = 'faded';
    });

  }

  help(lis: string): void {

    this.openDialog = this.menuDialog.open(HelpComponent, {
      panelClass: 'help',
      data: {
        case: this.main, sujet: this.dataD.sujet, user: this.dataD.user,
        lis: lis, auth: this.authService.userOb
      }
    });
    this.openDialog.afterClosed().subscribe(result => {
      if (result) {
        // console.log('res', result);
        /*
        if nu==1 - new drawing
        if nu==0 - new drawing
         */
        this.curTime = this.end;
        this.goEnd();
        this.ppl = true;
        this.drawing = (this.drawing + 1) % 2;
        this.mClosedP.next(this.drawing);
        if (result.nu === 1) {
          this.svg.gs.forEach(u => {
            u.data = 'faded';
          });
        }

      }
    });
  }


  spCh(number: number) {
    this.speed = number;
  }


  colorCh($event: any) {
    this.svg.colors.push($event);

  }

  private goEnd() {
    this.svg.playingObs.next(false);
    this.pl = false;
    // this.ppl = true;
    this.played = 1;
    this.playeed.emit(1);
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.curTime = this.start - 1;
  }
}
