export class PathS {
  id: number;
  color: string;
  colorO: string;
  d: string;
  dR: string;
  filter: string;
  radius: number;
  alpha: number;
  start: number;
  startR: number;
  line: any[];
  delta: number;
  dbId?: number;
  build: number;
  visible: boolean;

  constructor(alpha, radius, prevLine = null, trans = null) {
    this.build = 0;
    this.visible = true;
    this.startR = Date.now();
    if (prevLine) {
      this.start = Math.min(Date.now(), prevLine.start + prevLine.delta + 800);
    } else {
      this.start = Date.now();

    }
    this.alpha = alpha;
    this.filter = 'none';
    this.radius = radius;
    this.dR = '';
    this.line = [];
  }
}
