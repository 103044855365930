import {User} from '../user/user';
import {Sujet} from '../paper/sujet';

export class Story {
    private user_db: number;
    public user: User;
    sujet: Sujet[];
    public story_db: number;
    description: string;
public id: number;
  time: Date;
  timeString: string;
  seconds: number;
  name: string;
  access: string;
    constructor(loc: number) {
this.user_db = loc;
    }

}
