<div #svgg>
<app-svg [type]="'paint'"
  [style.marginLeft.px]="size.wm+10"
  [start]="0"
  [style.width.px]="size.wwm"
  playing="false"
  [style.height.px]="size.h"
  (pos)="ini($event)"
  [width]="size.wwm"
  [height]="size.h"
(touchstart)="sel($event)"
         (touchend)="usel($event)"
  [ww]="0" >
</app-svg></div>
<div [style.display]="paint ? 'block' : 'none'"  class="cont" #cont
     [style.left.px]="size.wm"
     (pinch)="scale($event)" (pinchstart)="pinchS($event)"  (pinchend)="pout($event)"
     [style.width.px]="size.wwm"
(touchend)="endT($event)"
     (mouseup)="endT($event)"
     [style.height.px]="size.h"

></div>


<div class="menuP" *ngIf="parp === 1" >

  <div *ngIf="brushes" [class]="drawing  ? 'act mat-menu-content' : 'mat-menu-content'">

    <button class="mat-menu-item" (click)="openColorDialog()">
      <ion-icon name="color-filter"></ion-icon>

    </button>

    <button *ngFor="let brush of brushes" [ngClass]="brush.active  ? 'mat-menu-item bact' : 'mat-menu-item'"  value="brush" (click)="startDraw(brush)" >
      <svg  x="0px" y="0px" width="48px" height="24px"
            viewBox="0 0 23.72 12.75" style="enable-background:new 0 0 23.72 12.75;" xml:space="preserve">
 <!--<rect  *ngIf="brush.active" [attr.fill]="'RGBA('+brush.color.red+','+brush.color.green+','+brush.color.blue+','+brush.color.alpha*2+')'"  x="0" width="4" height="12"/>-->
        <circle [attr.fill]="'RGBA('+brush.color.red+','+brush.color.green+','+brush.color.blue+','+brush.color.alpha*2+')'" class="st0" cx="6.52" cy="6" [attr.r]="brush.radius / 5"/>
        <rect class="st1" x="15.65" width="8.06" height="12"/>
        <polygon [attr.fill]="'RGB('+brush.color.red+','+brush.color.green+','+brush.color.blue+')'" [attr.points]="'6.71,'+(6+brush.radius / 5).toString()+' 15.88,11 15.88,0.5 6.71,'+(6-brush.radius / 5).toString()"/>
</svg>


    </button>


  </div>


</div>
<div *ngIf="parp === 1 && objet "  class="di flex-r" [style.right.px]="60">
  <ion-icon *ngIf="!paint"  (click)="move(true)" name="brush"></ion-icon>
  <ion-icon *ngIf="paint" (click)="move(false)" name="move"></ion-icon>
  <ion-icon (click)="undo()" name="arrow-undo-outline"></ion-icon>
    <ion-icon (click)="newO()" name="add-circle-outline"></ion-icon>
 <ion-icon (click)="save()" name="save-outline"></ion-icon>
  <ion-icon (click)="del()" name="trash"></ion-icon>

</div>
