import {Component, OnInit, NgZone, Input} from '@angular/core';

declare const annyang: any;
declare const WSAudioAPI: any;


@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.css']
})
export class AudioComponent implements OnInit {
  @Input() role;
  player: any;
  streamer: any;
  streaming: boolean;
  playing: boolean;

  constructor(private ngZone: NgZone) {
  }

  ngOnInit(): void {
    this.streaming = false;
    this.playing = false;
    if(this.role === 'stream') {
      this.streamer = new WSAudioAPI.Streamer({
        server: 'wss://facereplays23.herokuapp.com?host=stream', codec: {
          sampleRate: 24000,
          channels: 1,
          app: 2048,
          frameDuration: 20,
          bufferSize: 4096
        }
      });
    }
    if(this.role === 'player') {
      this.player = new WSAudioAPI.Player({
        server: 'wss://facereplays23.herokuapp.com?host=player', codec: {
          sampleRate: 24000,
          channels: 1,
          app: 2048,
          frameDuration: 20,
          bufferSize: 4096
        }
      });
    }

  }

  getStreaming() {
    return this.streaming;
  }
  getPlaying() {
    return this.playing;
  }
  play(nu: boolean) {
    this.playing = nu;
    if (nu) {
      this.player.start();
    } else {
      this.player.stop();
    }
  }
  stream(nu: boolean) {
    this.streaming = nu;
    if (nu) {
      this.streamer.start();
    } else {
      this.streamer.stop();
    }

  }
}
