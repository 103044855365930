import {Matrix3d} from './matrix3d';
import {Vector} from './vector';

export class Quat {

    constructor(public w: number, public x: number, public y: number, public z: number) {
        this.x = x;
        this.y = y;
        this.z = z;
        this.w = w;
        this.vector = this.toVector();
       // this.vectorX=this.rotateX(0,0,Math.PI*0.5);
    }
 public vector:Vector;
    public vectorX:Vector;
    public toVector() {

        return new Vector(2 * (this.x * this.z + this.w * this.y),
            2 * (this.y * this.z - this.w * this.x),
            1 - 2 * (this.x * this.x + this.y * this.y)).normalize(1);


    }

    public fromEuler(phi: number, theta: number, psi: number, order: string): Quat {
        const rad = Math.PI / 180;

        const _x = theta * 0.5 * rad;
        const _y = psi * 0.5 * rad;
        const _z = phi * 0.5 * rad;

        const cX = Math.cos(_x);
        const cY = Math.cos(_y);
        const cZ = Math.cos(_z);

        const sX = Math.sin(_x);
        const sY = Math.sin(_y);
        const sZ = Math.sin(_z);

        if (order === undefined || order === 'ZXY') {

            return new Quat(
                cX * cY * cZ - sX * sY * sZ,
                sX * cY * cZ - cX * sY * sZ,
                cX * sY * cZ + sX * cY * sZ,
                cX * cY * sZ + sX * sY * cZ);
        }

        if (order === 'XYZ') {
            return new Quat(
                cX * cY * cZ - sX * sY * sZ,
                sX * cY * cZ + cX * sY * sZ,
                cX * sY * cZ - sX * cY * sZ,
                cX * cY * sZ + sX * sY * cZ);
        }

        if (order === 'YXZ') {
            return new Quat(
                cX * cY * cZ + sX * sY * sZ,
                sX * cY * cZ + cX * sY * sZ,
                cX * sY * cZ - sX * cY * sZ,
                cX * cY * sZ - sX * sY * cZ);
        }

        if (order === 'ZYX') {
            return new Quat(
                cX * cY * cZ + sX * sY * sZ,
                sX * cY * cZ - cX * sY * sZ,
                cX * sY * cZ + sX * cY * sZ,
                cX * cY * sZ - sX * sY * cZ);
        }

        if (order === 'YZX') {
            return new Quat(
                cX * cY * cZ - sX * sY * sZ,
                sX * cY * cZ + cX * sY * sZ,
                cX * sY * cZ + sX * cY * sZ,
                cX * cY * sZ - sX * sY * cZ);
        }

        if (order === 'XZY') {
            return new Quat(
                cX * cY * cZ + sX * sY * sZ,
                sX * cY * cZ - cX * sY * sZ,
                cX * sY * cZ - sX * cY * sZ,
                cX * cY * sZ + sX * sY * cZ);
        }
    };

    public rotateX(out, a, rad) {
        rad *= 0.5;

        let bx = Math.sin(rad),
            bw = Math.cos(rad);

       let temp=new Quat(this.w * bw - this.x * bx, this.x * bw + this.w * bx, this.y * bw + this.z * bx, this.z * bw - this.y * bx);
      return temp.vector;
        // return new Vector(1,1,1);
    }
    public rotateY(out, a, rad) {
        rad *= 0.5;

        let by = Math.sin(rad),
            bw = Math.cos(rad);

        let temp=new Quat(this.w * bw - this.y * by,this.x * bw - this.z * by,this.y * bw + this.w * by, this.z * bw + this.x * by);
        return temp.vector;
    }
    public rotateZ(rad) {
        rad *= 0.5;

        let bz = Math.sin(rad),
            bw = Math.cos(rad);

        let temp=new Quat(this.w * bw - this.z * bz, this.x * bw + this.y * bz, this.y * bw - this.x * bz, this.z * bw + this.w * bz);
        return temp.vector;

    }

    public matrix3D(): number[] {
        // return new Quaternion(this.w, -this.x, -this.y, -this.z
        const w = this.w;
        const x = -this.x;
        const y = -this.y;
        const z = -this.z;

        const n = w * w + x * x + y * y + z * z;
        const s = n === 0 ? 0 : 2 / n;
        const wx = s * w * x, wy = s * w * y, wz = s * w * z;
        const xx = s * x * x, xy = s * x * y, xz = s * x * z;
        const yy = s * y * y, yz = s * y * z, zz = s * z * z;
        return [1 - (yy + zz), xy - wz, xz + wy, 0,
            xy + wz, 1 - (xx + zz), yz - wx, 0,
            xz - wy, yz + wx, 1 - (xx + yy), 0,
            0, 0, 0, 1];
        /*return 'matrix3d('.concat([1 - (yy + zz), xy - wz, xz + wy, 0,
            xy + wz, 1 - (xx + zz), yz - wx, 0,
            xz - wy, yz + wx, 1 - (xx + yy), 0,
            0, 0, 0, 1].join(','), ')');
            */

    };
}
