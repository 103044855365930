import {Component, OnInit} from '@angular/core';
import {AuthService} from '../services/auth.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {RestService} from '../rest/rest.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Story} from '../global-interfaces/story';
import {User} from '../user/user';
import {Sujet} from '../paper/sujet';
import {environment} from '../../environments/environment';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-story',
  templateUrl: './story.component.html',
  styleUrls: ['./story.component.css']
})

export class StoryComponent implements OnInit {
  public story: Story;
  user: User;
  public stories: Story[];
  public form: FormGroup;
  cards = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map(({matches}) => {
      if (matches) {
        return 2;
      }
      return 1;
    }));
  private newItem: Sujet;
  access = environment.access;

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private router: Router, public route: ActivatedRoute,
              private fb: FormBuilder, private restService: RestService) {


  }

  onSubmit() {
    const formData = this.form.getRawValue();

    this.restService.updateItem('story', this.story.id, formData).subscribe(r => {
      console.log(r);

    });
  }

  ngOnInit(): void {


    this.restService.userObservable.subscribe(u => {
      const storyId = this.route.snapshot.paramMap.get('story');
      this.user = u;

      u.story.forEach(r => {
        r.id.toString() === storyId ? this.story = r : null;
      });
      if (this.story) {


        console.log(this.story);
        this.form = this.fb.group({
          description: [this.story.description],
          access: [this.story.access]

        });

        this.restService.loadItem('sujet', 'join=objet,strokes&filter=story,eq,'.concat(storyId)).subscribe(
          s => {
     this.story.sujet=s;

          }
        );

      }

    });
  }

  deleteSu(su): void {
    if (su.user === this.user.id) {
      this.restService.delItem(su.id, 'sujet');
    }
  }

  saved($event: any): void {

    this.newItem = new Sujet();
    this.story.sujet.push(this.newItem);
    this.newItem.owner = this.user.id;
    this.newItem.story = this.story.id;
    this.newItem.image = environment.apiFiles.concat('/images/', $event.message);
  }
}
