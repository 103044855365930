<div *ngIf="type && (type === 'host' || type === 'canvas')">
<div *ngIf="type === 'canvas'" class="bg" [style.height.px]="hi"  [style.width.px]="wi">

</div>
 <app-paper-canvas *ngIf="type" (loadUsers)="loadUsers(1)"
                   [process]="type" [guests]="users" [user_db]="user_db"
                   [location_db]="host_db" class="canvas" ></app-paper-canvas>
<!--<script src="assets/web-api.js"></script>
  <script>
    const player = new WSAudioAPI.Player({
      server: environment.ws // dont't forget about scheme
    });
console.log('dddddddd',player);

  </script>
--> <app-audio [role]="'player'"></app-audio>
<div *ngIf="process === 'show'" id="cover">
    <div id="cover1st"></div>
    <div *ngFor="let guest of guests"class="guest"  >
<div *ngIf="guest.active"  @myInsertRemoveTrigger class="guest_c" [style.transform]="guest.transf">
<div  [style.width.px]="guest.w" [style.opacity]="getOp(guest)"  [style.height.px]="guest.h" [style.top.px]="- guest.h*0.5" [style.left.px]="- guest.w*0.5"  class="sq"> </div>


    </div>
    </div>
</div>

</div>

<!--
background of guest
<app-paper-canvas *ngIf="type === 'guest' && user"
                  (loadUsers)="loadUsers(1)" [process]="process"
                  [type]="type"
                  [guests]="users" [user_db]="user_db"
                  [location_db]="host_db" class="canvas" ></app-paper-canvas>

                  -->

<app-guest *ngIf="type === 'guest' && user"  [user]="user" [group]="hostId"></app-guest>
<div *ngIf="type === 'draw' && story && host_db" class="bg">
<app-drawer [user]="user"  [user_db]="user_db" [location_db]="host_db" [story]="story.story_db"></app-drawer>
</div><!--
<app-qrcode *ngIf="type === 'host' " [value]="guestUrl" [hi]="hi"></app-qrcode>
-->
<div class="bot" *ngIf="type === 'host' && wsService.isConnected">
  <app-mask-screen></app-mask-screen>
  @{{hostId}}

  <ion-icon name="share-social" style="float:right"></ion-icon>


</div>
<ng-container >
<app-player *ngIf="type === 'draw' && (process === 'main' || process === 'sujet'  || process === 'story') && dta && dta.sujet" [dataD]="dta" (playeed)="playyed()"
            [main]="process"
            (pos)="initPlayer($event)"
            [command]="command"
            [userR]="user"></app-player>


  <div [class]="mClosedC ? 'cl clo' : 'cl'"
       (click)="mop()">
    <div class="flex">
      <div class="cclo">
        <svg x="0px" y="0px" width="120" height="36"
             viewBox="0 0 161 52" style="enable-background:new 0 0 161 52;" xml:space="preserve">
<style type="text/css">
	.sdt0 {
    fill: #8E135F;
  }
</style>
          <path class="sdt0" d="M60,32.36l-2.16-19.25c-0.12-0.95,0.03-1.68,0.45-2.2c0.41-0.52,1.01-0.82,1.78-0.9c0.8-0.08,1.47,0.09,2,0.52
	c0.53,0.43,0.85,1.11,0.97,2.06l2.19,19.5c0.16,1.3,0.45,2.36,0.88,3.2c0.43,0.84,1.08,1.46,1.95,1.85s2.05,0.52,3.51,0.38
	c2.03-0.2,3.4-0.83,4.12-1.89c0.72-1.06,0.96-2.58,0.72-4.56l-2.2-19.6c-0.12-0.96,0.03-1.7,0.44-2.21c0.41-0.52,1-0.81,1.78-0.89
	c0.78-0.08,1.44,0.09,1.98,0.51c0.54,0.42,0.87,1.11,0.99,2.07l2.16,19.25c0.22,1.81,0.21,3.34-0.03,4.59
	c-0.24,1.25-0.85,2.39-1.82,3.41c-0.84,0.87-1.85,1.55-3.04,2.03s-2.62,0.8-4.27,0.97c-1.97,0.2-3.69,0.17-5.16-0.07
	s-2.7-0.72-3.7-1.44c-1-0.72-1.78-1.68-2.35-2.9C60.6,35.59,60.21,34.11,60,32.36z"/>
          <path d="M17.98,25.3v-0.43c-0.64,0.67-1.28,1.21-1.91,1.63c-0.63,0.42-1.31,0.74-2.04,0.95c-0.73,0.22-1.53,0.33-2.4,0.33
	c-1.15,0-2.21-0.22-3.19-0.66s-1.83-1.07-2.54-1.88c-0.72-0.82-1.26-1.78-1.63-2.88s-0.55-2.29-0.55-3.58c0-2.71,0.73-4.83,2.2-6.34
	s3.39-2.27,5.79-2.27c1.38,0,2.55,0.21,3.5,0.64c0.95,0.43,1.88,1.09,2.78,1.98V6.38c0-0.89,0.2-1.56,0.59-2.02s0.95-0.68,1.68-0.68
	c0.73,0,1.29,0.21,1.68,0.63s0.59,1.04,0.59,1.85V25.3c0,0.83-0.21,1.45-0.63,1.86c-0.42,0.41-0.97,0.62-1.64,0.62
	c-0.66,0-1.2-0.21-1.63-0.64C18.2,26.71,17.98,26.09,17.98,25.3z M8.54,18.92c0,1.18,0.2,2.18,0.61,3.01s0.96,1.45,1.66,1.87
	s1.47,0.63,2.3,0.63c0.84,0,1.62-0.2,2.31-0.6s1.25-1.01,1.67-1.83c0.42-0.82,0.62-1.85,0.62-3.08c0-1.16-0.21-2.15-0.62-2.98
	s-0.98-1.47-1.69-1.91c-0.71-0.44-1.49-0.66-2.33-0.66c-0.88,0-1.66,0.23-2.36,0.68s-1.23,1.1-1.61,1.94
	C8.73,16.83,8.54,17.81,8.54,18.92z"/>
          <path d="M30.2,21.51v3.63c0,0.88-0.23,1.54-0.69,1.98s-1.04,0.66-1.74,0.66c-0.69,0-1.26-0.22-1.71-0.67s-0.67-1.1-0.67-1.97V13.06
	c0-1.95,0.78-2.93,2.34-2.93c0.8,0,1.37,0.23,1.72,0.68s0.55,1.13,0.58,2.02c0.57-0.89,1.16-1.56,1.77-2.02
	c0.6-0.46,1.41-0.68,2.42-0.68s1.99,0.23,2.94,0.68c0.95,0.46,1.42,1.06,1.42,1.81c0,0.53-0.2,0.97-0.61,1.31
	c-0.4,0.34-0.84,0.52-1.31,0.52c-0.18,0-0.6-0.1-1.28-0.29c-0.67-0.2-1.27-0.29-1.79-0.29c-0.7,0-1.28,0.17-1.72,0.5
	s-0.79,0.83-1.04,1.49s-0.42,1.44-0.51,2.35C30.25,19.11,30.2,20.21,30.2,21.51z"/>
          <path d="M51.55,25.33c-1.16,0.82-2.28,1.43-3.37,1.84c-1.08,0.41-2.3,0.61-3.65,0.61c-1.23,0-2.31-0.22-3.24-0.66
	c-0.93-0.44-1.65-1.04-2.15-1.79c-0.5-0.75-0.76-1.57-0.76-2.45c0-1.19,0.42-2.2,1.25-3.04c0.83-0.84,1.98-1.4,3.43-1.69
	c0.3-0.06,1.06-0.21,2.27-0.43c1.21-0.22,2.24-0.43,3.1-0.61s1.8-0.41,2.81-0.68c-0.06-1.14-0.31-1.98-0.77-2.52
	c-0.45-0.54-1.39-0.8-2.81-0.8c-1.22,0-2.14,0.15-2.75,0.46c-0.62,0.31-1.14,0.77-1.58,1.38c-0.44,0.61-0.75,1.02-0.93,1.22
	c-0.18,0.2-0.57,0.29-1.17,0.29c-0.54,0-1.01-0.16-1.4-0.47c-0.39-0.31-0.59-0.71-0.59-1.2c0-0.76,0.3-1.51,0.9-2.23
	s1.53-1.31,2.8-1.78c1.27-0.47,2.84-0.7,4.73-0.7c2.11,0,3.77,0.23,4.98,0.68s2.06,1.16,2.56,2.14s0.75,2.27,0.75,3.88
	c0,1.02,0,1.88-0.01,2.59s-0.01,1.5-0.03,2.37c0,0.82,0.15,1.67,0.45,2.55c0.3,0.89,0.45,1.45,0.45,1.71c0,0.45-0.23,0.85-0.69,1.22
	c-0.46,0.37-0.99,0.55-1.57,0.55c-0.49,0-0.98-0.21-1.46-0.63C52.6,26.73,52.09,26.13,51.55,25.33z M51.24,19.05
	c-0.7,0.23-1.73,0.48-3.07,0.74s-2.27,0.45-2.79,0.57c-0.52,0.12-1.01,0.36-1.48,0.72s-0.7,0.85-0.7,1.49
	c0,0.66,0.28,1.22,0.83,1.68c0.55,0.46,1.27,0.69,2.16,0.69c0.95,0,1.83-0.19,2.63-0.56s1.39-0.86,1.77-1.45
	c0.43-0.66,0.65-1.74,0.65-3.24V19.05z"/>
          <path d="M100.04,12.41v12.61c0,1.44-0.17,2.68-0.51,3.72c-0.34,1.04-0.89,1.9-1.64,2.58c-0.75,0.68-1.73,1.18-2.95,1.51
	c-1.21,0.33-2.72,0.49-4.53,0.49c-1.65,0-3.13-0.21-4.43-0.63c-1.3-0.42-2.3-0.96-3.01-1.62c-0.7-0.66-1.06-1.34-1.06-2.04
	c0-0.53,0.2-0.96,0.6-1.3c0.4-0.33,0.88-0.5,1.44-0.5c0.7,0,1.32,0.28,1.85,0.84c0.26,0.29,0.52,0.58,0.8,0.87
	c0.28,0.29,0.58,0.54,0.91,0.75c0.33,0.21,0.74,0.36,1.2,0.46s1.01,0.15,1.62,0.15c1.24,0,2.21-0.16,2.89-0.47
	c0.69-0.31,1.17-0.75,1.44-1.31s0.44-1.16,0.48-1.8s0.08-1.67,0.11-3.09c-0.74,0.93-1.59,1.64-2.56,2.13
	c-0.97,0.49-2.12,0.73-3.46,0.73c-1.61,0-3.01-0.37-4.21-1.11c-1.2-0.74-2.13-1.78-2.77-3.12c-0.64-1.34-0.97-2.88-0.97-4.63
	c0-1.3,0.2-2.48,0.59-3.53s0.95-1.93,1.68-2.66c0.73-0.72,1.57-1.26,2.51-1.63s1.99-0.55,3.13-0.55c1.36,0,2.54,0.24,3.53,0.71
	s1.92,1.21,2.78,2.22v-0.59c0-0.75,0.21-1.34,0.62-1.75s0.94-0.62,1.58-0.62c0.93,0,1.54,0.27,1.85,0.82
	C99.89,10.59,100.04,11.38,100.04,12.41z M86.13,17.91c0,1.76,0.43,3.09,1.28,3.99c0.85,0.9,1.95,1.35,3.3,1.35
	c0.8,0,1.55-0.19,2.26-0.58c0.71-0.39,1.29-0.97,1.73-1.75c0.45-0.78,0.67-1.73,0.67-2.84c0-1.77-0.43-3.15-1.29-4.13
	s-2-1.48-3.4-1.48c-1.37,0-2.47,0.47-3.3,1.42C86.54,14.83,86.13,16.17,86.13,17.91z"/>
          <path d="M107.69,5.41v6.44c0.61-0.64,1.2-1.14,1.79-1.51c0.58-0.37,1.22-0.65,1.93-0.83c0.7-0.19,1.46-0.28,2.27-0.28
	c1.22,0,2.3,0.23,3.24,0.7c0.94,0.47,1.69,1.14,2.22,2.04c0.34,0.52,0.57,1.1,0.69,1.74c0.12,0.64,0.18,1.38,0.18,2.22v8.32
	c0,0.87-0.22,1.53-0.66,1.97s-1.02,0.67-1.75,0.67c-1.58,0-2.37-0.88-2.37-2.64V16.9c0-1.39-0.23-2.46-0.69-3.2
	c-0.46-0.75-1.32-1.12-2.6-1.12c-0.86,0-1.63,0.22-2.31,0.66c-0.69,0.44-1.2,1.04-1.54,1.8c-0.26,0.65-0.39,1.8-0.39,3.45v5.74
	c0,0.86-0.21,1.51-0.64,1.96c-0.43,0.45-1.02,0.68-1.79,0.68c-1.58,0-2.37-0.88-2.37-2.64V5.41c0-0.88,0.21-1.54,0.62-1.98
	c0.42-0.44,1-0.66,1.75-0.66c0.76,0,1.36,0.22,1.79,0.67C107.48,3.88,107.69,4.54,107.69,5.41z"/>
          <g>
	<path d="M77.25,49H63.38c-0.96,0-1.69-0.21-2.18-0.62c-0.49-0.41-0.74-0.94-0.74-1.57c0-0.64,0.25-1.15,0.76-1.55
		c0.5-0.4,1.23-0.6,2.16-0.6h13.74c0.95,0,1.66,0.2,2.15,0.6s0.72,0.91,0.72,1.55c0,0.64-0.24,1.16-0.72,1.57
		C78.78,48.79,78.11,49,77.25,49z"/>
</g>
</svg>


      </div>
      <svg x="0px" y="0px"
           width="40" height="35" viewBox="-15 -20 496 361" style="enable-background:new 0 0 496 361;"
           xml:space="preserve">
<style type="text/css">
	.st0 {
    fill: #635B5B;
  }

  .st1 {
    fill: #BD45FF;
  }

  .st2 {
    fill: #CCCAC2;
  }

  .st3 {
    fill: #2E6FFF;
  }

  .st4 {
    fill: #D00046;
  }
</style>
        <g>
	<rect x="14" y="14" class="st0" width="339" height="55"/>

          <rect x="14" y="125.95" class="st0" width="339" height="55"/>

          <rect x="14" y="243.75" class="st0" width="339" height="55"/>
</g>
        <path class="st1" d="M443.95,178.65c-26.46,8.56-65.95-2.01-65.95-2.01v-47.09c59.27-12.36,89.32,23.92,104.35,2.77
	C484,130,476.51,169.2,443.95,178.65z"/>
        <polygon class="st2" points="355,69 418,53.05 418,30.43 355,14 "/>
        <polygon class="st2" points="355,299 391,287 391,258 355,244 "/>
        <polygon class="st3" points="419,258 392,257.91 392,287 429,287 "/>
        <polygon class="st4" points="454,40.35 418,30.43 418,53.05 454,43.58 "/>
        <polygon class="st2" points="375,129.42 355,126.12 355,181.12 375,178.4 "/>
</svg>
    </div>

    <div *ngIf="mClosedC" class="menu">
      <ng-container *ngIf="!logg">
        <div *ngIf="!drawing" (click)="help('draw')">
          <ion-icon name="brush"></ion-icon>
          <span>Draw</span>
        </div>
        <div *ngIf="drawing" (click)="startDraw()">
          <ion-icon name="brush"></ion-icon>
          <span>Stop</span>
        </div>
        <ng-container *ngIf="played > 0 ">
          <div *ngIf="played > 1 " (click)="help('save')">
            <ion-icon name="save"></ion-icon>
            <span>Save</span>
          </div>
          <div (click)="saveWEBP()">
            <ion-icon name="save"></ion-icon>
            <span>Save IM</span>
          </div>
          <div *ngIf="played > 1 " (click)="help('stream')">
            <ion-icon name="radio-outline"></ion-icon>
            <span>Stream</span>
          </div>
        </ng-container>
        <div (click)="help('share')">
          <ion-icon name="share-social"></ion-icon>
          <span>Share</span>
        </div>

        <div (click)="help('about')">
          <ion-icon name="information-circle"></ion-icon>
          <span>About</span>
        </div>
        <ng-container *ngIf="authService.userOb | async  as user else nouser">
          <ng-container *ngIf="user.id else nouser">
            <div (click)="help('profile')">
              <ion-icon name="person-circle"></ion-icon>
              <span>Profile</span>
            </div>
          </ng-container>
        </ng-container>
        <ng-template #nouser>
          <div (click)="login()">
            <ion-icon name="person-circle"></ion-icon>
            <span>Login</span>
          </div>
        </ng-template>

      </ng-container>
      <app-login *ngIf="logg" (logged)="logged($event)">

      </app-login>
    </div>


  </div>
  <router-outlet></router-outlet>
  <!--
      <ion-icon mat-icon-button #lTrigger="matMenuTrigger" class="cl" (menuOpened)="mClosed(0)"  (menuClosed)="mClosed(1)" [matMenuTriggerFor]="menu"  name="menu"></ion-icon>
    -->


</ng-container>
