<mat-card>



  <div class="file-upload">Add image:
<input type="file" style="display:none" name="file" class="file-input"
       (change)="onFileSelected($event)" #fileUpload>


  <button mat-mini-fab color="primary" class="upload-btn"
          (click)="fileUpload.click()">
    <mat-icon>attach_file</mat-icon>
  </button>



</div>
</mat-card>
