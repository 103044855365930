import {Objet} from './objet';


export class Sujet {

  access: string;
  objet: Objet[];
    id: number;
    name: string;
    objets: Objet[];
    opacity: number;
    life: number;
  delta: number;
    item: paper.Group;
    start: number;
    owner: number;
    story: number;
  image: string;
  user: number;
  size: string;
  type: string;
    constructor() {
    }
}
