<div  [style.width.px]="w"  [style.height.px]="h" #svg [class]="pService.getF(0)" >
  <!--  #fd
  <canvas #pp  [width]="w"
        [height]="h"
        [style.width.px]="w" [style.height.px]="h" >

</canvas>

  -->
  <canvas #pop  [width]="w"
          [height]="h" (pinch)="scale($event)"(pinchstart)="pinchS($event)"  (pinchend)="pout($event)"
          [style.width.px]="w" [style.height.px]="h" >

  </canvas>
  <app-svg [style.width.px]="w" [brushes]="brushes" [playing]="pl"  [style.height.px]="h" [style.left.px]="marg" (pos)="ini($event)" [width]="w" [height]="h"  [mat]="mat"></app-svg>
<!--
   </div>
-->

  <div class="slid" [style.width.px]="w-40" [style.marginLeft.px]="20">



    <!--  -->
    <mat-slider #my  class="my"
              (input)="play($event)"
(change)="nn($event)"
              [value]="curTime"
              [max]="end"
               thumbLabel="true"
                aria-labelledby="label"
              [min]="currentsujet.start">

  </mat-slider>

    <div class="mover"  [style.left.%]="per">
      <ion-icon *ngIf="pl" name="play"></ion-icon><ion-icon *ngIf="!pl" name="play-back"></ion-icon>
    </div>
  </div>
</div>
<!--<div  class="dd" [style.height.px]="h"></div>-->


