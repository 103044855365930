import {Objet} from '../paper/objet';
import * as paper from 'paper';

export class ObjetItem {
  group: paper.Group;
  id: number;
  objet: Objet;

  constructor(objet: Objet, tm: number) {
    this.id = objet.id;
    this.objet = objet;
    this.group = new paper.Group();
    this.group.name = 'objet'.concat(objet.id.toString());
    let curSujItem = paper.project.getItem({name: 'sujet'.concat(objet.sujet.toString())});
    if (!curSujItem) {
      curSujItem = new paper.Group();
      curSujItem.name = 'sujet'.concat(objet.sujet.toString());

    }
    curSujItem.addChild(this.group);
    this.reDraw(tm);
  }

  reDraw(tm) {

    this.objet.strokes.forEach(stroke => {

        if (Date.parse(stroke.start) - tm < 0) {
          if (!paper.project.getItem({name: 'str'.concat(stroke.id.toString())})) {
            const dots = JSON.parse(stroke.line.replace(/{(\w):/g, '{"$1":').replace(/,(\w):/g, ',"$1":').replace(/,(\w+):/g, ',"$1":')).map(
              a => {
                const ret = new paper.Segment();
                ret.point = a.p as paper.Point;
                if (a.In) {
                  ret.handleIn = a.In as paper.Point;
                }
                if (a.Out) {
                  ret.handleOut = a.Out as paper.Point;
                }
                return ret;

              }
            );

            const path = new paper.Path();
            path.name = 'str'.concat(stroke.id.toString());

            path.strokeWidth = stroke.width;
            // path.scale(0.3);
            path.strokeColor = new paper.Color('rgb('.concat(stroke.color, ')'));
            path.strokeCap = 'round';
            path.opacity = stroke.alpha;
            dots.forEach(dot => {
              path.add(new paper.Segment(dot));
            });

            this.group.addChild(path);
          }
        } else {
        //  console.log('str'.concat(stroke.id.toString()));
          const rem = paper.project.getItem({name: 'str'.concat(stroke.id.toString())});
          if(rem) rem.remove();

        }
      }
    );
  }
}
