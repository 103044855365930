import {Component, Injectable, OnInit, Self, SkipSelf} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Observable} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';

import {WebsocketService} from './websocket';
import {WS} from './websocket.events';
import {BROWSER_STORAGE, BrowserStorageService} from './storage/storage.service';
import {HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';

@Injectable()
export class MyHammerConfig extends HammerGestureConfig {
overrides = <any>{
        'swipe': {velocity: 0.4, threshold: 20} // override default settings
    };
}

export interface IMessage {
  name: string;

    id: number;
    text: string;
    value: string;
    user: string;
    type: string;
    size: string;
}


/*

 */
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    providers: [ { provide: Window, useValue: window },
        BrowserStorageService,
        {provide: BROWSER_STORAGE, useFactory: () => sessionStorage},
      {
        provide: HAMMER_GESTURE_CONFIG,
        useClass: MyHammerConfig
      }
    ]
})
export class AppComponent implements OnInit {


    private counter$: Observable<number>;
    private texts$: Observable<string[]>;
    private messages$: Observable<IMessage[]>;
    private host: string;
    private user: string;
private bb: any;
    constructor(private router: Router, private route: ActivatedRoute, private fb: FormBuilder, private wsService: WebsocketService,
                @Self() private sessionStorageService: BrowserStorageService,
                @SkipSelf() private localStorageService: BrowserStorageService) {
    }



    ngOnInit() {
        this.user = this.localStorageService.get('user');
/*
      if (typeof Worker !== 'undefined') {
        // Create a new

        const worker = new Worker('./app.worker', { type: 'module' });

        worker.onmessage = ({ data }) => {
          console.log(`page got message: ${data}`);
        };
        worker.postMessage('hello');
      }
*/
      // get messages
        // console.log(WS.ON.MESSAGES);

     //  this.messages$ = this.wsService.on<IMessage[]>(WS.ON.MESSAGES);
        // get counter
      /*  this.counter$ = this.wsService.on<number>(WS.ON.COUNTER);


        // get texts
        this.texts$ = this.wsService.on<string[]>(WS.ON.UPDATE_TEXTS);

       */
    }


    onSwipeLeft($event: any, number: number) {
        console.log($event);
    }


}
