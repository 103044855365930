import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {User} from '../user/user';
import {environment} from '../../environments/environment';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {HandleError, HttpErrorHandler} from '../http-error-handler.service';
import {ApiResult} from '../global-interfaces/api-result';
import {Brush} from '../global-interfaces/brush';

@Injectable({
  providedIn: 'root'
})
export class RestService {
  public userDb: number;
  private key: string;
  httpOptions = {
    headers: new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    })
  };
  httpOptionsPost = {
    headers: new HttpHeaders({
      Accept: 'application/json',

      Authorization: 'Bearer ' + localStorage.getItem('access_token')
    })
  };
 private locationDb: number;
  private currentusers: User[];
  public usersObservable: Subject<User[]>;
  public userObservable: Subject<User>;
  private handleError: HandleError;
  private location_: any[];
  private locationObservable: any;
  private story: number;
  private objet: number;
  private sujet: number;
  private user: User;
  public singleuserObservable: Subject<User>;

  constructor(private http: HttpClient, {createHandleError}: HttpErrorHandler) {
    this.handleError = createHandleError('userService');
    this.currentusers = new Array<User>();
    this.usersObservable = new Subject<User[]>();
    this.userObservable = new Subject<User>();
    this.singleuserObservable = new Subject<User>();
this.key = 'sdfsdfsdf';
  }

  public setUser(u: any) {
    this.userDb = u.id;
    if (u.color === null) {
      // console.log( this.updateUser(u.id,{color: Math.random()}));

    }
  }

  public setLocation(u: number) {
    this.locationDb = u;
  }

  public setStory(u: number) {
    this.story = u;
  }
  public setUserOb(u: User) {
    this.user = u;
    this.userObservable.next(u);
  }

  public setObjet(u: number) {
    this.objet = u;
  }

  public setSujet(u: number) {
    this.sujet = u;
  }

  addLocation(location: string): Observable<any> {

    const locObservable = new Subject<any>();
    this.http.post<any>(environment.apiUrl.concat('locations'), {
      name: location
    }, this.httpOptions).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {

      locObservable.next(data as number);
    });
    return locObservable;
  }

  getMyLoc() {
    const locObservable = new Subject<any>();
    this.http.get<any>('https://api.ipgeolocation.io/ipgeo?apiKey=63f22cdf56074dfaada1e39118fee3af',  this.httpOptions).subscribe(data => {

      locObservable.next(data);
    });
    return locObservable;

  }

  getLocations(u) {
    const locObservable = new Subject<any>();
    const p = '-120 120,120 120,120 -120,-120 -120,-120 120';
    /*

    get the drum users

     */
    // https://walls.memigration.info/api/records/drum
    this.http.get<any>('https://walls.memigration.info/api/records/drum?include=id,lat,lng,uid&filter=latlng,swi,POLYGON(('.concat(p, '))')).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {

      locObservable.next(data.records);
    });
    return locObservable;
  }

  updateItem(itemName: string, pointer: number, value: any): Observable<any> {
    const locObservable = new Subject<any>();
    console.log(environment.apiUrl.concat(itemName, '/', String(pointer)));
    this.http.put<any>(environment.apiUrl.concat(itemName, '/', String(pointer)), value, this.httpOptions).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {

      locObservable.next(data as number);
    });
    return locObservable;
  }

  addItem(itemName: string, input: any): Observable<any> {

    const locObservable = new Subject<any>();
    this.http.post<any>(environment.apiUrl.concat(itemName), input, this.httpOptions).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {

      locObservable.next(data as number);
    });
    return locObservable;
  }

// 109
  delItem(id: number, table: string) {

    this.http.delete<string>(environment.apiUrl.concat(table, '/', String(id)), this.httpOptions)
      .pipe(catchError(this.handleError<string>('loadusers')))
      .subscribe(data => {
        console.log('deleted '.concat(data));
      });


  }

  delUser(uid: number) {
    console.log(uid);
    this.http.delete<string>('https://walls.memigration.info/api/records/user/'.concat(String(uid)),  this.httpOptions)
      .pipe(catchError(this.handleError<string>('loadusers')))
      .subscribe(data => {
        console.log('deleted '.concat(data));
      });


  }

  addPath(objet, brush: Brush, points, delta): Observable<any> {

    const color = brush.color.red.toString().concat(',', brush.color.green.toString(), ',', brush.color.blue.toString());
    const width = brush.radius;

    const locObservable = new Subject<any>();
    this.http.post<any>(environment.apiUrl.concat('strokes'), {
      objet: objet, color: color, alpha: brush.color.alpha, width: width, line: points, delta: delta
    }, this.httpOptions).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {
      locObservable.next(data as object);
    });
    return locObservable;
  }

  loadNodes(placeName: string): Observable<User[]> {
    const path = environment.usersPath.concat(placeName);

    const options = {
      params: new HttpParams().set('path', path)
    };
    // console.log(environment.apiUrl.concat(path));//

    this.http.get<ApiResult>('https://walls.memigration.info/api/records/chel')
      .pipe(catchError(this.handleError<ApiResult>('loadusers')))
      .subscribe(data => {
        this.currentusers = data.records as User[];
        this.usersObservable.next(this.currentusers);
        console.log(data.records);
      });
    return this.usersObservable;
    /*   */


    //  return [{userId: '23'}] as User[];
  }

  loadlocation(placeName: string): Observable<any> {

    const path = environment.locationPath.concat('?join=story,user&filter=name,eq,', placeName);

    const locObservable = new Subject<any>();
    this.http.get<ApiResult>(environment.apiUrl.concat(path), this.httpOptions)
      .pipe(catchError(this.handleError<ApiResult>('loadlocation')))
      .subscribe(data => {
if(data.records && data.records.length>0){
        locObservable.next(data.records[0] as object);
}else{


}
      });
    return locObservable;
  }

  loadItem(itemName: string, filter: string): Observable<any[]> {

    const itemObservable = new Subject<any[]>();
    this.http.get<ApiResult>(environment.apiUrl.concat(itemName, '?', filter),  this.httpOptions)
      .pipe(catchError(this.handleError<ApiResult>('loadusers')))
      .subscribe(data => {

        itemObservable.next(data.records);
      });
    return itemObservable;

  }

  loadusers(placeName: string): Observable<User[]> {
    const path = environment.usersPath.concat(placeName);

    this.http.get<ApiResult>(environment.apiUrl.concat('user?join=drum&filter=size,nis,NULL'),  this.httpOptions)
      .pipe(catchError(this.handleError<ApiResult>('loadusers')))
      .subscribe(data => {
        this.currentusers = data.records as User[];
        this.usersObservable.next(this.currentusers);
      });
    return this.usersObservable;
  }

  loaduser(userId: string): Observable<User> {



    const path = environment.userPath.concat(userId, '?join=story');

    this.http.get<User>(environment.apiUrl.concat(path),  this.httpOptions)
      .pipe(catchError(this.handleError<User>('loadProd')))
      .subscribe((data: User) => {

        if (data.id) {
          this.user = data as User;
          this.singleuserObservable.next(this.user);
          this.userObservable.next(this.user);


          this.userDb = data.id;

        }else{
          /*
          this.user = data as User;
          this.singleuserObservable.next(this.user);
          this.userObservable.next(this.user);


          this.userDb = parseInt(userId,1);
          */
        }

      });
    return this.singleuserObservable;
  }

  updateUser(id: number, body: any): Observable<any> {

    const locObservable = new Subject<any>();
    this.http.put<any>(environment.apiUrl.concat('user/', String(id)), body, this.httpOptions).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {
      locObservable.next(data as any);
    });
    return locObservable;
  }

  addUser(userId: string, size: string, col: number): Observable<any> {

    const locObservable = new Subject<any>();
    this.http.post<any>(environment.apiUrl.concat('user'), {
      name: userId, color: col, size: size
    }, this.httpOptions).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {

      locObservable.next(data as number);
    });
    return locObservable;
  }

  loadItemOld(itemName: string, filter: string): Observable<any[]> {


    const itemObservable = new Subject<any[]>();
    this.http.get<ApiResult>(environment.apiUrlOld.concat(itemName, '?', filter))
      .pipe(catchError(this.handleError<ApiResult>('loadusers')))
      .subscribe(data => {

        itemObservable.next(data.records);
      });
    return itemObservable;

  }
getUser(): User{

    return this.user;
}
  fileUpload(formData: FormData): Observable<any>{
    const locObservable = new Subject<any>();

    this.http.post<any>(environment.apiFiles.concat('/ulogin.php'), formData).pipe(
      catchError(this.handleError<any>('addLocation'))
    ).subscribe(data => {

      locObservable.next(data);
    });
    return locObservable;

  }

  getsingleuserObservable(): Observable<any> {
    return this.singleuserObservable;
  }
}
