
<form [formGroup]="form" >
  <mat-form-field appearance="standard"> <input type="email"  matInput formControlName="email" placeholder="email"> <br>
  </mat-form-field>
  <mat-form-field *ngIf="emOk" appearance="standard">
  <input type="password"  matInput formControlName="password" placeholder="password">
  </mat-form-field>
</form>

 <div class="flex-r btns">
   <button *ngIf="(found && emOk) || (!emOk)" (click)="onSubmit()">Log in</button>
   <ng-container *ngIf="!found">
   or  <a href="#" (click)="reg()">Register</a>
   </ng-container>
 </div>

