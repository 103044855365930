import {Injectable} from '@angular/core';
import {Brush} from '../global-interfaces/brush';
import paper from 'paper';


@Injectable({
  providedIn: 'root'
})
export class PaintService {
  brushes: Brush[];
  brush: Brush;
  colors: paper.Color[];
  public activeBrush: Brush;

  constructor() {

    this.colors = [new paper.Color(77, 19, 80, 0.05),
      new paper.Color(204, 19, 80, 0.05),
      new paper.Color(102, 116, 95, 0.05),
      new paper.Color(26, 9, 0, 0.9)
    ];
    this.brushes = [new Brush(
      20, this.colors[0], 0),
      new Brush(20, this.colors[1], 1),
      new Brush(20, this.colors[2], 2),
      new Brush(4, this.colors[3], 3)
    ];
    this.brushes[3].active = true;
    this.activeBrush = this.brushes[3];
    const rootStyle = document.documentElement.style;
    rootStyle.setProperty('--act-brushC', 'rgba(' + this.activeBrush.color.red+
    ',' + this.activeBrush.color.green +
      ',' + this.activeBrush.color.blue +
      ',' + this.activeBrush.color.alpha + ')');
    rootStyle.setProperty('--act-brushR', (this.activeBrush.radius + 4).toString()+'px');
    rootStyle.setProperty('--act-brushRm', ((-this.activeBrush.radius - 4)/2).toString()+'px');
  }

getDraw(u:any){console.log(u);}
  getBrushes(): Brush[] {

    return this.brushes;
  }
  getBrush(): Brush {

    return this.activeBrush;
  }
}
